import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

import Loading from "../../layout/loading";
import Modalalert from "../../layout/modal/alertImgModal";
import { fabric } from "fabric";
import { getData } from "../../services/getService";

// import editPhoto from "../../assets/img/icon/EditPhoto.svg";
import delBin from "../../assets/img/icon/bin2.svg";
import addText from "../../assets/img/icon/Addtext.svg";
import textColor from "../../assets/img/icon/Textcolor.svg";
import fontStyle from "../../assets/img/icon/Fontstyle.svg";
import boxIg from "../../assets/img/ig2.png";

import { ColorPicker } from 'primereact/colorpicker';

import { useRecoilState } from 'recoil';
import { nameState, charCountState } from '../../state/states'


const Home = () => {

    const pr = useParams();
    const fileInputRef = useRef();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    // const [mainData, setMainData] = useState([]);

    const [fileData, setFileData] = useState();

    // const [tableData, setTableData] = useState({});
    const [imgNatureData, setImgNatureData] = useState();
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })

    const [getStyle, setGetStyle] = useState({ text: '', x: '', y: '', fontStyle: false, color: '#000000' });
    // const [hex, setHex] = useState("#fff");

    const [nameIg, setNameIg] = useState({ igName: null, tableName: null });

    const [name, setName] = useRecoilState(nameState)

    const called = useRef(true)
    const canvasRef = useRef(null);
    useEffect(() => {
        if (called.current) {
            const imgNature = JSON.parse(localStorage.getItem('imgNature'));

            setNameIg({ tableName: (JSON.parse(localStorage.getItem('igName')).tableName), igName: (JSON.parse(localStorage.getItem('igName')).igName) })

            if (imgNature) {
                laodData(imgNature)
            }
            return () => canvasRef.current.dispose();
        }
        return () => {
            called.current = false
        }
    }, [])



    // const [theme, setTheme] = useContext();

    const laodData = (imgNature) => {
        setImgNatureData(imgNature)
        // setItems(items);
        canvasRef.current = initCanvas();
        // canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));

        setTimeout(() => {
            var imageUrl = imgNature.src;

            fabric.Image.fromURL(imageUrl, function (myImg) {
                var img1 = myImg.set({
                    scaleX: ((canvasRef.current.width) * 1 / (imgNature.width)),
                    scaleY: ((canvasRef.current.height) * 1 / (imgNature.height)),
                    selectable: false,
                });
                canvasRef.current.add(img1);
            });
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }, 100);
    }

    const initCanvas = () => (

        // Create a Fabric.Image object 
        new fabric.Canvas('c4', {
            // height: 1442.59,
            // width: 1920,
            height: 675,
            width: 1200,
            // backgroundImage: upImg,
        })
    );

    const handleNext = () => {
        if (fileData) {

        } else {
            setModalMenu({ view: true, id: 1 })
        }
    }

    const handleaddText = () => {
        let shape
        shape = new fabric.IText("This is an instance", {
            left: 280,
            top: 450,
            fontFamily: 'Prompt',
            fontWeight: '500',
            scaleX: 1.7,
            scaleY: 1.7,
            fontSize: 40,
            fill: getStyle.color,
            borderColor: 'rgba(0,0,0,0.1)',
            selectionColor: "rgba(0,0,0,0.5)",
            borderScaleFactor: 15,
            // touchCornerSize: 15,
            cornerStrokeColor: 'rgba(0,0,0,0.5)',
            transparentCorners: false,
            cornerColor: "rgba(0,0,0,0.5)",
            cornerSize: 30,
    
        });
        canvasRef.current.add(shape);
    }

    const handletextColorChange = (e) => {
        // setHex(e.hex);
        let shape
        // setGetStyle((prev) => ({ ...prev, color: '#' + e }))
        setGetStyle((prev) => ({ ...prev, color: e.target.value }))
        if (canvasRef.current.getActiveObject()) {
            // shape = canvasRef.current.getActiveObject().set('fill', '#' + e);
            shape = canvasRef.current.getActiveObject().set('fill', e.target.value);
            canvasRef.current.renderAll.bind(shape)
        }
    };

    const handleFontStyle = () => {
        setGetStyle((prev) => ({ ...prev, fontStyle: !getStyle.fontStyle }))
        let shape
        if (canvasRef.current.getActiveObject()) {
            shape = canvasRef.current.getActiveObject().set('fontStyle', !getStyle.fontStyle ? 'italic' : '');
            canvasRef.current.add(shape);
        }
    }

    const handleDel = () => {
        let canvas

        canvasRef.current.getActiveObjects().forEach((object) => {
            canvasRef.current.remove(object);
        });

    };

    const handleExport = () => {

        // localStorage.removeItem("imgNature")

        const base64Image = canvasRef.current.toDataURL('image/jpeg', 0.7);

        const img = new Image();
        img.src = base64Image;
        img.onload = function () {
            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

            // setOutput(base64Image);
            var arrayaa = { src: base64Image, width: imgWidth, height: imgHeight }
            // localStorage.setItem('imgNatureAddtext', JSON.stringify(arrayaa));
            // localStorage.setItem('imgNature', JSON.stringify(arrayaa));
            setName(JSON.stringify(arrayaa))

            // console.log(base64Image)

            // sessionStorage.setItem("imgNatureAddtext", JSON.stringify(arrayaa));

            // setIsLoading(true)

            setTimeout(() => {
                //     window.location.href = '/promote/edit'
                navigate('/promote/edit');
            }, 200);
        };
    }
    const refreshData = () => {

    }
    // console.log(getStyle.color)
    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="box-in-home-res bg-white text-center px-3">
                                        <div className="topic weight-bold pt-4"></div>
                                        <div className="font-14 weight-light"></div>
                                        <div className="row justify-content-md-center text-start pb-5 mb-5">
                                            {/* <div className="col-6 col-md-3 col-lg-2 text-center px-0 py-5" >
                                                <img src={imgNatureData && imgNatureData.src} className="w-100" />
                                            </div> */}
                                            <div className="col-12 col-md-12 col-lg-12 text-center px-0 py-5" >

                                                <div className="text-end m-auto position-relative mb-2" style={{ width: '387px' }}>

                                                    <button className="btn btn-cancle btn-add-text" onClick={() => handleaddText()}><img className='' src={addText} /></button>
                                                    <button className="btn btn-cancle btn-add-text position-relative" onClick={() => fileInputRef.current.click()}>
                                                        <img className='' src={textColor} />
                                                        <span className="position-absolute absolute-center-bottom" style={{ bottom: '3px', borderBottom: '3px solid ' + getStyle.color, height: '5px', width: '20px' }}></span>
                                                        <input
                                                            type="color"
                                                            value={getStyle.color}
                                                            size="10"
                                                            onChange={(e) => handletextColorChange(e)}
                                                            ref={fileInputRef}
                                                            className=""
                                                            style={{
                                                                opacity: 0, position: 'absolute',
                                                                left: 0,
                                                                top: 0,
                                                                height: '100%'
                                                            }} />

                                                    </button>
                                                    {/* <ColorPicker format="hex" value={getStyle.color} onChange={(e) => handletextColorChange(e.value)} /> */}

                                                    <button className="btn btn-cancle btn-add-text" onClick={() => handleFontStyle()}><img className='' src={fontStyle} /></button>
                                                    <button className="btn btn-cancle btn-add-text me-2" onClick={() => handleDel()}><img className='' src={delBin} style={{ width: '18px' }} /></button>
                                                </div>
                                                <div className="position-relative mx-auto box-add-text">
                                                    <div className="position-absolute weight-medium text-white" style={{ zIndex: 1, top: '7.5%', left: '3.4%', fontSize: '70%' }}>โต๊ะ</div>
                                                    <div className="position-absolute weight-bold text-white" style={{ zIndex: 1, top: '3.1%', left: '8.4%', fontSize: '123%' }}>{nameIg.tableName}</div>
                                                    <div className="position-absolute weight-medium" style={{ zIndex: 1, bottom: '6.1%', left: '3.5%',width:'7%'}}>
                                                        <img src={boxIg} className="w-100" />
                                                    </div>
                                                    <div className="position-absolute weight-medium text-white" style={{ zIndex: 1, bottom: '5.8%', left: '11.27%', fontSize: '106%' }}>{nameIg.igName}</div>
                                                    <canvas id="c4" />
                                                </div>

                                                {/* <button className="btn btn-blue text-white w-75 mt-4" onClick={() => handleExport()}>
                                                    <img src={editPhoto} className="" style={{width:'20px'}}/> ใส่ข้อความบนรูปภาพ
                                                </button> */}
                                                {/* <button className="btn btn-blue text-white w-100">ยืนยัน</button> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-3 box-order-list">
                                        <div className="row align-items-center text-start">
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <a className="btn btn-cancle w-100" href={"/promote/edit"}>ยกเลิก</a>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="position-relative">
                                                    <button onClick={() => handleExport()} className="btn btn-blue d-block w-100 text-white">
                                                        เสร็จสิ้น
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <Modalalert show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                    // dataProduct={modalMenu.dataProduct}
                    // selectData={selectData}
                    // onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Home;
