const menuData = [{
    id: 1,
    name: 'ระบบชื่อผู้ใช้บริการ',
    active: 'users',
    link: '/administrator/members',
    target: '',
    status: true,
    submenu: []
}, {
    id: 2,
    name: 'ระบบ Entertain',
    active: 'entertain',
    link: false,
    target: '',
    status: true,
    submenu: [{
        id: 21,
        name: 'ตั้งค่ารายการเมนู',
        active: 'settingMenu',
        link: '/administrator/settingMenu',
        target: '',
        status: true,
        supersubmenu: []
    }, {
        id: 22,
        name: 'รายการ Entertain',
        active: 'entertain',
        link: '/administrator/entertain',
        target: '',
        status: true,
        supersubmenu: []
    }, {
        id: 23,
        name: 'ตั้งค่าข้อมูลนักร้อง',
        active: 'singer',
        link: '/administrator/singer',
        target: '',
        status: true,
        supersubmenu: []
    }, {
        id: 24,
        name: 'รายงาน',
        active: 'report',
        link: '/administrator/report',
        target: '',
        status: true,
        supersubmenu: []
    }]
}, {
    id: 3,
    name: 'ระบบโปรโมท',
    active: 'promote',
    link: '/administrator/promote',
    target: '',
    status: true,
    submenu: []
}, {
    id: 4,
    name: 'ระบบจัดการออเดอร์',
    active: 'order',
    link: false,
    target: '',
    status: true,
    submenu: [{
        id: 41,
        name: 'จัดการโต๊ะ',
        active: 'manageTable',
        link: '/administrator/manageTable',
        target: '',
        status: true,
        supersubmenu: []
    }, {
        id: 42,
        name: 'ออเดอร์ที่เปิดอยู่',
        active: 'manageOrder',
        link: '/administrator/manageOrder',
        target: '',
        status: true,
        supersubmenu: []
    }, {
        id: 43,
        name: 'ประวัติใบเสร็จ',
        active: 'receiptHistory',
        link: '/administrator/receiptHistory',
        target: '',
        status: true,
        supersubmenu: []
    }]
}]


export { menuData }