import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import moment from "moment";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop';
// import { canvasPreview } from './../../canvasPreview';
import 'react-image-crop/dist/ReactCrop.css';

import CropPreview from './../../CropPreview';

import Loading from "../../layout/loading";
import Modalalert from "../../layout/modal/alertImgModal";
import Modalcrop from "../../layout/modal/modalCrop";

import { getDataNone } from "../../services/getService";

import upImg from "../../assets/img/icon/UploadImg.svg";
import orders from "../../assets/img/icon/Order.svg";



const Home = () => {

    // const pr = useParams();

    // const [name, setName] = useRecoilState(nameState)

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [tableData, setTableData] = useState({});

    const [fileData, setFileData] = useState();
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })
    const [modalCrop, setModalCrop] = useState({ view: false, id: 0, type: '' })

    const imgRef = useRef(null)

    const [imgSrc, setImgSrc] = useState('')
    const [completedCrop, setCompletedCrop] = useState()
    const [nameIg, setNameIg] = useState({ table: null, ig: null });

    const [crop, setCrop] = useState({
        unit: '%',
        width: 30,
        aspect: 16 / 9
    })

    const called = useRef(true)
    const fileInputRef = useRef();

    useEffect(() => {
        if (called.current) {
            localStorage.removeItem("imgNature")
            localStorage.removeItem("imgNatureAddtext")
            localStorage.removeItem("igName")
            // Promise.all([fetchData(pr.id)]).then(res => {
            // setTimeout(() => {
            setIsLoading(false);
            // }, 1000);
            // });

        }
        return () => {
            called.current = false
        }

    }, [])

    // const fetchData = async (id) => {

    //     getDataNone('order', id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 if (response.data) {
    //                     setMainData(response.data)
    //                     fetchTable(response.data.tableId)
    //                 } else {
    //                     setIsLoading(true);
    //                 }
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //             setIsLoading(true);
    //         })
    // }

    // const fetchTable = async (id) => {

    //     getDataNone('table', id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setTableData(response.data)
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }

    const handleNext = () => {
        // console.log(fileData)
        if (fileData) {
            // cropImageNow()
            var arrayaa = { tableName: nameIg.table, igName: nameIg.ig }
            localStorage.setItem('igName', JSON.stringify(arrayaa));

            // const imgNature = JSON.parse(localStorage.getItem('imgNature'));

            setTimeout(() => {
                window.location.href = '/promote/edit'
            }, 100);
        } else {
            setModalMenu({ view: true, id: 0, type: 'alert' })
        }
    }

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const newErrors = findFormErrors()

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            // dataForm.get('table')
            // dataForm.get('ig')
            if (fileData) {
                // cropImageNow()
                setIsLoading(true);

                var arrayaa = { tableName: dataForm.get('table'), igName: dataForm.get('ig') }
                localStorage.setItem('igName', JSON.stringify(arrayaa));
    
                // const imgNature = JSON.parse(localStorage.getItem('imgNature'));
    
                setTimeout(() => {
                    window.location.href = '/promote/edit'
                }, 100);
            } else {
                setModalMenu({ view: true, id: 0, type: 'alert' })
            }

        }
        setValidated(true);
    };


    const handleImage = (e) => {
        // if (e.target.files) {
        //     setFileData(URL.createObjectURL(e.target.files[0]));
        // alert(e.target.files[0].type)
        // }
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])

            setFileData(URL.createObjectURL(e.target.files[0]));

            setModalCrop({ view: true, fileData: URL.createObjectURL(e.target.files[0]), imgRef: imgRef })
        }
    }

    // const onImageLoad = (e) => {
    //     const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    //     const crop = centerCrop(
    //         makeAspectCrop({ unit: '%', width: 100, },
    //             16 / 9,
    //             width,
    //             height
    //         ),
    //         width,
    //         height
    //     )
    //     //    console.log(width,height)
    //     setCrop(crop)
    //     // console.log(crop)
    // }

    const setcompletedCrop = (e) => {
        setCompletedCrop(e)
    }

    console.log(fileData)
    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        {/* <div className="position-absolute" style={{ top: '12px', right: '10px' }}>
                                        <a href={"/qrmenu"} className="text-white btn-default">
                                            ส่งของขวัญ
                                        </a>
                                    </div> */}
                                        <div className="box-in-home-res bg-white text-center px-3 position-relative" >
                                            <div className="position-absolute bg-white w-100" style={{ height: '30px', bottom: '-12px', left: 0 }}></div>
                                            {/* <div className="topic weight-bold pt-4">โต๊ะ {tableData.name}</div> */}
                                            {/* <div className="font-14 weight-light">{moment(mainData.dateActive).format('DD/MM/YYYY')}&nbsp; | &nbsp;Check in : {moment(mainData.dateActive).format('HH:mm')} น.</div> */}
                                            <div className="row justify-content-md-center text-start pb-5">
                                                {/* <div>
                                                <input value={name} onChange={(event) => setName(event.target.value)} />
                                            </div> */}
                                                <div className="col-12 col-md-12 col-lg-12 text-center">
                                                    <div className="topic-small weight-bold pt-4 pb-3">โปรโมทขึ้นจอ</div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 text-center">
                                                    <Form.Group className="text-start" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className="weight-medium mb-1">ชื่อโต๊ะ</Form.Label>
                                                        {/* <Form.Control type="text" name="table" className="input-custom w-100" onChange={(e) => setNameIg((prev) => ({ ...prev, table: e.target.value }))} placeholder="พิมพ์ชื่อ โต๊ะ" required /> */}
                                                        <Form.Control type="text" name="table" className="input-custom w-100" placeholder="พิมพ์ชื่อ โต๊ะ" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 text-center">
                                                    <Form.Group className="text-start" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className="weight-medium mb-1">ชื่อ Instagram</Form.Label>
                                                        {/* <Form.Control type="text" name="ig" className="input-custom w-100" onChange={(e) => setNameIg((prev) => ({ ...prev, ig: e.target.value }))} placeholder="พิมพ์ชื่อ Instagram" required /> */}
                                                        <Form.Control type="text" name="ig" className="input-custom w-100" placeholder="พิมพ์ชื่อ Instagram" required />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 text-center">

                                                    {fileData ?
                                                        <div className="py-5">
                                                            {/* <ReactCrop crop={crop}
                                                            onChange={c => setCrop(c)}
                                                            aspect={4 / 3} locked
                                                            onComplete={(c) => setcompletedCrop(c)}
                                                            maxWidth={1920}
                                                            maxHeight={1080}>
                                                            <img src={fileData} ref={imgRef} onLoad={onImageLoad} />
                                                        </ReactCrop> */}
                                                            <div className="w-100 box-canvas">
                                                                <CropPreview img={imgRef.current} crop={completedCrop} table="A01" dataId={0} />
                                                            </div>
                                                            <input
                                                                type="file"
                                                                onChange={(e) => handleImage(e)}
                                                                hidden
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                ref={fileInputRef}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="p-5">
                                                            <img src={upImg} className="w-100 px-5" alt="" onClick={() => fileInputRef.current.click()} />
                                                            <input
                                                                type="file"
                                                                onChange={(e) => handleImage(e)}
                                                                hidden
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                ref={fileInputRef}
                                                            />
                                                        </div>
                                                    }
                                                    {/* <button onClick={cropImageNow}>Crop</button> */}


                                                    {/* <div>{imgRef.current && imgRef.current.src}</div> */}
                                                    {/* {console.log(completedCrop)} */}
                                                    <div className="font-12 text-gray">*การอัพโหลดข้อมูลรูปภาพของคุณ ถือเป็นการยินยอมให้เผยแพร่ข้อมูลดังกล่าว
                                                        หากเกิดข้อผิดพลาด ทางบริษัทขอสวงนสิทธิ์การรับผิดชอบ ไม่ว่ากรณีใดทั้งสิ้น</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-3 box-order-list">
                                            <div className="row align-items-center text-start">
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <a href={"/promote"} className="btn btn-cancle w-100">ยกเลิก</a>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="position-relative">
                                                        {/* <button onClick={() => handleNext()} className="btn btn-addmember d-block w-100 text-white">
                                                            ถัดไป
                                                        </button> */}
                                                        <button type="submit" className="btn btn-addmember d-block w-100 text-white">
                                                            ถัดไป
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <Modalalert show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                    // dataProduct={modalMenu.dataProduct}
                    // selectData={selectData}
                    // onRefresh={refreshData}
                    />
                    : null
            }
            {
                modalCrop.view ?
                    <Modalcrop show={modalCrop.view}
                        dataId={modalCrop.id}
                        onHide={() => setModalCrop({ view: false })}
                        fileData={modalCrop.fileData}
                        imgRef={modalCrop.imgRef}
                        setcompletedCrop={setcompletedCrop}
                    // dataProduct={modalMenu.dataProduct}
                    // selectData={selectData}
                    // onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Home;
