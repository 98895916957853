import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { getData } from "../../services/getService";

import ModalDel from "../../layout/modal/delData";
import ModalPrint from "../../layout/modal/printModel";
import Loading from "../../layout/loading";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import ComponentPrint from '../../components/PrintOrder';
import ReactToPrint from 'react-to-print';

import carlendar from "../../assets/img/icon/Carlendar.svg";
import menu from "../../assets/img/icon/Menu.svg";
import bin from "../../assets/img/icon/bin.svg";
import edit from "../../assets/img/icon/edit.svg";
import logo from "../../assets/img/icon/logo-black.svg";
import cancel from "../../assets/img/icon/Cancel.svg";
const Main = () => {
    const componentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dateToday, setDateToday] = useState(new Date());
    const [mainData, setMainData] = useState([]);
    const [stateActive, setStateActive] = useState({});
    const [editview, setEditview] = useState({ view: false, dataOrder: '' });
    const [modalDel, setModalDel] = useState({ view: false, id: 0, type: '' })
    const [modalPrint, setModalPrint] = useState({ view: false, id: 0, type: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        setDateToday(picker.startDate);
        fetchData(picker.startDate)
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            setTimeout(() => {
                Promise.all([fetchData(dateToday)]).then(res => {
                    setIsLoading(false);
                });
            }, 100);
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async (dd) => {

        getData('orderHistory?dateActive=' + moment(dd).format('YYYY-MM-DD')).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    if (response.data.length) {
                        setStateActive(response.data[0])
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const handleActive = (e) => {
        setStateActive(e)
        setEditview({ view: false })
    }
    const handleEdit = (e) => {
        setStateActive(e)
        setEditview({ view: true })
    }

    const refreshData = () => {
        fetchData(dateToday);
        setModalDel({ view: false })
        setModalPrint({ view: false })
    };

    const sumTotal = (e, type) => {
        let content = e;
        if (content && content.length) {
            let sum = content.reduce(function (prev, current) {
                if (type === 'gift') {
                    return prev + +(current.price * current.qty)
                } else {
                    return prev + +(current.price)
                }
            }, 0);
            return (sum)
        } else {
            return 0
        }
    }

    const printSuccess = (e) => {
        refreshData()
    }

    // console.log(stateActive)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-7 pe-0">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)', borderRadius: '22px 0 0 22px' }}>
                                        <div className="" style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="p-3">
                                                        <Menu page={'order'} subPage={'receiptHistory'} />
                                                        <span className="align-middle sub-topic weight-medium ms-4">ประวัติใบเสร็จ</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="px-4">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-auto">
                                                                <a href="/" className="btn btn-menu py-0 px-md-3 px-lg-3" style={{ opacity: 0 }}>
                                                                    <img src={menu} className="" style={{ height: '30px' }} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="sub-topic weight-medium">{moment(dateToday).format('DD/MM/YYYY')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-1 col-lg-2 text-end"></div>
                                                <div className="col-12 col-md-5 col-lg-4 text-end">
                                                    <div className="px-4 mb-3 ">
                                                        <div className="input-group" style={{}}>
                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}>
                                                                <input type="text" defaultValue={moment(dateToday).format("DD/MM/YYYY")} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 pt-3 px-4 box-scroll">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div>
                                                        <ul className="list-order">
                                                            {mainData.length ? mainData.map((val, index) => (
                                                                <li className={`position-relative ${stateActive.id === val.id && 'active'}`} key={index}>
                                                                    <a onClick={(e) => handleActive(mainData[index])} className="w-75 a-black">
                                                                        <div className="sub-topic weight-bold">{val.tableId.name}</div>
                                                                        <div className="text-gray">{moment(val.dateActive).format('HH:mm')}</div>
                                                                    </a>
                                                                    <span onClick={(e) => handleEdit(mainData[index])} className="btn-del-icon position-absolute absolute-top-end cursor-pointer" style={{ right: '40px' }} >
                                                                        <img src={edit} style={{ width: '30px' }} />
                                                                    </span>
                                                                    <span onClick={(e) => setModalDel({ view: true, id: val.id, type: 'order' })} className="btn-del-icon position-absolute absolute-top-end cursor-pointer">
                                                                        <img src={bin} style={{ width: '30px' }} />
                                                                    </span>
                                                                </li>

                                                            ))
                                                                : null}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                                {editview.view ? <>
                                    <div className="col-12 col-md-12 col-lg-5 ps-0">
                                        <div className="box-in bg-gray p-3" style={{ height: 'calc(100vh - 4rem)', borderRadius: '0 22px 22px 0' }}>
                                            {stateActive.tableId && <>
                                                <div className="bg-white">
                                                    <div className="p-3" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.10)' }}>
                                                        <div className="topic weight-bold">
                                                            {stateActive.tableId && stateActive.tableId.name}
                                                        </div>
                                                        <div className="text-gray" style={{ marginTop: '-4px' }}>{moment(stateActive.dateActive).format('HH:mm')}</div>
                                                    </div>
                                                    <div className="mt-4 pt-3 px-4 box-scroll">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="sub-topic-small weight-medium mb-2">รายการส่งของขวัญ</div>
                                                                {stateActive.gift && stateActive.gift.length ? stateActive.gift.map((val, index) => (
                                                                    <div className="font-14 position-relative mb-1" key={index}>
                                                                        <span className={`${val.status === '2' ? 'cicle-green' : 'cicle-gray'}`}></span> {val.name + "X" + val.qty}
                                                                        <span onClick={(e) => setModalDel({ view: true, id: val.id, type: 'gift' })} className="cursor-pointer position-absolute absolute-center-right">
                                                                            <img src={cancel} alt="" style={{ width: '18px' }} />
                                                                        </span>
                                                                    </div>
                                                                )) : <div className="font-14 position-relative mb-1">-</div>}
                                                            </div>
                                                            <div className="col-12 mt-5">
                                                                <div className="sub-topic-small weight-medium">รายการ Tips</div>
                                                                {stateActive.tips && stateActive.tips.length ? stateActive.tips.map((val, index) => (
                                                                    <div className="font-14 position-relative" key={index}>
                                                                        <span className={`${val.status === '1' ? 'cicle-green' : 'cicle-gray'}`}></span> {val.name}
                                                                        <span onClick={(e) => setModalDel({ view: true, id: val.id, type: 'tips' })} className="cursor-pointer position-absolute absolute-center-right">
                                                                            <img src={cancel} alt="" style={{ width: '18px' }} />
                                                                        </span>
                                                                    </div>
                                                                )) : <div className="font-14 position-relative mb-1">-</div>}
                                                            </div>
                                                            <div className="col-12 mt-5">
                                                                <div className="sub-topic-small weight-medium">รายการโปรโมทขึ้นจอ</div>
                                                                {/* <div className="font-14 position-relative" >
                                                                <span className={`${'cicle-gray'}`}></span> โปรโมทขึ้นจอ
                                                                <span className="position-absolute absolute-center-right"><img src={cancel} alt="" style={{ width: '18px' }} /></span>
                                                            </div> */}
                                                                <div className="font-14 position-relative mb-1">-</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <div className="mt-2">
                                                <button className="btn btn-order active w-100" onClick={(e) => setModalPrint({ dataOrder: stateActive, id: stateActive.id, view: true, type: 'bill' })} style={{ fontSize: '20px', padding: '14px' }}>ชำระเงิน</button>
                                            </div> */}
                                            </>}
                                        </div>
                                    </div>
                                </> :
                                    <div className="col-12 col-md-12 col-lg-5 ps-0">
                                        <div className="box-in bg-gray p-3" style={{ height: 'calc(100vh - 4rem)', borderRadius: '0 22px 22px 0' }}>
                                            {stateActive.tableId && <>
                                                <div className="bg-white">
                                                    <div className="pt-4 px-4 box-scroll-c">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="text-start">
                                                                    <img src={logo} className="" style={{ height: '55px' }} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                <div className="sub-topic weight-bold">{stateActive.tableId && stateActive.tableId.name}</div>
                                                                <div className="text-gray font-12 weight-light" style={{ marginTop: '-4px' }}>{moment(stateActive.dateActive).format('HH:mm')}</div>
                                                                <div className="font-12 mt-2 mb-3">ลูกค้า #XXXXXX</div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="sub-topic-small weight-medium mb-2">รายการส่งของขวัญ</div>
                                                                {stateActive.gift && stateActive.gift.filter(user => user.status === 2).length ? stateActive.gift.map((val, index) => (
                                                                    <div className="font-14 position-relative mb-1" key={index}>
                                                                        {val.name + "X" + val.qty}
                                                                        <span className="position-absolute absolute-center-right">{(val.qty * val.price).toLocaleString()}</span>
                                                                    </div>
                                                                )) : <div className="font-14 position-relative mb-1">-</div>}
                                                            </div>
                                                            <div className="col-12 mt-5">
                                                                <div className="sub-topic-small weight-medium">รายการ Tips</div>
                                                                {stateActive.tips && stateActive.tips.length ? stateActive.tips.map((val, index) => (
                                                                    <div className="font-14 position-relative" key={index}>
                                                                        {val.name}
                                                                        <span className="position-absolute absolute-center-right">{(val.price).toLocaleString()}</span>
                                                                    </div>
                                                                )) : <div className="font-14 position-relative mb-1">-</div>}
                                                            </div>
                                                            {/* <div className="col-12 mt-5">
                                                                <div className="sub-topic-small weight-medium">รายการโปรโมทขึ้นจอ</div>
                                                                {stateActive.promote && stateActive.promote.length ? stateActive.promote.map((val, index) => (
                                                                    <div className="font-14 position-relative mb-1" key={index}>
                                                                        โปรโมทขึ้นจอ
                                                                        <span className="position-absolute absolute-center-right">{(val.price).toLocaleString()}</span>
                                                                    </div>
                                                                )) : <div className="font-14 position-relative mb-1">-</div>}
                                                            </div> */}
                                                            <div className="col-12 mt-5">
                                                                <div className="position-relative" >
                                                                    ยอดรวม
                                                                    <span className="position-absolute absolute-center-right">{(sumTotal(stateActive.gift.filter(user => user.status === 2), 'gift') + sumTotal(stateActive.tips, 'tips')).toLocaleString()}</span>
                                                                </div>
                                                                <div className="position-relative" >
                                                                    ส่วนลด
                                                                    <span className="position-absolute absolute-center-right">-00.00</span>
                                                                </div>
                                                                <div className="position-relative sub-topic-small weight-medium" >
                                                                    <span className="">ยอดชำระ</span>
                                                                    <span className="position-absolute absolute-center-right">{(sumTotal(stateActive.gift.filter(user => user.status === 2), 'gift') + sumTotal(stateActive.tips, 'tips')).toLocaleString()}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="mt-2">
                                                    {/* <button className="btn btn-blue text-white w-100" onClick={(e) => setModalPrint({ dataOrder: stateActive, id: stateActive.id, view: true, type: 'print' })} style={{ fontSize: '20px', padding: '14px' }}>พิมพ์ใบเสร็จ</button> */}
                                                    <ReactToPrint
                                                        trigger={() => <button className="btn btn-blue text-white w-100">ยืนยัน</button>}
                                                        content={() => componentRef.current}
                                                        documenttTitle='name document'
                                                        onAfterPrint={() => printSuccess(1)}
                                                    />
                                                    <div className='position-absolute d-none'>
                                                        <div className="ticket" ref={componentRef} >
                                                            <div className="" />
                                                            <ComponentPrint
                                                                dataId={1}
                                                                dataTable={stateActive}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalDel.view ?
                    <ModalDel show={modalDel.view}
                        dataId={modalDel.id}
                        onHide={() => setModalDel({ view: false })}
                        type={modalDel.type}
                        onRefresh={refreshData}
                    />
                    : null
            }{
                modalPrint.view ?
                    <ModalPrint show={modalPrint.view}
                        dataId={modalPrint.id}
                        dataOrder={modalPrint.dataOrder}
                        onHide={() => setModalPrint({ view: false })}
                        type={modalPrint.type}
                        onRefresh={refreshData}
                    />
                    : null
            }

        </>
    );
};

export default Main;
