import { useEffect, useRef } from 'react';

import { canvasPreview } from './canvasPreview';

export default function CropPreview({ img, crop,table,dataId }) {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (!crop?.width || !crop?.height || !img || !canvasRef.current) {
            return;
        }

        canvasPreview(img, canvasRef.current, crop, 1, 0,table,dataId);
    }, [img, crop]);

    if (!!crop && !!img) {
   
        return <canvas ref={canvasRef} />;
    }
}