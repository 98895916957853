import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';

import Loading from "../../layout/loading";
import { getDataNone } from "../../services/getService";

import Footer from "../../layout/footer"


import logo from "../../assets/img/LOGO.svg";

const Main = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [twoData, setTwoData] = useState([]);

    const [mainData, setMainData] = useState([]);

    const called = useRef(true)

    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }

    }, [])

    useEffect(() => {
        const interval = setInterval(        
            () => fetchData(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")
            ), 20000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchData = async (startDate, endDate) => {

        getDataNone('entertain?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    if (response.data) {
                        setMainData(response.data)
                        setTwoData(response.data.data)
                        // filteredData(response.data, searchData.button,searchData.dateStart,searchData.dateEnd)
                    } else {
                        setIsLoading(true);
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
                setIsLoading(true);
            })
    }



    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <img src={logo} className="py-3" style={{ height: '100px' }} alt="" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    {/* {time} */}
                                </div>
                            </div>
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white overflow-hidden" style={{ height: 'calc(100vh - 8.8rem)' }}>
                                        {/* <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <span className="align-middle sub-topic weight-medium ms-4">วันที่ {moment().format("DD/MM/YYYY")}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6"></div>
                                        </div>
                                        <hr /> */}
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 pt-4 overflow-auto" style={{ height: 'calc(100vh - 8.8rem)' }}>
                                                    <table className="table table-striped table-list table-en">
                                                        <thead>
                                                            <tr className="table-detail">
                                                                <th style={{width:'160px'}}>ลำดับที่</th>
                                                                <th style={{width:'120px'}}>เวลา</th>
                                                                <th style={{width:'320px'}}>รายการ</th>
                                                                <th style={{width:'100px'}}>โต๊ะ</th>
                                                                <th >ข้อความ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {twoData.length ? twoData.map((val, index) => ( val.table ?
                                                                <tr className="table-detail" key={index}>
                                                                    <td className="text-center py-3">{val.no}</td>
                                                                    <td className="py-3">{moment(val.dateActive).format("HH:mm")}</td>
                                                                    <td className="py-3">{val.product && val.product.name ? val.product.name + 'X' + val.qty : 'Tips '+val.price}</td>
                                                                    <td className="py-3">{val.table.name}</td>
                                                                    <td className="py-3">{val.message}</td>
                                                                </tr> : null
                                                            )) : <tr>
                                                                <td colSpan={7} className="text-center">No data</td>
                                                            </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Main;
