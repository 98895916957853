import React, { useState, useRef, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Loading from "../../layout/loading";
import { FileUploader } from "../../components/FileUploader";
import imgBin from "../../assets/img/icon/binImg.svg";

import { createData, putDataProduct } from "../../services/getService";

const Main = ({ dataId, type, onRefresh, dataOne, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [inputData, setInputData] = useState({ name: '', price: '', img: '' });
    const [fileUpload, setFileUpload] = useState({ url: '', name: '', file: '' });

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            if (type === 'edit') {
                setIsLoading(false);
                setInputData(dataOne[0])
                setFileUpload({ url: process.env.REACT_APP_API_LINK_FILE + '/product/' + dataOne[0].id + '/' + dataOne[0].img, name: dataOne[0].name, file: dataOne[0].img });
            }
        }
        return () => {
            called.current = false;
        };
    }, []);


    const handleFile = (fileName, fileUrl, file) => {
        console.log(file)
        setFileUpload({ url: fileUrl, name: fileName, file: file });
    };

    const [validated, setValidated] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setIsLoading(true);

            if (type === 'add') {

                dataForm.set('file', fileUpload.file);

                await createData('product', dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                onRefresh()
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            } else {

                dataForm.set('file', fileUpload.file);

                await putDataProduct('product', dataId, dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                onRefresh()
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            }
        }
        setValidated(true);
    };

    console.log(inputData)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading ? <Loading border={true} /> : null}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            <div className="sub-topic weight-bold text-center mb-4">
                                {type === 'add' ? 'เพิ่มเมนู' : type === 'edit' ? 'แก้ไขเมนู' : 'เพิ่มยอดสะสม'}
                            </div>
                            <div className="px-3">
                                {type === 'add' || type === 'edit' ?
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <div className="my-5 text-center">
                                            {/* <FileUploader handleFile={handleFile} /> */}
                                            {fileUpload.url ?
                                                <>
                                                    <div className="position-relative d-inline-block button-upload cursor-pointer">
                                                        <img src={fileUpload.url} alt={fileUpload.name} className="h-100" />
                                                        <img src={imgBin} className="position-absolute cursor-pointer" onClick={(e) => setFileUpload({ url: '', name: '' })} style={{ bottom: '5px', right: '5px' }} />
                                                    </div>
                                                </>
                                                : <FileUploader handleFile={handleFile} />}
                                        </div>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">ชื่อเมนู*</Form.Label>
                                            <Form.Control type="text" name="name" required defaultValue={inputData.name} className="input-custom py-2" placeholder="ชื่อเมนู" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">ราคา*</Form.Label>
                                            <Form.Control type="number" name="price" required defaultValue={inputData.price} className="input-custom py-2" placeholder="ราคา" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className="font-14 weight-medium mb-1 mt-4">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-12"><button className="btn btn-addmember text-white w-100">บันทึก</button></div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    :
                                    <Form>

                                        <Form.Group className="mb-3 mt-5" controlId="exampleForm.ControlInput1">
                                            <div className="font-14 weight-medium mb-1">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>
                                            <div className="">
                                                <button className="btn btn-submit text-white w-100">บันทึก</button>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
