import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { delData } from "../../services/getService";
import logo from "../../assets/img/icon/logo-black.svg";
import ComponentPrint from '../../components/PrintOrder';
import Loading from "../../layout/loading";
import moment from "moment";

import ReactToPrint from 'react-to-print';

const Main = ({ dataId, dataOrder, type, onRefresh, ...data }) => {
    const componentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [stateActive, setStateActive] = useState({});
    const dataType = () => {

        if (type === 'member') {
            return 'ลบชื่อลูกค้า'
            // } else if (type === 'singer') {
            //     return 'ลบข้อมูลนักร้อง'
            // } else if (type === 'table') {
            //     return 'ลบข้อมูลโต๊ะ'
            // } else if (type === 'product') {
            //     return 'ลบข้อมูลเมนู'
        } else {
            return 'ลบบิล'
        }
    }

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            // Promise.all([fetchData()]).then(res => {
            setIsLoading(false);
            setStateActive(dataOrder)
            // });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const handleSubmit = async (id) => {

        setIsLoading(true)

        await delData(type, id).then(
            response => {
                if (response.status === 'success') {
                    setTimeout(() => {
                        onRefresh()
                    }, 100);
                }
            }).catch(e => {
                setIsLoading(false)
                console.log(e.response.data.messages.errors)
            })
    }

    const sumTotal = (e, type) => {
        let content = e;
        if (content && content.length) {
            let sum = content.reduce(function (prev, current) {
                if (type === 'gift') {
                    return prev + +(current.price * current.qty)
                } else {
                    return prev + +(current.price)
                }
            }, 0);
            return (sum)
        } else {
            return 0
        }
    }

    const printSuccess = (e) => {
        // alert(e)
        if (type === 'bill') {
            handleSubmit(e)
        } else {
            onRefresh()
        }

    }


    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            {type === 'bill' || type === 'print' && stateActive ? <>
                                <div className="px-4 box-scroll">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="text-start">
                                                <img src={logo} className="" style={{ height: '55px' }} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="sub-topic weight-bold">{stateActive.tableId && stateActive.tableId.name}</div>
                                            <div className="text-gray font-12 weight-light" style={{ marginTop: '-4px' }}>{moment(stateActive.dateActive).format('HH:mm')}</div>
                                            <div className="font-12 mt-2 mb-3">ลูกค้า #XXXXXX</div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="sub-topic-small weight-medium mb-2">รายการส่งของขวัญ</div>
                                            {stateActive.gift && stateActive.gift.filter(user => user.status === '2').length ? stateActive.gift.filter(user => user.status === '2').map((val, index) => (
                                                <div className="font-14 position-relative mb-1" key={index}>
                                                    {val.name + "X" + val.qty}
                                                    <span className="position-absolute absolute-center-right">{(val.qty * val.price).toLocaleString()}</span>
                                                </div>
                                            )) : <div className="font-14 position-relative mb-1">- No data -</div>}
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="sub-topic-small weight-medium">รายการ Tips</div>
                                            {stateActive.tips && stateActive.tips.length ? stateActive.tips.map((val, index) => (
                                                <div className="font-14 position-relative" key={index}>
                                                    {val.name}
                                                    <span className="position-absolute absolute-center-right">{parseFloat(val.price).toLocaleString()}</span>
                                                </div>
                                            )) : <div className="font-14 position-relative mb-1">- No data -</div>}
                                        </div>
                                        {/* <div className="col-12 mt-4">
                                            <div className="sub-topic-small weight-medium">รายการโปรโมทขึ้นจอ</div>
                                            {stateActive.promote && stateActive.promote.length ? stateActive.promote.map((val, index) => (
                                                <div className="font-14 position-relative" key={index}>
                                                    โปรโมทขึ้นจอ
                                                    <span className="position-absolute absolute-center-right">{parseFloat(val.price).toLocaleString()}</span>
                                                </div>
                                            )) : <div className="font-14 position-relative mb-1">- No data -</div>}
                                        </div> */}
                                        <div className="col-12 mt-5">
                                            <div className="position-relative" >
                                                ยอดรวม
                                                <span className="position-absolute absolute-center-right">{(sumTotal(stateActive.gift ? stateActive.gift.filter(user => user.status === '2') : stateActive.gift, 'gift') + sumTotal(stateActive.tips, 'tips')).toLocaleString()}</span>
                                            </div>
                                            <div className="position-relative" >
                                                ส่วนลด
                                                <span className="position-absolute absolute-center-right">-00.00</span>
                                            </div>
                                            <div className="position-relative sub-topic-small weight-medium" >
                                                <span className="">ยอดชำระ</span>
                                                <span className="position-absolute absolute-center-right">{(sumTotal(stateActive.gift ? stateActive.gift.filter(user => user.status === '2') : stateActive.gift, 'gift') + sumTotal(stateActive.tips, 'tips')).toLocaleString()}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="sub-topic weight-bold text-center mt-4 mb-4">
                                {dataType()}
                            </div>
                            <div className="px-3 text-center">
                                ยืนยันการ{dataType()} เมื่อทำรายการคุณจะไม่สามารถ <br />
                                กู้คืนข้อมูลได้อีก
                            </div> */}

                                {/* <div className="mt-5">
                                    <button className="btn btn-del text-white w-100" onClick={(e) => handleSubmit(dataId)}>ยืนยัน</button>
                                </div> */}
                                <ReactToPrint
                                    trigger={() => <button className="btn btn-blue text-white w-100">ยืนยัน</button>}
                                    content={() => componentRef.current}
                                    documenttTitle='name document'
                                    onAfterPrint={() => printSuccess(dataId)}
                                />
                                <div className='position-absolute d-none'>
                                    <div className="ticket" ref={componentRef} >
                                        <div className="" />
                                        <ComponentPrint
                                            dataId={dataId}
                                            dataTable={stateActive}
                                        />
                                    </div>
                                </div>
                            </>
                                :
                                null
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
