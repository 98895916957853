import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Loading from "../../layout/loading";
import ModalMenu from "../../layout/modal/menuModel";

import { getDataNone } from "../../services/getService";

// import product1 from "../../assets/img/product2.png";
// import tips from "../../assets/img/icon/tips.png";
import orders from "../../assets/img/icon/Order.svg";
import back from "../../assets/img/icon/Back.svg";

const Home = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [mainData, setMainData] = useState([]);
    const [tableData, setTableData] = useState({});
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(pr.id)]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchData = async (id) => {

        getDataNone('order', id).then(
            response => {
                if (response.status === 'success') {
                    if (response.data) {
                        setMainData(response.data)
                        fetchTable(response.data.tableId)
                    } else {
                        setIsLoading(true);
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
                setIsLoading(true);
            })
    }
    const fetchTable = async (id) => {

        getDataNone('table', id).then(
            response => {
                if (response.status === 'success') {
                    setTableData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }


    //   const handleLogout = () => {
    //     fetchLogout()
    //   }
    const [inputpin, setInputpin] = useState({ price: 0, type: 'singer', text: '' });

    const array = [20, 50, 100, 200, 500, 1000]

    const handleAddItem = (e, t) => {
        // setInputpin((oldArray) => [...oldArray, e])


        if (t === 'price') {
            setInputpin((prev) => ({ ...prev, price: e }))
            setModalMenu({ view: true, id: 1, type: 'con', price: e })
        } else {
            setInputpin((prev) => ({ ...prev, type: e }))
        }
    }

    const refreshData = (e) => {
        // fetchData();
        setInputpin((prev) => ({ ...prev, text: e }))
        setModalMenu({ view: false })
        // setModalDel({ view: false })
    };


    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="position-absolute" style={{ top: '10px', left: '10px' }}>
                                        <a href={"/qrmenu/" + pr.id} className="text-white btn-default">
                                            <img src={back} className="" alt="" /> ย้อนกลับ
                                        </a>
                                    </div>
                                    <div className="box-in-res bg-white text-center px-3" style={{ minHeight: 'calc(100vh - 3rem)' }}>
                                        <div className="topic weight-bold pt-4">โต๊ะ {tableData.name}</div>
                                        <div className="font-14 weight-light">{moment(mainData.dateActive).format('DD/MM/YYYY')}&nbsp; | &nbsp;Check in : {moment(mainData.dateActive).format('HH:mm')} น.</div>
                                        <div className="row justify-content-md-center text-start">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="topic-small weight-bold pt-4 pb-3">Tips</div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center text-start px-4">
                                            {array.map((val) => (
                                                <div className="col-6 col-md-3 col-lg-2 p-1" key={val}>
                                                    <div className={`tips-price cursor-pointer ${val === inputpin.price && 'active'}`} onClick={() => handleAddItem(val, 'price')}>
                                                        {val.toLocaleString()}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="row justify-content-md-center text-start">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="sub-topic weight-medium pt-4 pb-3">ข้อความ</div>
                                                <div>{inputpin.text}</div>
                                            </div>
                                        </div>
                                        {/* <div className="row justify-content-md-center text-start px-4">
                                            <div className="col-6 col-md-3 col-lg-2 p-1">
                                                <div className={`tips-type cursor-pointer ${'singer' === inputpin.type && 'active'}`} onClick={() => handleAddItem('singer', 'type')}>
                                                    ศิลปิน
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-3 col-lg-2 p-1">
                                                <div className={`tips-type cursor-pointer ${'dj' === inputpin.type && 'active'}`} onClick={() => handleAddItem('dj', 'type')}>
                                                    DJ
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                    </div>

                                    <div className="px-3 box-order-list">
                                        <div className="font-20 weight-medium">ยอด Tips</div>
                                        <div className="font-28 weight-bold">{inputpin.price.toLocaleString()}.-</div>
                                        <div className="font-12 mb-2">หมายเหตุ : เมื่อกดยืนยันไม่สามารถยกเลิกและเปลี่ยนแปลงเป็นเงินสดได้</div>
                                        <div className="position-relative" style={{ paddingRight: '65px' }}>
                                            <button className="btn btn-send-order w-100" disabled={!inputpin.price} onClick={() => setModalMenu({ view: true, id: pr.id, type: 'tips' })}>ส่งรายการ</button>
                                            <a href={"/qrlist/" + pr.id} className="position-absolute" style={{ top: '-5px', right: '-15px' }}>
                                                <img src={orders} className="" alt="" />
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <ModalMenu show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                        tipsPrice={inputpin.price}
                        tipsText={inputpin.text}
                        tipsType={inputpin.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Home;
