import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { delData } from "../../services/getService";
import delIcon from "../../assets/img/icon/Del.svg";
import Loading from "../../layout/loading";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop';

const Main = ({ dataId, fileData, imgRef, onRefresh, setcompletedCrop, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);
    // const [completedCrop, setCompletedCrop] = useState()
    const [crop, setCrop] = useState({
        unit: '%',
        width: 30,
        aspect: 16 / 9,

    })

    const onImageLoad = (e) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = centerCrop(
            makeAspectCrop({ unit: '%', width: 100 },
                16 / 9,
                width,
                height,
            ),
            width,
            height
        )

        setCrop(crop)
        console.log(crop)
    }

    const handleSubmit = async (id) => {

    }
    console.log(crop)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header >
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            <div className="text-center mt-5">
                                <ReactCrop crop={crop}
                                    onChange={c => setCrop(c)}
                                    aspect={16 / 9} locked
                                    onComplete={(c) => setcompletedCrop(c)}
                                    maxWidth={1920}
                                    maxHeight={1080}>
                                    <img src={fileData} ref={imgRef} onLoad={onImageLoad} />
                                </ReactCrop>
                            </div>
                            <div className="mt-5 mb-4">
                            {/* "" */}
                                    <button className="btn btn-del text-white w-100" style={{opacity: `${crop && crop.unit === 'px' ? '1' : '0'}`}}  onClick={data.onHide}>
                                        ยืนยัน
                                    </button>
                                   
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
