import React, { useState, useEffect, useRef } from "react";

import { getData } from "../../services/getService";

import ModalAdd from "../../layout/modal/productModal";
import ModalDel from "../../layout/modal/delData";
import Loading from "../../layout/loading";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import imgBin from "../../assets/img/icon/binImg.svg";
import plus from "../../assets/img/icon/AddPlus.svg";


const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [modalAdd, setModalAdd] = useState({ view: false, id: 0, type: '' })
    const [modalDel, setModalDel] = useState({ view: false, id: 0, type: '' })

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async () => {

        getData('product').then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const refreshData = () => {
        fetchData();
        setModalAdd({ view: false })
        setModalDel({ view: false })
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="pb-5 mb-4" style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="p-3">
                                                        <Menu page={'entertain'} subPage={'settingMenu'} />
                                                        <span className="align-middle sub-topic weight-medium ms-4">ตั้งค่ารายการเมนู</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="p-3 text-end">
                                                        <button onClick={(e) => setModalAdd({ view: true, id: 0, type: 'add' })} className="btn btn-addmenu sub-topic-small text-white py-2 px-5">
                                                            <img src={plus} className="me-1" style={{ height: '18px' }} alt="" /> เพิ่มเมนู
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 pt-3 px-4 box-scroll">
                                            <div className="row">
                                                {mainData.length ? mainData.map((val, index) => (
                                                    <div className="col-4 col-md-2 col-lg-2 p-1" key={index}>
                                                        <div className="p-2 box-product">
                                                            <div onClick={(e) => setModalAdd({ view: true, id: val.id, type: 'edit' })} className="box-product-img text-center mb-2 box-ra" style={{ backgroundImage: `url(${process.env.REACT_APP_API_LINK_FILE + '/product/' + val.id + '/' + val.img})` }}></div>
                                                            <div onClick={(e) => setModalAdd({ view: true, id: val.id, type: 'edit' })} className="weight-medium" style={{ height: '48px' }}>
                                                                {val.name}
                                                            </div>
                                                            <div className="sub-topic-small weight-bold position-relative">
                                                                {val.price}.-
                                                                <div className="position-absolute" style={{ bottom: '-5px', right: '-5px' }}>
                                                                    <img src={imgBin} className="cursor-pointer" onClick={(e) => setModalDel({ view: true, id: val.id, type: 'product' })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <div className="col-12 col-md-12 col-lg-12 py-5 my-5 text-center">-- No data --</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalAdd.view ?
                    <ModalAdd show={modalAdd.view}
                        dataId={modalAdd.id}
                        onHide={() => setModalAdd({ view: false })}
                        type={modalAdd.type}
                        dataOne={mainData.filter((item) => item.id === modalAdd.id)}
                        onRefresh={refreshData}
                    />
                    : null
            }
            {
                modalDel.view ?
                    <ModalDel show={modalDel.view}
                        dataId={modalDel.id}
                        onHide={() => setModalDel({ view: false })}
                        type={modalDel.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Main;
