import React from "react";

const Footer = () => {
    return (
        <>
            <div className="box-footer weight-light font-12 text-center text-white position-absolute">Copyright © 2023 STATUS All rights reserved.</div>
        </>
    );
};

export default Footer
