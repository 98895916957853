import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import moment from 'moment';

import { getData } from "../../services/getService";
import Loading from "../../layout/loading";


const Footer = ({ idZone, dateData, onRefresh }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [twoData, setTwoData] = useState([]);
    
    const called = useRef(true);

    useEffect(() => {
        if (called.current) {

            if (dateData.name !== '') {
                // console.log(mainData)
                const updatedList = mainData.filter((item) => {
                    return item.singerName.toLowerCase().indexOf(dateData.name.toLowerCase()) !== -1;
                });
                setTwoData(updatedList)
            } else {
                Promise.all([fetchData(dateData.name, dateData.startDate, dateData.endDate)]).then(res => {
                    setIsLoading(false);
                });
            }
        }
        // return () => {
        //     called.current = false;
        // };
    }, [dateData]);

    const fetchData = async (name, startDate, endDate) => {

        getData('reportGift?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    setTwoData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    return (
        <>
            {idZone === 'gift' ?
                <div className="row hr-custom">
                    {isLoading ? <Loading /> : null}
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="px-3 box-scroll-b">
                            <table className="table table-striped table-list">
                                <thead>
                                    <tr>
                                        <th style={{ width: '150px' }}>วันที่</th>
                                        <th style={{ width: '100px' }}>เวลา</th>
                                        <th>ชื่อนักร้อง</th>
                                        <th>รายการ</th>
                                        <th style={{ width: '100px' }}>โต๊ะ</th>
                                        <th>ข้อความ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {twoData.length ? twoData.map((val, index) => (
                                        <tr key={index}>
                                            <td>{moment(val.dateActive).format("DD/MM/YYYY")}</td>
                                            <td>{moment(val.dateActive).format("HH:mm")}</td>
                                            <td>{val.singerName}</td>
                                            <td>{val.product.name}X{val.qty}</td>
                                            <td>{val.order && val.order.table && val.order.table.name}</td>
                                            <td>{val.message}</td>

                                        </tr>
                                    )) : <tr>
                                        <td colSpan={6} className="text-center">-- No data --</td>
                                    </tr>}
                                </tbody>                               
                            </table>
                        </div>
                    </div>
                </div>
                : <div>No data</div>
            }

        </>
    );
};

export default Footer
