import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import { getData } from "../../services/getService";

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ModalAddMember from "../../layout/modal/memberModal";
import Loading from "../../layout/loading";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import menu from "../../assets/img/icon/Menu.svg";
import pdf from "../../assets/img/icon/Pdf.svg";
import xls from "../../assets/img/icon/Xls.svg";
import plus from "../../assets/img/icon/AddPlus.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";


const Main = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [memberData, setMemberData] = useState({});
    const [searchData, setSearchData] = useState({button:'month',dateStart:'',dateEnd:''});
    const [mainData, setMainData] = useState([]);
    const [modalAddMember, setModalAddMember] = useState({ view: false, id: 0, type: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };
    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({button:'date',dateStart:picker.startDate.format('YYYY-MM-DD'),dateEnd:picker.endDate.format('YYYY-MM-DD')})
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({button:'month',dateStart:'',dateEnd:''})
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(),fetchDataMember()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchDataMember = async () => {

        getData('member', pr.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const fetchData = async () => {

        getData('amount', pr.id).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const refreshData = () => {
        fetchData();
        setModalAddMember({ view: false })
    };

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))
    }

    const filteredData = mainData.filter((item) => {
        const itemDate = new Date(item.dateActive);
        if(searchData.button === 'month'){
            const dateTo = moment().format("YYYY-MM");
            return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        }else if(searchData.button === 'last'){
            const dateTo = moment().subtract('1', 'month').format("YYYY-MM");
            return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        }else if(searchData.button === 'year'){
            const dateTo = moment().format("YYYY");
            return (itemDate >= new Date(dateTo + '-01-01')) && (itemDate <= new Date(dateTo + '-12-31'));
        }else{
            return (itemDate >= new Date(searchData.dateStart)) && (itemDate <= new Date(searchData.dateEnd));
        }
    });

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <Menu page={'users'} />
                                                    <span className="align-middle sub-topic-small weight-medium ms-4">รายงานการเข้าใช้บริการ</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3 text-end">
                                                    {/* <button className="btn px-0">
                                                        <img src={pdf} className="" style={{ height: '45px' }} alt="" />
                                                    </button>
                                                    <button className="btn">
                                                        <img src={xls} className="" style={{ height: '45px' }} alt="" />
                                                    </button> */}
                                                    <button onClick={(e) => setModalAddMember({ view: true, id: pr.id, type: 'amount' })} className="btn btn-addpoint-add sub-topic-small text-white py-2">
                                                        <img src={plus} className="me-1" style={{ height: '18px' }} alt="" /> เพิ่มยอดสะสม
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="px-4">
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <a href="/" className="btn btn-menu py-0 px-md-3 px-lg-3" style={{ opacity: 0 }}>
                                                                <img src={menu} className="" style={{ height: '30px' }} alt="" />
                                                            </a>
                                                            <span className="sub-topic weight-medium ms-4">
                                                                {memberData.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="row px-4 justify-content-md-end">
                                                    <div className="col-auto p-0">
                                                        <button onClick={() => handleSearch('month')} className={`btn btn-border-black me-1 ${searchData.button === 'month' && 'active'}`}>
                                                            เดือนนี้
                                                        </button>
                                                        <button onClick={() => handleSearch('last')} className={`btn btn-border-black me-1 ${searchData.button === 'last' && 'active'}`}>
                                                            เดือนที่แล้ว
                                                        </button>
                                                        <button onClick={() => handleSearch('year')} className={`btn btn-border-black me-1 ${searchData.button === 'year' && 'active'}`}>
                                                            ปีนี้
                                                        </button>
                                                    </div>
                                                    <div className="col-auto ps-0 pe-1">
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                // onEvent={handleEvent} onCallback={handleCallback}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}                                                          
                                                            >
                                                                <input type="text" value={searchData.dateStart ? moment(searchData.dateStart).format("DD/MM/YYYY") +' - '+moment(searchData.dateEnd).format("DD/MM/YYYY") : ''} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 box-scroll">
                                                    <table className="table table-striped table-list">
                                                        <thead>
                                                            <tr className="table-detail">
                                                                <th>ลำดับที่</th>
                                                                <th>วันที่</th>
                                                                <th>ยอดสะสม</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredData.length ? filteredData.map((val, index) => (
                                                                <tr className="table-detail" key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{moment(val.dateActive).format('DD/MM/YYYY')}</td>
                                                                    <td>{parseInt(val.amount).toLocaleString()}</td>
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={3} className="table-detail text-center">-- No data --</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalAddMember.view ?
                    <ModalAddMember show={modalAddMember.view}
                        dataId={modalAddMember.id}
                        onHide={() => setModalAddMember({ view: false })}
                        type={modalAddMember.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Main;
