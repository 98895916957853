import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import moment from "moment";

import logo from "../assets/img/icon/logo-black.svg";
// import '../assets/css/print.css';

// import PostService from "./services_member/post.service";
// import AuthService from "./services_member/auth.service";

// const getToken = () => {
//     const tokenString = localStorage.getItem("login_member");
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const Main = ({ dataId, dataTable, ...data }) => {

    const sumTotal = (e, type) => {
        let content = e;
        if (content && content.length) {
            let sum = content.reduce(function (prev, current) {
                if (type === 'gift') {
                    return prev + +(current.price * current.qty)
                } else {
                    return prev + +(current.price)
                }
            }, 0);
            return (sum)
        } else {
            return 0
        }
    }

    return (
        <>
            {/* {data.select.map((value2, index2) => ( */}
            <div className="ticket mb-5" style={{paddingBottom:'20px'}}>
                <div className='mt-3 font-12 text-start'>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            <tr>
                                <th className="quantity"><img src={logo} className="" style={{ height: '35px' }} alt="" /></th>
                                <th className="text-end">
                                    <div className="font-18 weight-medium">{dataTable.tableId && dataTable.tableId.name}</div>
                                    <div className="text-gray font-10 weight-light" style={{ marginTop: '-4px' }}>{moment(dataTable.dateActive).format('HH:mm')}</div>
                                    <div className="font-10 mt-2 mb-3 weight-light">ลูกค้า #XXXXXX</div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div className="font-14 weight-medium">รายการส่งของขวัญ</div>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            {dataTable.gift && dataTable.gift.filter(user => user.status === '2').length ? dataTable.gift.filter(user => user.status === '2').map((val, index) => (
                                <tr key={index}>
                                    <th className="quantity">
                                        <div className="font-12 weight-light">{val.name + "X" + val.qty}</div>
                                    </th>
                                    <th className="text-end">
                                        <div className="font-12 weight-light">{(val.qty * val.price).toLocaleString()}</div>
                                    </th>
                                </tr>
                            )) : <tr><td>-</td></tr>}
                        </thead>
                    </table>

                    <div className="font-14 weight-medium mt-3">รายการ Tips</div>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            {dataTable.tips && dataTable.tips.length ? dataTable.tips.map((val, index) => (
                                <tr key={index}>
                                    <th className="quantity">
                                        <div className="font-12 weight-light">{val.name}</div>
                                    </th>
                                    <th className="text-end">
                                        <div className="font-12 weight-light">{parseFloat(val.price).toLocaleString()}</div>
                                    </th>
                                </tr>
                            )) : <tr><td>-</td></tr>}
                        </thead>
                    </table>
                    {/* <div className="font-14 weight-medium mt-3">รายการโปรโมทขึ้นจอ</div>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            {dataTable.promote && dataTable.promote.length ? dataTable.promote.map((val, index) => (
                                <tr key={index}>
                                    <th className="quantity">
                                        <div className="font-12 weight-light">โปรโมทขึ้นจอ</div>
                                    </th>
                                    <th className="text-end">
                                        <div className="font-12 weight-light">{parseFloat(val.price).toLocaleString()}</div>
                                    </th>
                                </tr>
                            )) : <tr><td>-</td></tr>}
                        </thead>
                    </table> */}

                    <table className='w-100 mt-5'>
                        <thead style={{ verticalAlign: 'top' }}>
                            <tr>
                                <th>ยอดรวม</th>
                                <th className="text-end">{(sumTotal(dataTable.gift && dataTable.gift.filter(user => user.status === '2'), 'gift') + sumTotal(dataTable.tips, 'tips')).toLocaleString()}</th>
                            </tr>
                            <tr>
                                <th>ส่วนลด</th>
                                <th className="text-end"> -00.00</th>
                            </tr>
                            <tr>
                                <th>ยอดรวม</th>
                                <th className="text-end">{(sumTotal(dataTable.gift && dataTable.gift.filter(user => user.status === '2'), 'gift') + sumTotal(dataTable.tips, 'tips')).toLocaleString()}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            {/* ))} */}
        </>
    );
};

export default Main;