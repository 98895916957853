import React, { useState, useEffect, useRef } from "react";

import ModalAdd from "../../layout/modal/tableModal";
import ModalDel from "../../layout/modal/delData";

import product2 from "../../assets/img/icon/Table.svg";
import bin from "../../assets/img/icon/bin.svg";
import plusPink from "../../assets/img/icon/PlusPink.svg";

const Footer = ({ idZone, dataTable, onRefresh }) => {

    const [modalAdd, setModalAdd] = useState({ view: false, id: 0, type: '' })
    const [modalDel, setModalDel] = useState({ view: false, id: 0, type: '' })

    const handleRefresh = () => {
        setModalAdd({ view: false })
        setModalDel({ view: false })
        onRefresh()
    }

    return (
        <>
            {idZone === 'all' ? dataTable.length ? dataTable.map((val, index) => (
                <div className="row hr-custom" key={index}>
                    {/* {val.name} */}

                    {val.table.length ? val.table.map((vall, indexx) => (
                        <div className="col-4 col-md-2 col-lg-2 p-1" key={indexx}>
                            <div className="p-2 box-table text-center position-relative">
                                <span className={`position-absolute absolute-top-end ${vall.status === '2' ? 'cicle-green' : vall.status === '3' ? 'cicle-orange' : null}`}></span>
                                <div className="mb-2 box-ra px-5 py-4">
                                    <img src={product2} className="w-100" alt="" />
                                </div>
                                <div className="sub-topic weight-bold">
                                    {vall.name}
                                </div>
                                <div className="sub-topic-small weight-medium mb-4">{vall.qty} คน</div>
                                <div className="box-action-table p-3">
                                    {vall.status === '3' ?
                                        <button disabled={vall.status === '2' ? true : vall.status === '3' ? false : false} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'nowTable', dataTable: val.table[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">มาแล้ว</button>
                                        :
                                        <button disabled={vall.status === '2' ? true : vall.status === '3' ? false : false} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'openTable', dataTable: val.table[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">เปิดโต๊ะ</button>
                                    }
                                    {/* <button onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'closeTable' })} className="btn btn-action-table btn-action-close w-100 mb-1">ปิดโต๊ะ</button> */}
                                    <button disabled={vall.status === '2' ? false : vall.status === '3' ? false : true} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'moveTable', dataTable: dataTable[index].table })} className="btn btn-action-table btn-action-move w-100 mb-1">ย้ายโต๊ะ</button>
                                    <button disabled={vall.status === '2' ? false : true} onClick={(e) => setModalAdd({ view: true, id: vall.qrcode, type: 'qrcodeTable', dataTable: val.table[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">QR CODE</button>
                                    <div className="position-absolute text-center absolute-center-bottom">
                                        <img src={bin} onClick={(e) => setModalDel({ view: true, id: vall.id, type: 'table' })} className="me-1" alt="" />
                                    </div>
                                    {/* <div className="position-absolute text-center" style={{ bottom: '15px', right: '0' }}>
                                        <img src={bin} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'qrTable',dataTable:val.table[indexx] })} className="me-1" alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    )) : null}

                    <div className="col-4 col-md-2 col-lg-2 p-1">
                        <div onClick={(e) => setModalAdd({ view: true, id: val.id, type: 'addTable' })} className="p-2 box-add-table text-center position-relative h-100">
                            <div className="position-absolute absolute-center">
                                <div className="mb-2 box-ra px-5 pb-4">
                                    <img src={plusPink} className="me-1" alt="" />
                                </div>
                                <div className="sub-topic weight-bold">
                                    เพิ่มโต๊ะ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )) : <div>No data</div>
                :
                <div className="row hr-custom">
                    {/* {val.name} */}

                    {dataTable.length ? dataTable.map((vall, indexx) => (
                        <div className="col-4 col-md-2 col-lg-2 p-1" key={indexx}>
                            <div className="p-2 box-table text-center position-relative">
                                <span className={`position-absolute absolute-top-end ${vall.status === '2' ? 'cicle-green' : vall.status === '3' ? 'cicle-orange' : null}`}></span>
                                <div className="mb-2 box-ra px-5 py-4">
                                    <img src={product2} className="w-100" alt="" />
                                </div>
                                <div className="sub-topic weight-bold">
                                    {vall.name}
                                </div>
                                <div className="sub-topic-small weight-medium mb-4">{vall.qty} คน</div>
                                <div className="box-action-table  p-3">
                                    {vall.status === '3' ?
                                        <button disabled={vall.status === '2' ? true : vall.status === '3' ? false : false} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'nowTable', dataTable: dataTable[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">มาแล้ว</button>
                                        :
                                        <button disabled={vall.status === '2' ? true : vall.status === '3' ? false : false} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'openTable', dataTable: dataTable[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">เปิดโต๊ะ</button>
                                    }
                                    {/* <button disabled={vall.status === '2' ? true : vall.status === '3' ? false : false} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'openTable', dataTable: dataTable[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">เปิดโต๊ะ</button> */}
                                    {/* <button onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'closeTable' })} className="btn btn-action-table btn-action-close w-100 mb-1">ปิดโต๊ะ</button> */}
                                    <button disabled={vall.status === '2' ? false : vall.status === '3' ? false : true} onClick={(e) => setModalAdd({ view: true, id: vall.id, type: 'moveTable', dataTable: dataTable })} className="btn btn-action-table btn-action-move w-100 mb-1">ย้ายโต๊ะ</button>
                                    <button disabled={vall.status === '2' ? false : true} onClick={(e) => setModalAdd({ view: true, id: vall.qrcode, type: 'qrcodeTable', dataTable: dataTable[indexx] })} className="btn btn-action-table btn-action-open w-100 mb-1">QR CODE</button>
                                    <div className="position-absolute text-center absolute-center-bottom">
                                        <img src={bin} onClick={(e) => setModalDel({ view: true, id: vall.id, type: 'table' })} className="me-1" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    )) : null}

                    <div className="col-4 col-md-2 col-lg-2 p-1">
                        <div onClick={(e) => setModalAdd({ view: true, id: idZone, type: 'addTable' })} className="p-2 box-add-table text-center position-relative h-100">
                            <div className="position-absolute absolute-center">
                                <div className="mb-2 box-ra px-5 pb-4">
                                    <img src={plusPink} className="me-1" alt="" />
                                </div>
                                <div className="sub-topic weight-bold">
                                    เพิ่มโต๊ะ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                modalAdd.view ?
                    <ModalAdd show={modalAdd.view}
                        dataId={modalAdd.id}
                        onHide={handleRefresh}
                        type={modalAdd.type}
                        onRefresh={handleRefresh}
                        dataTable={modalAdd.dataTable}
                    />
                    : null
            }
            {
                modalDel.view ?
                    <ModalDel show={modalDel.view}
                        dataId={modalDel.id}
                        onHide={handleRefresh}
                        type={modalDel.type}
                        onRefresh={handleRefresh}

                    />
                    : null
            }
        </>
    );
};

export default Footer
