import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import moment from "moment";
import Footer from "../layout/footer"
import Loading from "../layout/loading";
import ModalSubmenu from "../layout/modal/submenu";

import { authUser, fetchLogout, initAuth } from '../services/authenService';

import logo from "../assets/img/LOGO.svg";
import logout from "../assets/img/icon/logout.svg";
import user from "../assets/img/icon/system-user.svg";
import enter from "../assets/img/icon/system-enter.svg";
import promote from "../assets/img/icon/system-promote.svg";
import order from "../assets/img/icon/system-order.svg";
import userHover from "../assets/img/icon/system-user-hover.svg";
import enterHover from "../assets/img/icon/system-enter-hover.svg";
import promoteHover from "../assets/img/icon/system-promote-hover.svg";
import orderHover from "../assets/img/icon/system-order-hover.svg";

const Home = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useState(authUser())
  const [modalSubmenu, setModalSubmenu] = useState({ view: false, id: 0, type: '' })

  const called = useRef(true)

  useEffect(() => {
    if (called.current) {
      fetchUser()
    }
    return () => {
      called.current = false
    }
  }, [])

  const fetchUser = async () => {

    if (!auth || !auth.id)
      setTimeout(() => { window.location.reload() }, 500);

    const userData = await authUser()
    // console.log('user data : ', userData)
    setAuth(userData)



    // console.log('auth : ', auth.id)
    // const user = await getUser(auth.id)

    // if( user.status === 200 || user.status === 'success'){

    // }
  }


  const handleLogout = () => {
    fetchLogout()
  }

  return (
    <>
      <div id="outer-container" style={{}}>
        {/* <Header page="home" /> */}
        <div className="wrapper bg-main vh-100">
          {isLoading ? <Loading /> : null}
          <section className="" id="page-wrap">
            <div className="container-fluid ">
              <div className="row align-items-center text-start">
                <div className="col-12 col-md-6 col-lg-6">
                  <img src={logo} className="py-3" style={{ height: '100px' }} alt="" />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="text-end py-3 text-white">
                    <div className="topic-small weight-bold" style={{ lineHeight: 1 }}>{auth && auth.username}</div>
                    <div className="font-14 weight-light">07/11/2023&nbsp; | &nbsp;12:00</div>
                    <div className="sub-topic-small mt-2 cursor-pointer" onClick={handleLogout}><img src={logout} className="" style={{}} alt="" /> ออกจากระบบ</div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center text-start">
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="box-in bg-white text-center" style={{ height: 'calc(100vh - 11rem)' }}>
                    <div className="topic weight-bold pt-5">เลือกเมนู</div>
                    <div className="row justify-content-md-center mt-5">
                      <div className="col-12 col-md-6 col-lg-4 ps-5">
                        <Link to="/administrator/members" className="sub-topic weight-medium btn btn-system py-4 px-md-3 px-lg-5 w-100 mb-4 text-start">
                          <img src={user} className="pe-3 btn-img" style={{ height: '60px' }} alt="" />
                          <img src={userHover} className="pe-3 btn-img-hover" style={{ height: '60px' }} alt="" />
                          ระบบชื่อผู้ให้บริการ
                        </Link>
                        <a href="/administrator/promote" className="sub-topic weight-medium btn btn-system py-4 px-md-3 px-lg-5 w-100 mb-4 text-start">
                          <img src={promote} className="pe-3 btn-img" style={{ height: '60px' }} alt="" />
                          <img src={promoteHover} className="pe-3 btn-img-hover" style={{ height: '60px' }} alt="" />
                          ระบบโปรโมท
                        </a>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4 pe-5">
                        <button onClick={(e) => setModalSubmenu({ view: true, id: 2, type: '' })} className="sub-topic weight-medium btn btn-system py-4 px-md-3 px-lg-5 w-100 mb-4 text-start">
                          <img src={enter} className="pe-3 btn-img" style={{ height: '60px' }} alt="" />
                          <img src={enterHover} className="pe-3 btn-img-hover" style={{ height: '60px' }} alt="" />
                          ระบบ Entertain
                        </button>
                        <button onClick={(e) => setModalSubmenu({ view: true, id: 4, type: '' })} className="sub-topic weight-medium btn btn-system py-4 px-md-3 px-lg-5 w-100 mb-4 text-start">
                          <img src={order} className="pe-3 btn-img" style={{ height: '60px' }} alt="" />
                          <img src={orderHover} className="pe-3 btn-img-hover" style={{ height: '60px' }} alt="" />
                          ระบบจัดการออเดอร์
                        </button>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {
        modalSubmenu.view ?
          <ModalSubmenu show={modalSubmenu.view}
            userId={modalSubmenu.id}
            onHide={() => setModalSubmenu({ view: false })}
            type={modalSubmenu.type}
          // onRefresh={refreshPage}
          />
          : null
      }
    </>
  );
};

export default Home;
