import React, { useState, useRef } from "react";
import { Modal, Form } from "react-bootstrap";

import Loading from "../../layout/loading";
import { getData, createData, putData } from "../../services/getService";
import moment from 'moment';
import ComponentToqrcode from '../../components/PrintQrcode';

import addPlus from "../../assets/img/icon/AddPlus.svg";
import lopPlus from "../../assets/img/icon/Lop.svg";

import ReactToPrint from 'react-to-print';
import { TimePicker } from "antd";

const format = 'HH:mm';

const Main = ({ dataId, type, onRefresh, dataTable, ...data }) => {

    const componentRef = useRef(null);

    const [state, setState] = useState({ count: 1 });
    // const [valuetime, onChangetime] = useState('10:00');

    const [isLoading, setIsLoading] = useState(false);
    // const [inputData, setInputData] = useState({ name: '', tel: '', sex: '', birth: '', email: '' });
    const [viewQrcode, setViewQrcode] = useState({ view: false, url: '' });

    const numbers = [1, 2, 3];

    const incrementCount = () => {
        setState({
            count: state.count + 1
        });
    }
    const decrementCount = () => {
        if (state.count > 1) {
            setState({
                count: state.count - 1
            });
        }
    }

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const newErrors = findFormErrors()

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setIsLoading(true);
            if (type === 'addZone') {

                await createData('zone', dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                onRefresh()
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            } else if (type === 'addTable') {

                dataForm.set('zoneId', dataId);

                await createData('table', dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                onRefresh()
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            } else if (type === 'openTable') {

                dataForm.set('tableId', dataId);
                // console.log(dataForm.get('dateActive'))

                await createData('order', dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                if (response.data.url) {
                                    setViewQrcode({ view: true, url: response.data.url })
                                } else {
                                    onRefresh()
                                }
                                setIsLoading(false)
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            } else if (type === 'nowTable') {

                // dataForm.set('tableId', dataId);
                // console.log(dataForm.get('dateActive'))

                await putData('order', dataId).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                if (response.data.url) {
                                    setViewQrcode({ view: true, url: response.data.url })
                                } else {
                                    onRefresh()
                                }
                                setIsLoading(false)
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            } else {
                dataForm.set('id', dataId);
                dataForm.set('tableIdTo', dataForm.get('tableIdTo'));

                await putData('moveTAble', dataId, dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            setTimeout(() => {
                                onRefresh()
                            }, 100);
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
                // alert(5)
            }

        }
        setValidated(true);
    };

    const printSuccess = (e) => {
        // alert(e)
        // console.log(e)
        onRefresh()
    }

    console.log(dataTable)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading ? <Loading border={true} /> : null}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            <div className="sub-topic weight-bold text-center mb-4">
                                {type === 'addZone' ? 'เพิ่มโซน' :
                                    type === 'addTable' ? 'เพิ่มโต๊ะ' :
                                        type === 'openTable' ? 'เปิดโต๊ะ' :
                                            type === 'nowTable' ? 'ลูกค้ามาแล้ว' :
                                                type === 'closeTable' ? 'ปิดโต๊ะ' :
                                                    type === 'moveTable' ? 'ย้ายโต๊ะ' : 'QR Code'}
                            </div>
                            <div className="px-3">
                                {type === 'addZone' ?
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">ชื่อโซน*</Form.Label>
                                            <Form.Control type="text" name="name" required className="input-custom py-2" placeholder="ชื่อโซน" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className="font-14 weight-medium mb-1 mt-4">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>

                                            <div className="">
                                                <div className="row">
                                                    <div className="col-12"><button type="submit" className="btn btn-addmember text-white w-100">บันทึก</button></div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    : type === 'addTable' ?
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">เลขโต๊ะ*</Form.Label>
                                                <Form.Control type="text" name="name" required className="input-custom py-2" placeholder="เลขโต๊ะ" />
                                            </Form.Group>
                                            <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">จำนวนที่นั่ง*</Form.Label>
                                                <div className='text-start'>
                                                    <div className='position-relative d-inline-block weight-bold text-center py-4 ps-4 overflow-hidden' style={{ fontSize: '52px', background: '#F5F5F5', borderRadius: '10px', paddingRight: '73px' }}>
                                                        <span className="d-block" style={{ width: '70px' }}>{state.count}</span>
                                                        <input type="hidden" required defaultValue={state.count} name="qty" />

                                                        <div className='position-absolute h-50 cursor-pointer text-white box-lob px-3 weight-light' onClick={incrementCount} style={{ top: 0, right: 0 }}>
                                                            <img src={addPlus} className="mb-3" alt="" style={{ width: '22px' }} />
                                                        </div>
                                                        <div className='position-absolute h-50 cursor-pointer text-white box-lob px-3 weight-light' onClick={decrementCount} style={{ bottom: 0, right: 0 }}>
                                                            <img src={lopPlus} className="mb-3" alt="" style={{ width: '22px' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <div className="font-14 weight-medium mb-1 mt-4">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>

                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-12"><button className="btn btn-addmember text-white w-100">บันทึก</button></div>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Form>
                                        : type === 'openTable' ? viewQrcode.view ?
                                            <div className="">
                                                <div className="mb-3 text-center">
                                                    <div className='my-3 sub-topic weight-medium'>{dataTable.name}</div>
                                                    <img src={process.env.REACT_APP_API_LINK + '/' + viewQrcode.url} className="w-100 px-5" />
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <ReactToPrint
                                                            trigger={() => <button className="btn btn-blue text-white w-100">พิมพ์ QR Code</button>}
                                                            content={() => componentRef.current}
                                                            documenttTitle='name document'
                                                            onAfterPrint={() => printSuccess('yes')}
                                                        />
                                                        <div className='position-absolute d-none'>
                                                            <div className="ticket" ref={componentRef} >
                                                                <div className="" />
                                                                <ComponentToqrcode
                                                                    viewQrcode={viewQrcode.url}
                                                                    dataTable={dataTable}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="weight-medium mb-1">เวลา*</Form.Label>
                                                    {/* <Form.Select name="dateActive" className="input-custom py-2" aria-label="Default select example">
                                                        <option value="0">ทันที</option>
                                                        <option value="1">1 ชั่วโมง</option>
                                                        <option value="2">2 ชั่วโมง</option>
                                                        <option value="3">3 ชั่วโมง</option>
                                                    </Form.Select> */}
                                                    <div>
                                                        {/* <TimePicker onChange={onChangetime} value={valuetime} /> */}
                                                        {/* <input type="time" className="form-control timePicker" value="22:00" locale="th"/> */}
                                                        <TimePicker defaultValue={moment()} format={format} name="dateActive" className="input-custom py-2" />
                                                    </div>

                                                    <Form.Label className="weight-medium mb-1 mt-3">ชื่อผู้จอง</Form.Label>
                                                    <Form.Control type="text" name="reserveName" className="input-custom py-2" placeholder="ชื่อผู้จอง" />
                                                    <div className="font-14 weight-medium text-red mt-1">ระบบจะทำการจองโต๊ะเป็นเวลาที่คุณระบุ</div>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <div className="">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {/* <button className="btn btn-blue text-white w-100">พิมพ์ใบเปิดโต๊ะ</button> */}
                                                                <button className="btn btn-addmember text-white w-100">ยืนยัน</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </Form>
                                            : type === 'nowTable' ? viewQrcode.view ?
                                                <div className="">
                                                    <div className="mb-3 text-center">
                                                        <div className='my-3 sub-topic weight-medium'>{dataTable.name}</div>
                                                        <img src={process.env.REACT_APP_API_LINK + '/' + viewQrcode.url} className="w-100 px-5" />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <ReactToPrint
                                                                trigger={() => <button className="btn btn-blue text-white w-100">พิมพ์ QR Code</button>}
                                                                content={() => componentRef.current}
                                                                documenttTitle='name document'
                                                                onAfterPrint={() => printSuccess('yes')}
                                                            />
                                                            <div className='position-absolute d-none'>
                                                                <div className="ticket" ref={componentRef} >
                                                                    <div className="" />
                                                                    <ComponentToqrcode
                                                                        viewQrcode={viewQrcode.url}
                                                                        dataTable={dataTable}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                                        {/* <div>                                                          
                                                            <TimePicker defaultValue={moment()} format={format} name="dateActive" className="input-custom py-2" />
                                                        </div>

                                                        <Form.Label className="weight-medium mb-1 mt-3">ชื่อผู้จอง</Form.Label>
                                                        <Form.Control type="text" name="reserveName" required className="input-custom py-2" placeholder="ชื่อผู้จอง" /> */}
                                                        <div className="font-14 weight-medium mt-1">ระบบจะทำการเปิดโต๊ะโดยสถานะจะเปลี่ยนจากจองโต๊ะเป็นเปิดโต๊ะแล้ว</div>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <div className="">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {/* <button className="btn btn-blue text-white w-100">พิมพ์ใบเปิดโต๊ะ</button> */}
                                                                    <button className="btn btn-addmember text-white w-100">ยืนยัน</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                                : type === 'closeTable' ?
                                                    <Form>
                                                        <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                                            <Form.Label className="weight-medium mb-1">ช่วงเวลาปิดโต๊ะ*</Form.Label>
                                                            <Form.Select className="input-custom py-2" aria-label="Default select example">
                                                                <option value="1">1 ชั่วโมง</option>
                                                                <option value="2">2 ชั่วโมง</option>
                                                                <option value="3">3 ชั่วโมง</option>
                                                            </Form.Select>
                                                            <div className="font-14 weight-medium text-red mt-1">ระบบจะทำการปิดโต๊ะเป็นเวลาที่คุณระบุ</div>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <div className="">
                                                                <div className="row">
                                                                    <div className="col-12"><button className="btn btn-addmember text-white w-100">บันทึก</button></div>
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    </Form>
                                                    : type === 'qrTable' ?
                                                        <div className="">
                                                            <div className="mb-3 text-center">
                                                                <div className='my-3 sub-topic weight-medium'>{dataTable.name}</div>
                                                                <img src={process.env.REACT_APP_API_LINK + '/qrcode/order/' + dataId + '-qrcode.png'} className="w-100 px-5" />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <ReactToPrint
                                                                        trigger={() => <button className="btn btn-blue text-white w-100">พิมพ์ QR Code</button>}
                                                                        content={() => componentRef.current}
                                                                        documenttTitle='name document'
                                                                        onAfterPrint={() => printSuccess('yes')}
                                                                    />
                                                                    <div className='position-absolute d-none'>
                                                                        <div className="ticket" ref={componentRef} >
                                                                            <div className="" />
                                                                            <ComponentToqrcode
                                                                                viewQrcode={viewQrcode.url}
                                                                                dataTable={dataTable}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : type === 'qrcodeTable' ?
                                                            <div className="">
                                                                <div className="mb-3 text-center">
                                                                    <div className='my-3 sub-topic weight-medium'>{dataTable.name}</div>
                                                                    <img src={process.env.REACT_APP_API_LINK + '/' + dataId} className="w-100 px-5" />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <ReactToPrint
                                                                            trigger={() => <button className="btn btn-blue text-white w-100">พิมพ์ QR Code</button>}
                                                                            content={() => componentRef.current}
                                                                            documenttTitle='name document'
                                                                            onAfterPrint={() => printSuccess('yes')}
                                                                        />
                                                                        <div className='position-absolute d-none'>
                                                                            <div className="ticket" ref={componentRef} >
                                                                                <div className="" />
                                                                                <ComponentToqrcode
                                                                                    viewQrcode={dataId}
                                                                                    dataTable={dataTable}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                                <Form.Group className="mb-5" controlId="exampleForm.ControlInput1">
                                                                    <Form.Label className="weight-medium mb-1">ไปยัง*</Form.Label>
                                                                    <Form.Select name="tableIdTo" className="input-custom py-2" aria-label="Default select example" required>
                                                                        <option value="">เลือก</option>
                                                                        {dataTable.filter(a => a.status === '1').map((val, index) => (
                                                                            <option value={val.id} key={index}>{val.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <div className="font-14 weight-medium text-red mt-1">การย้ายโต๊ะจะดึงข้อมูลการเปิดโต๊ะทั้งหมดไปยังโต๊ะที่ย้าย</div>
                                                                </Form.Group>

                                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                    <div className="">
                                                                        <div className="row">
                                                                            <div className="col-12"><button className="btn btn-addmember text-white w-100">บันทึก</button></div>
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </Form>
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
