import React, { useState,useEffect,useRef } from "react";
import menu from "../assets/img/icon/Menu.svg";
import closemenu from "../assets/img/icon/CloseMenu.svg";

import { menuData } from "../configs/menu";


const Menu = (data) => {
    const called = useRef(true);
    const [isActive, setActive] = useState(false);
    const [menusubOpen, setMenusubOpen] = useState({ view: false, id: 0 });

    useEffect(() => {
        if (called.current) {
            if(data.page === 'entertain'){
                setMenusubOpen({ view: true, id: 2 });
            }else if(data.page === 'order'){
                setMenusubOpen({ view: true, id: 4 });
            }
        }
        return () => {
          called.current = false;
        };
      }, []);

    const handleMenusubChange = (state, id) => {
        if (menusubOpen.id === id) {
            setMenusubOpen({ view: !menusubOpen.view, id: id });
        } else {
            setMenusubOpen({ view: true, id: id });
        }
    };

    const toggleClass = () => {
        setActive(!isActive);
    };

    return (
        <>
            <div className="d-inline-block position-relative" id={isActive ? 'menu-active' : null} >
                <button onClick={toggleClass} className="btn btn-menu py-3 px-md-3 px-lg-3">
                    <img src={menu} className="" style={{ height: '30px' }} alt="" />
                </button>
                <div className="box-menu position-fixed">
                    <button onClick={toggleClass} className="btn btn-menu py-1 px-md-1">
                        <img src={closemenu} className="" style={{ height: '50px' }} alt="" />
                    </button>
                    <div className="mt-5">
                        <ul className="menu-m">

                            {menuData.filter(a => a.status === true).map((val, index) => (

                                val.submenu.length ?

                                    <li
                                        className={
                                            menusubOpen.id === val.id ? 
                                                menusubOpen.view ? "nav-item has-submenu menu-open" : "nav-item has-submenu"
                                                : "nav-item has-submenu"
                                        }
                                        key={index}
                                    >
                                        <a
                                            className="nav-link"
                                            href="#"
                                            onClick={(state) => handleMenusubChange(state, val.id)}
                                        >
                                            {val.name}
                                        </a>
                                        <ul className="submenu collapse">
                                            {val.submenu.filter(a => a.status === true).map((vall, indexx) => (

                                                <li key={indexx}>
                                                    <a
                                                        href={vall.link}
                                                        className={
                                                            data.subPage === vall.active
                                                                ? "btn-link-default active"
                                                                : "btn-link-default"
                                                        }
                                                        target={vall.target}
                                                    >
                                                        {vall.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    :
                                    <li key={index}>
                                        <a
                                            href={val.link}
                                            className={
                                                data.page === val.active
                                                    ? "btn-link-default un active"
                                                    : "btn-link-default un"
                                            }
                                            target={val.target}
                                        >
                                            {val.name}
                                        </a>
                                    </li>
                            ))}

                        </ul>
                    </div>
                    <div className="px-3 w-100 position-absolute" style={{ bottom: '15px' }}>
                        <a href="/administrator/home" className="btn btn-addmember sub-topic-small text-white w-100">
                            หน้าแรก
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Menu
