import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Loading from "../../layout/loading";
import ModalMenu from "../../layout/modal/menuModel";

import { getDataNone } from "../../services/getService";

import back from "../../assets/img/icon/Back.svg";

const Home = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [mainData, setMainData] = useState([]);
    const [tableData, setTableData] = useState({});
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchOrder(pr.id)]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchOrder = async (id) => {

        getDataNone('orderList', id).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    fetchTable(response.data.tableId)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }


    const fetchTable = async (id) => {

        getDataNone('table', id).then(
            response => {
                if (response.status === 'success') {
                    setTableData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="position-absolute" style={{ top: '10px', left: '10px' }}>
                                        <a href={"/qrmenu/" + pr.id} className="text-white btn-default">
                                            <img src={back} className="" alt="" /> ย้อนกลับ
                                        </a>
                                    </div>
                                    <div className="box-in-home-res bg-white text-center px-3" style={{ minHeight: 'calc(100vh - 3rem)' }}>
                                        <div className="topic weight-bold pt-4">โต๊ะ {tableData.name}</div>
                                        <div className="font-14 weight-light">{moment(mainData.dateActive).format('DD/MM/YYYY')}&nbsp; | &nbsp;Check in : {moment(mainData.dateActive).format('HH:mm')} น.</div>
                                        <div className="row justify-content-md-center text-start pb-3">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="sub-topic-small weight-bold pt-4 pb-3">รายการส่งของขวัญ</div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="box-list-30 p-2">
                                                    <div className="list-scroll">
                                                        <table className="w-100">
                                                            {mainData && mainData.gift && mainData.gift.length ? mainData.gift.map((val, key) => (
                                                                <tr style={{ verticalAlign: 'baseline' }} key={key}>
                                                                    <td style={{ width: '25px' }}>{key + 1}</td>
                                                                    <td>{val.name}</td>
                                                                    <td style={{ width: '32px' }}>X{val.qty}</td>
                                                                    <td style={{ width: '60px' }} className="text-pink weight-bold">{(val.price * val.qty).toLocaleString()}.-</td>
                                                                    <td className="font-12" style={{ width: '30px' }}>{moment(val.dateActive).format('HH:mm')}</td>
                                                                </tr>
                                                            )) : null}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="sub-topic-small weight-bold pt-4 pb-3">รายการ Tips</div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="box-list-30 p-2">
                                                    <div className="list-scroll">
                                                        <table className="w-100">
                                                            {mainData && mainData.tips && mainData.tips.length ? mainData.tips.map((val, key) => (
                                                                <tr style={{ verticalAlign: 'baseline' }} key={key}>
                                                                    <td style={{ width: '25px' }}>{key + 1}</td>
                                                                    <td>{val.name}</td>
                                                                    <td style={{ width: '32px' }}></td>
                                                                    <td style={{ width: '60px' }} className="text-pink weight-bold">{(val.price).toLocaleString()}.-</td>
                                                                    <td className="font-12" style={{ width: '30px' }}>{moment(val.dateActive).format('HH:mm')}</td>
                                                                </tr>
                                                            )) : null}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-12 col-lg-12">
                                                <div className="sub-topic-small weight-bold pt-4 pb-3">รายการโปรโมทขึ้นจอ</div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="box-list-30 p-2">
                                                    <div className="list-scroll">
                                                        <table className="w-100">
                                                            {mainData && mainData.promote && mainData.promote.length ? mainData.promote.map((val, key) => (
                                                                <tr style={{ verticalAlign: 'baseline' }} key={key}>
                                                                    <td style={{ width: '25px' }}>{key + 1}</td>
                                                                    <td>โปรโมทขึ้นจอ</td>
                                                                    <td style={{ width: '60px' }} className="text-pink weight-bold">{(val.price).toLocaleString()}.-</td>
                                                                    <td className="font-12" style={{ width: '30px' }}>{moment(val.dateActive).format('HH:mm')}</td>
                                                                </tr>
                                                            )) : null}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="px-3 box-order-list">

                                        <div className="position-relative" style={{ paddingRight: '65px' }}>
                                            <button className="btn btn-send-order w-100" onClick={() => setModalMenu({ view: true, id: 1, type: 'gift' })}>ส่งรายการ</button>
                                            <a href="/list/1" className="position-absolute" style={{ top: '-5px', right: '-15px' }}>
                                                <img src={orders} className="" alt="" />
                                            </a>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <ModalMenu show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                    // onRefresh={refreshPage}
                    />
                    : null
            }
        </>
    );
};

export default Home;
