import {useEffect, useRef } from 'react'
import { authInfo, getUser } from '../services/authenService'

const AuthMiddleware = ({children}) => {
    const called = useRef(true)
    useEffect(() => {
        if(called.current){
            init()
        }
        return () => {
            called.current = false
        }
    }, [])
    const init = async() => {
        const user = await authInfo()
        // if( !user)
        // console.log('get user auth middleware : ', user)
    }
    return children
}
export default AuthMiddleware