import { axiosHeader, fetchHeader, axiosJson } from "../configs/fetcher";

const api_link = process.env.REACT_APP_API_LINK+'/api/v1';

const accessToken = localStorage.getItem('accessToken')

const fetchRegister = async (request) => {
    // const request = fetchHeader('POST', JSON.stringify({
    //     email: inputUsername.replace(/\s/g, ""),
    //     password: inputPassword.replace(/\s/g, ""),
    //     remember: inputRemember.replace(/\s/g, "")
    // }))
    const response = await axiosHeader.post(`${api_link}/auth/register`,request)

    if (!response || response.status !== 200) {
        return {
            status: 'error1',
            message: '!! ไม่สามารถใช้งานได้ในขณะนี้ โปรดลองใหม่ภายหลัง',
        }
    }
    // setLogin(response.data)
    return {
        status: 'success',
        message: 'ลงทะเบียนสำเร็จ',
    }
}

const fetchRegisterupdate = async (id,request) => {

    const response = await axiosJson.put(`${api_link}/auth/usersupdate/${id}`,request)

    if (!response || response.status !== 200) {
        return {
            status: 'error1',
            message: '!! ไม่สามารถใช้งานได้ในขณะนี้ โปรดลองใหม่ภายหลัง',
        }
    }
    // setLogin(response.data)
    return {
        status: 'success',
        message: 'ลงทะเบียนสำเร็จ',
    }
}

const fetchLogin = async (request) => {
    // const request = fetchHeader('POST', JSON.stringify({
    //     email: inputUsername.replace(/\s/g, ""),
    //     password: inputPassword.replace(/\s/g, ""),
    //     remember: inputRemember.replace(/\s/g, "")
    // }))
    const response = await axiosJson.post(`${api_link}/auth/login`,request)

    if (!response || response.status !== 200) {
        return {
            status: 'error',
            message: '!! ไม่สามารถใช้งานได้ในขณะนี้ โปรดลองใหม่ภายหลัง',
        }
    }
    setLogin(response.data)
    return {
        status: 'success',
        message: 'เข้าสู่ระบบสำเร็จ',
    }
}

const setLogin = ({token}) => {
    localStorage.setItem('accessToken', token)
}

const fetchLogout = async () => {
    localStorage.removeItem('accessToken')
    localStorage.clear();
    console.log('fetch logout user ');
    window.location.href = "/"
}

const authInfo = async () => {
    authGuard()
    const url = `${api_link}/auth/userinfo`
    const response = await axiosHeader.post(url, {})
        .catch(async (error) => {
            console.log('error response ', error.response)
            if (error.response && error.response.status == 401) {
                alert('กรุณาลงชื่อเข้าใช้งานระบบ')
                await fetchLogout();
            }
        });
        // console.log(response.status)
    if (response.status == 401)
        await fetchLogout()
    if (response.status == 200) {
        const data = response.data;
        localStorage.setItem('authUser', JSON.stringify(setAuthen(data)))
        localStorage.setItem('auth', JSON.stringify(setAuthen(data)))
        return setAuthen(data)
        
        // console.log('authen user info ', data );       
    }
}

const getUser = async () => {
    authGuard()
    const url = `${api_link}/auth/userinfo`
    const response = await axiosHeader.post(url, {})
        .catch(function (error) {
            if (error.response) {
                if (error.response.status == 401) {
                    // alert('กรุณาลงชื่อเข้าใช้งานระบบ 2')
                    localStorage.clear()
                    localStorage.removeItem('accessToken')
                    window.location.href = "/"
                    return false;
                }
            }
        });

    if (!response) return false;
    if (response.status == 401)
        fetchLogout()

    const data = response.data
    // console.log('data : ', response)
    if (!data) return false;
    localStorage.setItem('authUser', JSON.stringify(setAuthen(data)))
    localStorage.setItem('auth', JSON.stringify(setAuthen(data)))
    return response;
}
const authGuard = async () => {
    if (!accessToken)
        window.location.href = "/"
}

const initAuth = {
    emp_name: '',
    emp_surname: '',
    username: '',
    password: '',
    // zoo_profile: {
    //     id: 0,
    //     code: 0,
    //     zoo_name_th: '',
    //     zoo_name_en: ''
    // }
}
const setAuthen = (data) => {
    //    console.log('set authen data : ', data)
    // const profile = data.zoo_profile;
    return {
        id: data.id,
        // emp_name: data.emp_name,
        // emp_surname: data.emp_surname,
        email: data.email,
        username: data.username,
        // access: data.access,
        remember: data.remember ? JSON.parse(data.remember) : null,
        // is_super_admin: data.level,
        // zoo_profile: {
        //     id: profile.id,
        //     code: profile.code,
        //     zoo_name_th: profile.name_th,
        //     zoo_name_en: profile.name_en
        // }

    }
}

const authUser = () => {
    const user = localStorage.getItem('auth')
    return user ? JSON.parse(user) : null
}

const getAuth = () => {
    const user = localStorage.getItem('auth')
    return user ? JSON.parse(user) : {}
}

export {
    initAuth,
    api_link,
    accessToken,
    fetchRegister,
    fetchRegisterupdate,
    fetchLogin,
    authGuard,
    getUser,
    getAuth,
    fetchLogout,
    setAuthen,
    authUser,
    authInfo
}
