import React, { useState, useEffect, useRef } from "react";
import { Tab, Nav } from "react-bootstrap";

import { getData } from "../../services/getService";

import ModalAdd from "../../layout/modal/tableModal";
import TabTable from "../../administrator/order/tabTable";
import Loading from "../../layout/loading";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import plus from "../../assets/img/icon/AddPlus.svg";


const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [modalAdd, setModalAdd] = useState({ view: false, id: 0, type: '' })
    const [stateTab, setStateTab] = useState(1);

    // const handleSelect = (key) => {
    //     alert(`selected ${key}`);
    //     setStateTab(key);
    // }

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async () => {

        getData('zone').then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const refreshData = () => {
        fetchData();
        setModalAdd({ view: false })
        // setModalDel({ view: false })
    };

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <Tab.Container id="tabs-with-dropdown" defaultActiveKey="0">
                        <section className="pt-3" id="page-wrap">
                            <div className="container-fluid ">
                                <div className="row align-items-center text-start">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                            <div className="" style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                                <div className="row justify-content-md-center">
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="p-3">
                                                            <Menu page={'order'} subPage={'manageTable'} />
                                                            <span className="align-middle sub-topic weight-medium ms-4">จัดการโต๊ะ</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="p-3 text-end">
                                                            <button onClick={(e) => setModalAdd({ view: true, id: 1, type: 'addZone' })} className="btn btn-addmenu sub-topic-small text-white py-2 px-5">
                                                                <img src={plus} className="me-1" style={{ height: '18px' }} alt="" /> เพิ่มโซน
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="pt-2 text-end">
                                                            <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden tab-custom">
                                                                <Nav.Item as="li">
                                                                    <Nav.Link eventKey="0" className=''>ทั้งหมด</Nav.Link>
                                                                </Nav.Item>
                                                                {mainData.length ? mainData.map((val, index) => (
                                                                    <Nav.Item as="li" key={index}>
                                                                        <Nav.Link eventKey={val.id} className=''>{val.name}</Nav.Link>
                                                                    </Nav.Item>
                                                                )) : null}
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-4 pt-3 px-4 box-scroll">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="0">
                                                                <TabTable idZone="all" dataTable={mainData} onRefresh={refreshData}/>
                                                            </Tab.Pane>
                                                            {mainData.length ? mainData.map((val, index) => (
                                                                <Tab.Pane eventKey={val.id} key={index}>
                                                                    <TabTable idZone={val.id} dataTable={val.table} onRefresh={refreshData}/>
                                                                </Tab.Pane>
                                                            )) : null}
                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Tab.Container>
                </div>
            </div >

            {
                modalAdd.view ?
                    <ModalAdd show={modalAdd.view}
                        dataId={modalAdd.id}
                        onHide={() => setModalAdd({ view: false })}
                        type={modalAdd.type}
                        onRefresh={refreshData}
                    />
                    : null
            }

        </>
    );
};

export default Main;
