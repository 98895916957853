import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import moment from 'moment';

import { getData } from "../../services/getService";
import Loading from "../../layout/loading";


const Footer = ({ idZone, dateData, onRefresh }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [twoData, setTwoData] = useState([]);

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {

            if (dateData.name !== '') {
                // console.log(mainData)
                const updatedList = mainData.filter((item) => {
                    return item.singerName.toLowerCase().indexOf(dateData.name.toLowerCase()) !== -1;
                });
                setTwoData(updatedList)
            } else {
                Promise.all([fetchData(dateData.name, dateData.startDate, dateData.endDate)]).then(res => {
                    setIsLoading(false);
                });
            }
        }
        // return () => {
        //     called.current = false;
        // };
    }, [dateData]);

    const fetchData = async (name, startDate, endDate) => {

        getData('reportTotal?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    setTwoData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const sumTotal = (e) => {
        if (e !== 0 && e.length) {
            let sum = e.reduce(function (prev, current) {
                return prev + +current.qty
            }, 0);
            return parseFloat(sum).toLocaleString()
        }
    }

    return (
        <>
            {idZone === 'total' ?
                <div className="row hr-custom">
                    {isLoading ? <Loading /> : null}
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="px-3 box-scroll-b">
                            <table className="table table-striped table-list table-list-b">
                                <thead>
                                    <tr>
                                        <th className="font-14 px-2" style={{ width: '30px'}}>ลำดับ</th>
                                        <th className="text-center font-14 px-2">รายการอาหาร</th>
                                        {twoData.length ? twoData[0].day.map((val, index) => ( <th key={index} className="px-2 font-14 text-center">{index+1}</th> )) : null }
                                        <th className="font-14 px-2 text-center">รวม</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {twoData.length ? twoData.map((val, index) => (
                                        <tr key={index}>
                                            <td className="text-center font-14">{index + 1}</td>
                                            <td className="font-14">{val.name}</td>
                                            {val.day.map((vall, indexx) => (
                                                <td key={indexx} className="font-14 text-center">{vall.qty ? vall.qty : '-'}</td>
                                            ))}
                                            <td className="text-center px-2">{sumTotal(val.day.length && val.day)}</td>
                                        </tr>
                                    )) : <tr>
                                        <td colSpan={6} className="text-center">-- No data --</td>
                                    </tr>}
                                </tbody>
                                {/* {twoData.length ?
                                    <tfoot>
                                        <tr>
                                            <th colSpan={'1'} className="text-end">
                                                รวม
                                            </th>
                                            <th className="text-end">
                                                {sumTotal(twoData.length && twoData)}
                                            </th>
                                        </tr>
                                    </tfoot>
                                    : null} */}
                            </table>
                        </div>
                    </div>
                </div>
                : <div>No data</div>
            }

        </>
    );
};

export default Footer
