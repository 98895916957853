import React, { useState, useEffect, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import moment from "moment";
import Footer from "../layout/footer";
import Loading from "../layout/loading";

import logo from "../assets/img/LOGO.svg";
import warning from "../assets/img/icon/Warning.svg";
import seen from "../assets/img/icon/Seen.svg";
import unseen from "../assets/img/icon/Unseen.svg";


import { fetchLogin } from '../services/authenService';



const Home = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [remember, setRemember] = useState({ user: '', pass: '' })
    const [result, setResult] = useState()
    const [validated, setValidated] = useState(false);

    let componentMounted = useRef(true);

    useEffect(() => {

        if (componentMounted.current) {
            if (localStorage.getItem('accessToken'))
                window.location.href = "/administrator/home";

            if (localStorage.getItem('remember')) {
                setRemember({ user: '', pass: '' })
                console.log(localStorage.getItem('remember'))
            }
        }

        return () => {
            componentMounted.current = false;
        }
    }, [])

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            setIsLoading(true);

            await fetchLogin(dataForm).then(
                response => {
                    if (response.status === 'success') {

                        if (dataForm.get('remember')) {
                            // let arr = {'user':dataForm.get('username'),'pass':dataForm.get('password')};
                            let arr = { 'user': dataForm.get('email'), 'pass': '' };
                            localStorage.setItem('remember', JSON.stringify(arr))
                        }

                        setTimeout(() => {
                            window.location.href = "/administrator/home";
                            // alert(5)
                        }, 100);
                    }
                }).catch(e => {
                    setIsLoading(false);
                    setResult(e.response.data.error)
                    console.log(e.response.data.error)

                })
        }
        setValidated(true);
    };

    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const refreshPage = () => {

        // setModaldel({ view: false })
        setIsLoading(true)
        window.location.reload()
    }


    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-center">
                                <div className="col-12 col-md-12 col-lg-12 py-5">
                                    <img src={logo} className="" style={{ height: '120px' }} alt="" />
                                </div>
                            </div>
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white text-center" style={{ height: 'calc(100vh - 16rem)' }}>
                                        <div className="row align-items-center text-start justify-content-center">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="sub-topic weight-bold pt-5 text-center mb-4">เข้าสู่ระบบ</div>
                                                <div className="px-4">
                                                    <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <Form.Label className="weight-medium mb-1">อีเมล*</Form.Label>
                                                            <Form.Control type="email" name="email" className="input-custom input-login" placeholder="Email" required/>
                                                        </Form.Group>
                                                        <Form.Group className="mb-1 position-relative" controlId="exampleForm.ControlInput1">
                                                            <Form.Label className="weight-medium mb-1">รหัสผ่าน*</Form.Label>
                                                            <Form.Control type={passwordShown ? "text" : "password"} name="password" className="input-custom input-login" placeholder="******"
                                                                required />
                                                            <span className="sh-pass cursor-pointer tetx-red" onClick={togglePassword}>
                                                                <img src={passwordShown ? seen : unseen} alt="" className="" style={{ width: "25px" }} />
                                                            </span>
                                                        </Form.Group>
                                                        <div className="a-default">{result}</div>
                                                        <div className="row pt-4">
                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                                                    <div>
                                                                        <div className="ex-checkbox4 d-inline-block align-middle me-4">
                                                                            <input type="checkbox" name="remember" value="1" />
                                                                            <label className="radio_one font-size-18">
                                                                                <span className='ps-2'>จดจำฉันในระบบ</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 text-end">
                                                                <a href="" className="a-default">ลืมรหัสผ่าน</a>
                                                            </div>
                                                        </div>

                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                            <div className="">
                                                                <button type="submit" className="btn btn-addmember text-white w-100">เข้าสู่ระบบ</button>
                                                            </div>
                                                            <div className="font-14 weight-medium mt-1"> <img src={warning} className="" style={{ height: '15px' }} alt="" /> กรุณากรอกข้อมูลให้ครบถ้วน</div>
                                                        </Form.Group>

                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        </>
    );
};

export default Home;
