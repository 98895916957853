import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Loading from "../../layout/loading";
import ModalMenu from "../../layout/modal/menuModel";

import { getDataNone } from "../../services/getService";

import tips from "../../assets/img/icon/tips.png";
import orders from "../../assets/img/icon/Order.svg";

const Home = () => {

    const pr = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [mainData, setMainData] = useState([]);
    const [tableData, setTableData] = useState({});
    const [productData, setProductData] = useState([]);
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })

    const [selectData, setSelectData] = useState([]);

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(pr.id), fetchProduct()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchData = async (id) => {

        getDataNone('order', id).then(
            response => {
                if (response.status === 'success') {
                    if (response.data) {
                        setMainData(response.data)
                        fetchTable(response.data.tableId)
                    } else {
                        setIsLoading(true);
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
                setIsLoading(true);
            })
    }
    const fetchTable = async (id) => {

        getDataNone('table', id).then(
            response => {
                if (response.status === 'success') {
                    setTableData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }
    const fetchProduct = async (id) => {

        getDataNone('product').then(
            response => {
                if (response.status === 'success') {
                    setProductData(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const refreshData = (productId, qty, message) => {
        if (productId) {
            changSelet(productId, qty, message)
        } else {
            setSelectData([])
        }
        setModalMenu({ view: false })
    };

    const changSelet = (productId, qty, message) => {
        if (selectData.filter((item) => item.productId === productId).length) {

            if (qty === '0') {
                const filteredArray = selectData.filter(item => item.productId !== productId)
                setSelectData(filteredArray);
                // console.log(qty)
            } else {
                const newTodos = selectData.map((todo) => {
                    if (todo.productId === productId) {
                        return { ...todo, qty: parseFloat(qty), message: message };
                    }
                    return todo;
                });
                setSelectData(newTodos);
            }
        } else {
            const arrayData = { productId: productId, qty: parseFloat(qty), message: message }
            setSelectData((oldArray) => [...oldArray, arrayData])
        }
    }

    const qtyView = (id) => {
        return selectData.length && selectData.filter((item) => item.productId === id).length ? <span className="qty-product">
            {selectData.filter((item) => item.productId === id)[0].qty}
        </span> : null

    }
    // console.log(selectData)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    {/* <div className="position-absolute" style={{ top: '12px', right: '10px' }}>
                                        <a href={"/promote/" + pr.id} className="text-white btn-default">
                                            Rich Menu
                                        </a>
                                    </div> */}
                                    <div className="box-in-home-res bg-white text-center px-3" style={{ minHeight: 'calc(100vh - 3rem)' }}>
                                        <div className="topic weight-bold pt-4">โต๊ะ {tableData.name}</div>
                                        <div className="font-14 weight-light">{moment(mainData.dateActive).format('DD/MM/YYYY')}&nbsp; | &nbsp;Check in : {moment(mainData.dateActive).format('HH:mm')} น.</div>
                                        <div className="row justify-content-md-center text-start pb-5 mb-5">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="topic-small weight-bold pt-4 pb-3">ส่งของขวัญ</div>
                                            </div>
                                            {productData.length ? productData.map((val, index) => (
                                                <div className="col-6 col-md-3 col-lg-2 pb-3" key={index}>
                                                    <div className="pb-2 box-product-m position-relative" onClick={() => setModalMenu({ view: true, id: pr.id, type: 'add', dataProduct: productData.filter((item) => item.id === val.id) })}>
                                                        <div className="box-product-m-img text-center mb-2 box-ra" style={{ backgroundImage: `url(${process.env.REACT_APP_API_LINK_FILE + '/product/' + val.id + '/' + val.img})` }}></div>
                                                        <div className="row justify-content-md-center">
                                                            <div className="col-7 col-md-8 col-lg-10">
                                                                <div className="weight-medium" style={{ height: '48px' }}>
                                                                    {val.name}
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-md-4 col-lg-2  text-end">
                                                                <div className="sub-topic-small weight-bold position-relative">
                                                                    {val.price}.-
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {qtyView(val.id)}

                                                    </div>
                                                </div>
                                            )) : <div className="col-12 col-md-12 col-lg-12 text-center p-5" >No data</div>}

                                            <div className="col-12 col-md-4 col-lg-3">
                                                <a href={'/qrtips/' + pr.id} className="cursor-pointer">
                                                    <img src={tips} className="w-100 button-tips" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-3 box-order-list">

                                        <div className="position-relative" style={{ paddingRight: '65px' }}>
                                            <button className="btn btn-send-order w-100" disabled={!selectData.length} onClick={() => setModalMenu({ view: true, id: pr.id, type: 'gift' })}>ส่งรายการ</button>
                                            <a href={"/qrlist/" + pr.id} className="position-absolute" style={{ top: '-5px', right: '-15px' }}>
                                                <img src={orders} className="" alt="" />
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <ModalMenu show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                        dataProduct={modalMenu.dataProduct}
                        selectData={selectData}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Home;
