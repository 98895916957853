import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";

import { createDataNone } from "../../services/getService";
import delIcon from "../../assets/img/icon/Del.svg";
import Loading from "../../layout/loading";

import product1 from "../../assets/img/product2.png";
import addPlus from "../../assets/img/icon/AddPlus.svg";
import lopPlus from "../../assets/img/icon/Lop.svg";

const Main = ({ dataId, type, onRefresh, tipsPrice, tipsType, tipsText, dataProduct, selectData, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({ count: selectData && selectData.length && dataProduct && selectData.filter((item) => item.productId === dataProduct[0].id).length && selectData.filter((item) => item.productId === dataProduct[0].id)[0].qty });

    const dataType = () => {

        if (type === 'gift') {
            return 'ส่งของขวัญ'
        } else if (type === 'tips') {
            return ' Tips '
        } else {
            return 'ลบบิล'
        }
    }

    const [validated, setValidated] = useState(false);
    const [formSinger, setFormSinger] = useState(false)
    const [errors, setErrors] = useState({ qty: false })

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);
        // setIsLoading(true)

        // dataForm.set('birth',dataForm.get('birth'));

        if (type === 'add') {
            if (dataForm.get('qty') > 0) {
                setErrors({ qty: false })
                onRefresh(dataForm.get('productId'), dataForm.get('qty'), dataForm.get('message'))
            } else {
                setErrors({ qty: true })
            }
        } else if (type === 'gift') {
            // console.log(dataId)
            dataForm.set('orderId', dataId);
            dataForm.set('productlist', JSON.stringify(selectData));

            await createDataNone('gift', dataForm).then(
                response => {
                    if (response.status === 'success') {
                        setTimeout(() => {
                            onRefresh()
                        }, 100);
                    }
                }).catch(e => {
                    setIsLoading(false)
                    setFormSinger(true)
                    console.log(e.response.data.messages.errors)
                })
        } else if (type === 'tips') {
            dataForm.set('orderId', dataId);
            dataForm.set('price', tipsPrice);
            dataForm.set('message', tipsText);
            // console.log(dataId,tipsPrice,tipsType,tipsText)
            await createDataNone('tips', dataForm).then(
                response => {
                    if (response.status === 'success') {
                        setTimeout(() => {
                            window.location.href = "/qrmenu/" + dataId;
                        }, 100);
                    }
                }).catch(e => {
                    setIsLoading(false)
                    setFormSinger(true)
                    console.log(e.response.data.messages.errors)
                })
        } else {
            onRefresh(dataForm.get('detail'))
        }
    }

    const incrementCount = () => {
        setState({
            count: state.count + 1
        });

        setErrors({ qty: false })
    }
    const decrementCount = () => {
        if (state.count > 0) {
            setState({
                count: state.count - 1
            });
        }

        if (state.count === 0) {
            setErrors({ qty: true })
        }
    }

    // console.log(selectData.length && selectData.filter((item) => item.productId === dataProduct[0].id).length && selectData.filter((item) => item.productId === dataProduct[0].id)[0].qty)
console.log(formSinger)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    {type === 'add' ?
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="row text-start">
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className="">
                                        <img src={`${process.env.REACT_APP_API_LINK_FILE + '/product/' + dataProduct[0].id + '/' + dataProduct[0].img}`} alt={''} className="w-100 box-ra" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6">
                                    <div className='text-start'>
                                        <div className='position-relative d-inline-block weight-bold text-center py-4 ps-4 overflow-hidden' style={{ fontSize: '52px', background: '#F5F5F5', borderRadius: '10px', paddingRight: '73px' }}>
                                            <span className="d-block" style={{ width: '70px' }}>{state.count}</span>
                                            <input type="hidden" required defaultValue={state.count} name="qty" />
                                            <input type="hidden" required defaultValue={dataProduct[0].id} name="productId" />

                                            <div className='position-absolute h-50 cursor-pointer text-white box-lob px-3 weight-light' onClick={incrementCount} style={{ top: 0, right: 0 }}>
                                                <img src={addPlus} className="mb-3" alt="" style={{ width: '22px' }} />
                                            </div>
                                            <div className='position-absolute h-50 cursor-pointer text-white box-lob px-3 weight-light' onClick={decrementCount} style={{ bottom: 0, right: 0 }}>
                                                <img src={lopPlus} className="mb-3" alt="" style={{ width: '22px' }} />
                                            </div>
                                        </div>
                                        {errors.qty ? <div className="text-red">Please specify the quantity</div> : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="topic weight-bold pt-4 pb-0">ขอขอบคุณ</div>
                                    <div className="weight-medium font-20">
                                        ที่สนับสนุนศิลปินของเรา อยากบอก
                                        ศิลปินว่า….
                                    </div>
                                    <div className="my-3">
                                        <textarea className="form-control input-custom" rows="3" name="message" placeholder="พิมพ์รายละเอียด..." defaultValue={selectData.length && selectData.filter((item) => item.productId === dataProduct[0].id).length ? selectData.filter((item) => item.productId === dataProduct[0].id)[0].message : ''}></textarea>
                                    </div>
                                    <div className="row mt-5 mb-3">
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="">
                                                <button className="btn btn-cancle w-100" onClick={data.onHide}>ยกเลิก</button>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6">
                                            <div className="">
                                                <button type="submit" className="btn btn-addmember text-white w-100">บันทึก</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        : type === 'tips' || type === 'gift' ?
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row text-start">
                                    {formSinger ?
                                        <div className="col-12 col-md-12 col-lg-12 text-center">
                                            <div className="topic-small weight-bold pt-4 pb-0">{dataType()}ไม่สำเร็จ</div>
                                            <div className="weight-medium font-18 mt-2">
                                                ไม่สามารถ{dataType()}
                                                ได้เนื่องจากช่วงเวลาดังกล่าวไม่มีข้อมูลนักร้อง
                                            </div>
                                            <div className="row mt-5 mb-3 justify-content-center">
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="">
                                                        <button className="btn btn-cancle w-100" onClick={data.onHide}>ยกเลิก</button>
                                                    </div>
                                                </div>                                               
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12 col-md-12 col-lg-12 text-center">
                                            <div className="topic-small weight-bold pt-4 pb-0">ยืนยัน{dataType()}</div>
                                            <div className="weight-medium font-18 mt-2">
                                                การกดยืนยันจะไม่สามารถ
                                                กลับมาแก้ไขรายการ{dataType()}
                                                ได้อีก
                                            </div>
                                            <div className="row mt-5 mb-3">
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="">
                                                        <button className="btn btn-cancle w-100" onClick={data.onHide}>ยกเลิก</button>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <div className="">
                                                        <button className="btn btn-addmember text-white w-100">ยืนยัน</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Form>
                            :
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="row text-start">
                                    <div className="col-6 col-md-6 col-lg-6">
                                        <div className={`tips-price cursor-pointer active`}>
                                            {tipsPrice.toLocaleString()}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="topic weight-bold pt-4 pb-0">ขอขอบคุณ</div>
                                        <div className="weight-medium font-20">
                                            ที่สนับสนุนศิลปินของเรา อยากบอก
                                            ศิลปินว่า….
                                        </div>
                                        <div className="my-3">
                                            <textarea className="form-control input-custom" name="detail" rows="3" placeholder="พิมพ์รายละเอียด..."></textarea>
                                        </div>

                                        <div className="row mt-5 mb-3">
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="">
                                                    <button className="btn btn-cancle w-100" onClick={data.onHide}>ยกเลิก</button>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="">
                                                    <button type="submit" className="btn btn-addmember text-white w-100">บันทึก</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
