import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { delData } from "../../services/getService";
import delIcon from "../../assets/img/icon/Del.svg";
import Loading from "../../layout/loading";

const Main = ({ dataId, type, onRefresh, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);

    const dataType = () => {

        if (type === 'member') {
            return 'ลบชื่อลูกค้า'
        } else if (type === 'singer') {
            return 'ลบข้อมูลนักร้อง'
        } else if (type === 'table') {
            return 'ลบข้อมูลโต๊ะ'
        } else if (type === 'product') {
            return 'ลบข้อมูลเมนู'
        } else if (type === 'gift') {
            return 'ลบข้อมูลของขวัญ'
        } else if (type === 'tips') {
            return 'ลบข้อมูล Tips'
        } else if (type === 'promote') {
            return 'ลบข้อมูลโปรโมทขึ้นจอ'
        } else {
            return 'ลบบิล'
        }
    }

    const handleSubmit = async (id) => {

        setIsLoading(true)

        await delData(type, id).then(
            response => {
                if (response.status === 'success') {
                    setTimeout(() => {
                        onRefresh()
                    }, 100);
                }
            }).catch(e => {
                setIsLoading(false)
                console.log(e.response.data.messages.errors)
            })
    }

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            <div className="text-center mt-5">
                                <img src={delIcon} className="" style={{ height: '60px' }} alt="" />
                            </div>
                            <div className="sub-topic weight-bold text-center mt-4 mb-4">
                                {dataType()}
                            </div>
                            <div className="px-3 text-center">
                                ยืนยันการ{dataType()} เมื่อทำรายการคุณจะไม่สามารถ <br />
                                กู้คืนข้อมูลได้อีก
                            </div>

                            <div className="mt-5 mb-4">
                                <button className="btn btn-del text-white w-100" onClick={(e) => handleSubmit(dataId)}>ยืนยัน</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
