import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import logo from './logo.svg';
import AuthMiddleware from "./components/authMiddleware";

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/button.scss";
import "./assets/scss/respornsive.scss";

import Login from "./administrator/login";
import Home from "./administrator/home";
import UsersSystem from "./administrator/user/index";
import UsersSystemDetail from "./administrator/user/detail";

import SettingMenu from "./administrator/entertain/settingMenu";
import Entertain from "./administrator/entertain/entertain";
import Singer from "./administrator/entertain/singer";
import Report from "./administrator/entertain/report";

import ManageTable from "./administrator/order/manageTable";
import ManageOrder from "./administrator/order/manageOrder";
import ReceiptHistory from "./administrator/order/receiptHistory";

import PromoteAdmin from "./administrator/promote/index";
import PromoteAdminDownload from "./administrator/promote/download";

import Qrmenu from "./pages/qrcode/index";
import Qrlist from "./pages/qrcode/list";
import Qrtips from "./pages/qrcode/tips";

import Promote from "./pages/promote/index";
import PromoteEdit from "./pages/promote/edit";
import PromoteAddText from "./pages/promote/addText";

import EntertainList from "./pages/entertain/index";

// import ThermalPrinter from "./ThermalPrinter";

import { RecoilRoot } from 'recoil'

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <ThermalPrinter />
      </header> */}
      <BrowserRouter basename="">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/administrator/home" element={<AuthMiddleware><Home /></AuthMiddleware>} />
          <Route path="/administrator/members" element={<AuthMiddleware><UsersSystem /></AuthMiddleware>} />
          <Route path="/administrator/members/:id" element={<AuthMiddleware><UsersSystemDetail /></AuthMiddleware>} />

          <Route path="/administrator/settingMenu" element={<AuthMiddleware><SettingMenu /></AuthMiddleware>} />
          <Route path="/administrator/entertain" element={<AuthMiddleware><Entertain /></AuthMiddleware>} />
          <Route path="/administrator/singer" element={<AuthMiddleware><Singer /></AuthMiddleware>} />
          <Route path="/administrator/report" element={<AuthMiddleware><Report /></AuthMiddleware>} />

          <Route path="/administrator/manageTable" element={<AuthMiddleware><ManageTable /></AuthMiddleware>} />
          <Route path="/administrator/manageOrder" element={<AuthMiddleware><ManageOrder /></AuthMiddleware>} />
          <Route path="/administrator/receiptHistory" element={<AuthMiddleware><ReceiptHistory /></AuthMiddleware>} />

          <Route path="/administrator/promote" element={<AuthMiddleware><PromoteAdmin /></AuthMiddleware>} />
          <Route path="/administrator/promoteDownload" element={<AuthMiddleware><PromoteAdminDownload /></AuthMiddleware>} />

          <Route path="/qrmenu/:id" element={<Qrmenu />} />
          <Route path="/qrlist/:id" element={<Qrlist />} />
          <Route path="/qrtips/:id" element={<Qrtips />} />


          <Route path="/promote" element={<RecoilRoot><Promote /></RecoilRoot>} />
          <Route path="/promote/edit" element={<RecoilRoot><PromoteEdit /></RecoilRoot>} />
          <Route path="/promote/addText" element={<RecoilRoot><PromoteAddText /></RecoilRoot>} />
          <Route path="/entertain" element={<EntertainList />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
