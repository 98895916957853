import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Loading from "../../layout/loading";
import { getData, putData, exportData } from "../../services/getService";

import ModalAddMember from "../../layout/modal/memberModal";
import ModalDelMember from "../../layout/modal/delData";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import pdf from "../../assets/img/icon/Pdf.svg";
import xls from "../../assets/img/icon/Xls.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";

import ComponentPrint from '../../components/PrintGift';
import ReactToPrint from 'react-to-print';

const Main = () => {
    const componentRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [modalAddMember, setModalAddMember] = useState({ view: false, id: 0, type: '' })
    const [modalDelMember, setModalDelMember] = useState({ view: false, id: 0, type: '' })

    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [twoData, setTwoData] = useState([]);
    const [searchData, setSearchData] = useState({ button: 'day', dateStart: '', dateEnd: '' });

    const [mainData, setMainData] = useState([]);

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({ button: 'date', dateStart: picker.startDate.format('YYYY-MM-DD'), dateEnd: picker.endDate.format('YYYY-MM-DD') })
        filteredData('date', picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'), 'get', '')
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({ button: 'day', dateStart: '', dateEnd: '' })
    };

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchData = async (startDate, endDate) => {

        getData('gift?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    if (response.data) {
                        setMainData(response.data)
                        setTwoData(response.data)
                        // filteredData(response.data, searchData.button,searchData.dateStart,searchData.dateEnd)
                    } else {
                        setIsLoading(true);
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
                setIsLoading(true);
            })
    }

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))
        // filteredData(mainData, e)
        filteredData(e, '', '', 'get', '')
    }

    const filteredData = (e, st, en, type, cat) => {

        let startDate
        let endDate
        if (e === 'day') {
            startDate = moment().format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        } else {
            startDate = st;
            endDate = en;
        }

        if (type === 'get') {
            fetchData(startDate, endDate);
        } else if (type === 'export') {
            fetchExport(startDate, endDate, cat)
        }
    };

    const handleExport = async (event) => {
        filteredData(searchData.button, searchData.dateStart, searchData.dateEnd, 'export', event)
    }

    const fetchExport = async (startDate, endDate, cat) => {

        let pi
        if (cat === 'xls') {
            pi = 'gift'
        } else {
            pi = 'giftPDF'
        }

        exportData(pi + '?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    // console.log(response.data)
                    window.open(response.data.redirect, '_blank')
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }


    const handleStatus = (e) => {

        putData('gift', e).then(
            response => {
                if (response.status === 'success') {
                    fetchData(moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD"))
                }
            }).catch(e => {
                setIsLoading(false)
                console.log(e.response.data.messages.errors)
            })
    }

    const printSuccess = (e) => {
        // refreshData()
        handleStatus(e)
    }

    // console.log(searchData)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <Menu page={'entertain'} subPage={'entertain'} />
                                                    <span className="align-middle sub-topic weight-medium ms-4">รายการ Entertain</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3 text-end">
                                                    <button onClick={(e) => handleExport('pdf')} className="btn px-0">
                                                        <img src={pdf} className="" style={{ height: '45px' }} alt="" />
                                                    </button>
                                                    <button onClick={(e) => handleExport('xls')} className="btn">
                                                        <img src={xls} className="" style={{ height: '45px' }} alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="row px-4 justify-content-md-end">
                                                    <div className="col-auto p-0">
                                                        <button onClick={() => handleSearch('day')} className={`btn btn-border-black me-1 ${searchData.button === 'day' && 'active'}`}>
                                                            วันนี้
                                                        </button>
                                                    </div>
                                                    <div className="col-auto ps-0 pe-1">
                                                        <div className="input-group">

                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                // onEvent={handleEvent} onCallback={handleCallback}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}
                                                            >
                                                                <input type="text" value={searchData.dateStart ? moment(searchData.dateStart).format("DD/MM/YYYY") + ' - ' + moment(searchData.dateEnd).format("DD/MM/YYYY") : ''} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 box-scroll">
                                                    <table className="table table-striped table-list">
                                                        <thead>
                                                            <tr className="table-detail">
                                                                <th>ลำดับที่</th>
                                                                <th>วันที่</th>
                                                                <th>เวลา</th>
                                                                <th>รายการ</th>
                                                                <th>โต๊ะ</th>
                                                                <th>ข้อความ</th>
                                                                <th>สถานะ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {twoData.length ? twoData.map((val, index) => (val.table ?
                                                                <tr className="table-detail" key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{moment(val.dateActive).format("DD/MM/YYYY")}</td>
                                                                    <td>{moment(val.dateActive).format("HH:mm")}</td>
                                                                    <td>{val.product.name}X{val.qty}</td>
                                                                    <td>{val.table ? val.table.name : null}</td>
                                                                    <td>{val.message}</td>
                                                                    <td>
                                                                        <ReactToPrint
                                                                            trigger={() => <button className={`${val.status === '2' ? 'active' : ''} btn btn-order`}>รับออเดอร์</button>}
                                                                            content={() => componentRef.current}
                                                                            documenttTitle='name document'
                                                                            onAfterPrint={() => printSuccess(val.id)}
                                                                        />
                                                                        <div className='position-absolute d-none'>
                                                                            <div className="ticket" ref={componentRef} >
                                                                                <div className="" />
                                                                                <ComponentPrint
                                                                                    dataId={1}
                                                                                    dataTable={twoData[index]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* <button onClick={(e) => handleStatus(val.id)} className={`${val.status === '2' ? 'active' : ''} btn btn-order`}>รับออเดอร์</button> */}
                                                                    </td>
                                                                </tr> : null
                                                            )) : <tr>
                                                                <td colSpan={7} className="text-center">No data</td>
                                                            </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalAddMember.view ?
                    <ModalAddMember show={modalAddMember.view}
                        userId={modalAddMember.id}
                        onHide={() => setModalAddMember({ view: false })}
                        type={modalAddMember.type}
                    // onRefresh={refreshPage}
                    />
                    : null
            }{
                modalDelMember.view ?
                    <ModalDelMember show={modalDelMember.view}
                        userId={modalDelMember.id}
                        onHide={() => setModalDelMember({ view: false })}
                        type={modalDelMember.type}
                    // onRefresh={refreshPage}
                    />
                    : null
            }
        </>
    );
};

export default Main;
