import React, { useState, useRef, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Loading from "../../layout/loading";
import { getData, createData, putData, getDataSmard } from "../../services/getService";

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import carlendar from "../../assets/img/icon/Carlendar.svg";
import carlendarPing from "../../assets/img/icon/CarlendarPing.svg";
import addMember from "../../assets/img/icon/AddMember.svg";
import photo from "../../assets/img/icon/user.png";

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const Main = ({ dataId, type, onRefresh, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [inputData, setInputData] = useState({ name: '', tel: '', sex: '', birth: '', email: '' });
    const [result, setResult] = useState({ name: '', email: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            // cancelLabel: 'Clear',
        },
        singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true,
        startDate: moment(inputData.birth).format("DD/MM/YYYY"),
    };
    const dateoptionrangB = {
        locale: {
            format: 'DD/MM/YYYY',
        },
        singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: true,
        timePicker24Hour: true,
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        // console.log(5)
        setInputData((prev) => ({ ...prev, birth: moment(picker.startDate.format('DD/MM/YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD') }));
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            if (type === 'edit' || type === 'detail') {
                Promise.all([fetchData()]).then(res => {
                    setIsLoading(false);
                });
            }
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async () => {

        getData('member', dataId).then(
            response => {
                if (response.status === 'success') {
                    setInputData(response.data)
                    // console.log(response.data)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const newErrors = findFormErrors()

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setIsLoading(true);
            if (type === 'add') {
                dataForm.set('birth', moment(dataForm.get('birth'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
                await createData('member', dataForm).then(
                    response => {
                        if (response.status === 'success') {

                            Swal.fire({
                                position: "center-center",
                                icon: "success",
                                // title: "เพิ่มสมาชิกเรียบร้อย",
                                html: response.data.type === 'add' ? "<div className='mb-5'>เพิ่มสมาชิกเรียบร้อย</div>" : "<div className='mb-5'>เป็นสมาชิกอยู่แล้ว</div>",
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                setIsLoading(false)

                                setTimeout(() => {
                                    onRefresh()
                                }, 100);
                            });
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        if (e.response.status === 409 && e.response.data.messages.errors) {
                            if (e.response.data.messages.errors.name) {
                                setResult((prev) => ({ ...prev, name: e.response.data.messages.errors.name }))
                            }

                            if (e.response.data.messages.errors.email) {
                                setResult((prev) => ({ ...prev, email: e.response.data.messages.errors.email }))
                            }
                        }
                    })
            } else {
                dataForm.set('id', dataId);
                dataForm.set('birth', moment(dataForm.get('birth'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

                await putData('member', dataId, dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            Swal.fire({
                                position: "center-center",
                                icon: "success",
                                // title: "เพิ่มสมาชิกเรียบร้อย",
                                html: "<div className='mb-5'>แก้ไขสมาชิกเรียบร้อย</div>",
                                showConfirmButton: false,
                                timer: 1500
                            }).then((result) => {
                                setTimeout(() => {
                                    onRefresh()
                                }, 100);
                            });
                           
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        console.log(e.response.data.messages.errors)
                    })
            }

        }
        setValidated(true);
    };

    const handleSex = (val) => {
        setInputData((prev) => ({ ...prev, sex: val }));
    }

    const handleSubmitAmount = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const newErrors = findFormErrors()

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setIsLoading(true);

            dataForm.set('memId', dataId);
            dataForm.set('dateActive', moment(dataForm.get('dateActive'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
            await createData('amount', dataForm).then(
                response => {
                    if (response.status === 'success') {
                        setTimeout(() => {
                            onRefresh()
                        }, 100);
                    }
                }).catch(e => {
                    setIsLoading(false)
                    // setResult(e.response.status === 409 ? 'The username field must contain a unique value.' : null)
                    console.log(e.response.data.messages.errors)
                })


        }
        setValidated(true);
    };

    const handleSmartcard = (e) => {

        getDataSmard('https://localhost:8182/thaiid/read.jsonp?&section1=true&section2a=true&section2b=true').then(
            response => {
                // console.log(response.data)

                setInputData({
                    name: response.data.FirstNameTh + ' ' + response.data.LastNameTh,
                    tel: '',
                    sex: response.data.Gender === '1' ? 'men' : 'women',
                    birth: moment(response.data.BirthDate, "YYYYMMDD").subtract(543, 'year').format("YYYY-MM-DD"),
                    email: '',
                    // photo: response.data.Photo
                })
            }).catch(e => {
                console.log(e.response)
                alert("ไม่สามารถดึงข้อมูลได้")
            })
    }

    const sexText = (e) => {
        return e === 'women' ? 'หญิง' : e === 'men' ? 'ชาย' : 'LGBTQ'
    }

    // console.log(inputData.birth)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4">
                            <div className="sub-topic weight-bold text-center mb-4">
                                {type === 'add' ? 'เพิ่มชื่อลูกค้า' : type === 'edit' ? 'แก้ไขชื่อลูกค้า' : type === 'detail' ? 'ข้อมูลลูกค้า' : 'เพิ่มยอดสะสม'}
                            </div>
                            <div className="px-3">
                                {type === 'add' && <>
                                    <div className="text-center pb-3"><img src={photo} className="" style={{ width: '120px' }} alt="" /></div>
                                    <div className="text-end">
                                        <button className="btn px-0" onClick={(e) => handleSmartcard()}><img src={addMember} className="" style={{ height: '16px', filter: 'brightness(0%)' }} alt="" /> ดึงข้อมูลจากบัตร ประชาชน</button>
                                    </div>
                                </>
                                }
                                {type === 'add' || type === 'edit' ?
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">ชื่อ-นามสกุล*</Form.Label>
                                            <Form.Control type="text" name="name" defaultValue={inputData.name} className="input-custom py-2" placeholder="ชื่อ-นามสกุล" required />
                                            <div className="a-default">{result.name}</div>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">เบอร์โทร</Form.Label>
                                            <Form.Control type="text" name="tel" defaultValue={inputData.tel} className="input-custom py-2" minLength={9} maxLength={10} placeholder="เบอร์โทร" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">เพศ</Form.Label>
                                            <div>
                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                    <input type="radio" name="sex" value="women" required onChange={(e) => handleSex(e.target.value)} checked={inputData.sex === 'women' && true} />
                                                    <label className="radio_one font-size-18">
                                                        <span className='ps-2'>หญิง</span>
                                                    </label>
                                                </div>
                                                <div className="ex-radio4 d-inline-block align-middle me-4">
                                                    <input type="radio" name="sex" value="men" required onChange={(e) => handleSex(e.target.value)} checked={inputData.sex === 'men' && true} />
                                                    <label className="radio_one font-size-18">
                                                        <span className='ps-2'>ชาย</span>
                                                    </label>
                                                </div>
                                                <div className="ex-radio4 d-inline-block align-middle">
                                                    <input type="radio" name="sex" value="lgbtq" required onChange={(e) => handleSex(e.target.value)} checked={inputData.sex === 'lgbtq' && true} />
                                                    <label className="radio_one font-size-18">
                                                        <span className='ps-2'>LGBTQ</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input type="radio" className="form-check-input d-none" name="sex" value="0" required />
                                                    <div className="invalid-feedback">
                                                        You must Gender before submitting.
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        <div className="row text-start">
                                            <div className="col-12 col-md-8 col-lg-8">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="weight-medium mb-1">วันเกิด</Form.Label>
                                                    {type === 'edit' && inputData.birth ?
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}
                                                            >
                                                                <input type="text" defaultValue={moment(inputData.birth).format("DD/MM/YYYY")} name="birth" required placeholder="วว/ดด/ปปปป" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendarPing} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                            <div className="invalid-feedback">
                                                                Please select date of birth
                                                            </div>
                                                        </div>
                                                        : inputData.birth ?
                                                            <div className="input-group">
                                                                <DateRangePicker
                                                                    initialSettings={dateoptionrang}
                                                                    onApply={handleApply}
                                                                    onCancel={handleCancel}
                                                                >
                                                                    <input type="text" defaultValue={moment(inputData.birth).format("DD/MM/YYYY")} name="birth" required placeholder="วว/ดด/ปปปป" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                                </DateRangePicker>
                                                                <span className="input-group-append">
                                                                    <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                        <img src={carlendarPing} className="" style={{ height: '20px' }} alt="" />
                                                                    </span>
                                                                </span>
                                                                <div className="invalid-feedback">
                                                                    Please select date of birth
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="input-group">
                                                                <DateRangePicker
                                                                    initialSettings={dateoptionrangB}
                                                                    onApply={handleApply}
                                                                    onCancel={handleCancel}
                                                                >
                                                                    <input type="text" defaultValue="" required name="birth" placeholder="วว/ดด/ปปปป" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                                </DateRangePicker>
                                                                <span className="input-group-append">
                                                                    <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                        <img src={carlendarPing} className="" style={{ height: '20px' }} alt="" />
                                                                    </span>
                                                                </span>
                                                                <div className="invalid-feedback">
                                                                    Please select date of birth
                                                                </div>
                                                            </div>
                                                    }
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label className="weight-medium mb-1">อายุ</Form.Label>
                                                    <div>{inputData.birth ? moment().diff(inputData.birth, 'years', false) : '-'} ปี</div>
                                                </Form.Group>
                                            </div>

                                        </div>
                                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className="weight-medium mb-1">Email*</Form.Label>
                                            <Form.Control type="email" name="email" defaultValue={inputData.email} className="input-custom py-2" placeholder="Email" required />
                                            <div className="a-default">{result.email}</div>
                                        </Form.Group> */}
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <div className="font-14 weight-medium mb-1 mt-0">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>
                                            <div className="">
                                                <button className="btn btn-addmember text-white w-100">บันทึก</button>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    : type === 'detail' ?
                                        <div>
                                            <div className="text-center pb-3"><img src={photo} className="" style={{ width: '120px' }} alt="" /></div>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">ชื่อ-นามสกุล*</Form.Label>

                                                <div>{inputData.name}</div>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">เบอร์โทร</Form.Label>
                                                <div>{inputData.tel}</div>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">เพศ</Form.Label>
                                                <div>{sexText(inputData.sex)}</div>
                                            </Form.Group>
                                            <div className="row text-start">
                                                <div className="col-12 col-md-8 col-lg-8">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className="weight-medium mb-1">วันเกิด</Form.Label>
                                                        {type === 'detail' && inputData.birth ?
                                                            <div className="input-group">
                                                                {moment(inputData.birth).format("DD/MM/YYYY")}
                                                            </div>
                                                            : inputData.birth ?
                                                                <div className="input-group">
                                                                    {moment(inputData.birth).format("DD/MM/YYYY")}
                                                                </div>
                                                                :
                                                                <div className="input-group">
                                                                    {moment(inputData.birth).format("DD/MM/YYYY")}
                                                                </div>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                        <Form.Label className="weight-medium mb-1">อายุ</Form.Label>
                                                        <div>{inputData.birth ? moment().diff(inputData.birth, 'years', false) : '-'} ปี</div>
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <Form noValidate validated={validated} onSubmit={handleSubmitAmount}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">วันที่*</Form.Label>
                                                <div className="input-group">
                                                    <DateRangePicker
                                                        initialSettings={dateoptionrangB}
                                                        onApply={handleApply}
                                                        onCancel={handleCancel}
                                                    >
                                                        <input type="text" name="dateActive" required defaultValue="" placeholder="วว/ดด/ปปปป" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                    </DateRangePicker>
                                                    <span className="input-group-append">
                                                        <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                            <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                        </span>
                                                    </span>
                                                    <div className="invalid-feedback">
                                                        Please select date
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="weight-medium mb-1">ยอดการใช้บริการ</Form.Label>
                                                <Form.Control type="number" name="amount" className="input-custom py-2" placeholder="ยอดการใช้บริการ" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-5" controlId="exampleForm.ControlInput1">
                                                <div className="font-14 weight-medium mb-1">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>
                                                <div className="">
                                                    <button className="btn btn-submit text-white w-100">บันทึก</button>
                                                </div>
                                            </Form.Group>
                                        </Form>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
