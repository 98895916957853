import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import moment from "moment";

import logo from "../assets/img/icon/logo-black.svg";
// import '../assets/css/print.css';

// import PostService from "./services_member/post.service";
// import AuthService from "./services_member/auth.service";

// const getToken = () => {
//     const tokenString = localStorage.getItem("login_member");
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const Main = ({ dataId, dataTable, ...data }) => {

    return (
        <>
            {/* {data.select.map((value2, index2) => ( */}
            <div className="ticket mb-5" style={{ paddingBottom: '20px'}}>
                <div className='mt-3 font-12 text-start'>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            <tr>
                                <th className="quantity"><img src={logo} className="" style={{ height: '35px' }} alt="" /></th>
                                <th className="text-end">
                                    <div className="font-18 weight-medium">{dataTable.tableId && dataTable.tableId.name}</div>
                                    <div className="text-gray font-10 weight-light" style={{ marginTop: '-4px' }}>{moment(dataTable.dateActive).format('DD/MM/YYYY HH:mm')}</div>
                                    <div className="font-10 mt-2 mb-3 weight-light">โต๊ะ {dataTable.table ? dataTable.table.name : null}</div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div className="font-14 weight-medium">รายการ Entertain</div>
                    <table className='w-100'>
                        <thead style={{ verticalAlign: 'top' }}>
                            <tr>
                                <th className="quantity">
                                    <div className="font-12 weight-light">{dataTable.product.name}</div>
                                </th>
                                <th className="quantity">
                                    X{dataTable.qty}
                                </th>
                            </tr>
                            {/* <tr>
                                <th className="quantity">
                                    <div className="font-12 weight-light">{dataTable.table ? dataTable.table.name : null}
                                                                   {dataTable.message}</div>
                                </th>
                            </tr> */}
                        </thead>
                    </table>
                </div>
            </div>
            {/* ))} */}
        </>
    );
};

export default Main;