import React,{useState,useEffect,useRef} from "react";
import { Modal } from "react-bootstrap";


import { menuData } from "../../configs/menu";

const Main = ({ userId, type, ...data }) => {

    const called = useRef(true);

    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        if (called.current) {
            setNewsData(menuData.filter((index) => index.id === userId)[0].submenu)
        }
        return () => {
          called.current = false;
        };
      }, []);

    //   console.log(newsData)
    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {/* {isLoading ? <Loading border={true} /> : null} */}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 px-4 py-4">
                            {newsData.length ? newsData.map((val, index) => (
                                <div className="sub-topic-small weight-medium text-center mb-1" key={index}>
                                    <a href={val.link} target={val.target} className="btn-default">{val.name}</a>
                                </div>
                            )) : 'test' }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
