import React, { useState, useCallback, useEffect } from 'react';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
// import '../assets/css/print.css';

const Main = ({viewQrcode,dataTable,...data}) => {

    return (
        <>

            <div className="ticket mb-5" style={{paddingBottom:'20px'}}>
                <div className='mt-1 font-12 text-center'>
                    
                    <div className='font-14 mt-3'>{dataTable.name}</div>
                    <div className='mb-5'>
                        <img src={process.env.REACT_APP_API_LINK + '/' + viewQrcode} alt="" className="w-50 px-3" />
                    </div>
                    {/* <div className='font-12 mt-1'>Order #{data.no}</div>
                    <div className='font-12 mt-1 '>{data.date}</div>
                    <div className='my-3'>
                        <img src={process.env.REACT_APP_API_URL + "/qrcode/order/" + data.id + "-qrcode.png"} alt="" className="w-100 px-4" />
                    </div>
                    <div className='font-10'>
                        QR code สำหรับดูเมนูและสั่งอาหาร <br />
                        ท่านสามารถใช้ Smartphone ในการสแกน <br />
                        เพื่อทำการสั่งอาหารได้เลย
                    </div>
                    <div className='font-9 mt-4'>
                        วิธีใช้ : เปิดกล้องโทรศัพท์ของท่าน<br />
                        และสแกน QR Code นี่ได้ทันที
                    </div> */}
                </div>

            </div>

        </>
    );
};

export default Main;