import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { Form } from "react-bootstrap";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Loading from "../../layout/loading";
import Modalalert from "../../layout/modal/alertImgModal";
import { getData, putData } from "../../services/getService";
import ModalDel from "../../layout/modal/delData";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import backB from "../../assets/img/icon/BackB.svg";
import addMember from "../../assets/img/icon/Download.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";
import bin from "../../assets/img/icon/bin.svg";

const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [dateToday, setDateToday] = useState(new Date());
    const [mainData, setMainData] = useState([]);
    const [twoData, setTwoData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [searchData, setSearchData] = useState({ button: 'day', dateStart: '', dateEnd: '' });
    const [checkbox, setCheckbox] = useState({ languages: [] });
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })
    const [modalDel, setModalDel] = useState({ view: false, id: 0, type: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        // minDate: todayDate,
        // startDate: startDate,
        // endDate: endDate,
        // singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD"))]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async (startDate,endDate) => {

        getData('promote', '2?startDate='+startDate+'&endDate='+endDate).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    setTwoData(response.data)
                    // filteredData(response.data, searchData.button)
                    setIsLoading(false);
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const dateMonthGen = ($year,$month) => {
        return $month == 2 ? ($year % 4 ? 28 : ($year % 100 ? 29 : ($year % 400 ? 28 : 29))) : (($month - 1) % 7 % 2 ? 30 : 31);
    }

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({ button: 'date', dateStart: picker.startDate.format('YYYY-MM-DD'), dateEnd: picker.endDate.format('YYYY-MM-DD') })
        filteredData('date', picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'),'get')
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({ button: 'day', dateStart: '', dateEnd: '' })
    };

    const refreshData = () => {
        fetchData(moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD"));
        setCheckbox({ languages: [] })
    };

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))
        // filteredData(mainData, e)
        filteredData(e,'','','get')
    }

    const filteredData = (e,st,en,type) => {

        let startDate
        let endDate
        if(e === 'day'){
            startDate = moment().format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        }else if(e === 'month'){
            startDate = moment().format("YYYY-MM") + '-01';
            // endDate = moment().format("YYYY-MM") + '-31';
            endDate = moment().format("YYYY-MM") + '-'+dateMonthGen(moment().format("YYYY"),moment().format("MM"));
        } else if (e === 'last') {
            startDate = moment().subtract('1', 'month').format("YYYY-MM") + '-01';
            // endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-31';
            endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-'+dateMonthGen(moment().subtract('1', 'month').format("YYYY"),moment().subtract('1', 'month').format("MM"));
        } else if (e === 'year') {
            startDate = moment().format("YYYY") + '-01-01';
            endDate = moment().format("YYYY") + '-12-31';
        }else{
            startDate = st;
            endDate = en;
        }
        fetchData(startDate,endDate);
    };

    const handleSubmit = async (event) => {

        // event.preventDefault();
        // const form = event.currentTarget;
        if (checkbox.languages.length) {
            const dataForm = new FormData();

            dataForm.set('idPromote', JSON.stringify(checkbox.languages));
            // dataForm.set('birth', moment(dataForm.get('birth'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

            setIsLoading(true);

            await putData('promote', 1, dataForm).then(
                response => {
                    if (response.status === 'success') {
                        // setTimeout(() => {
                        //     refreshData()
                        //     window.location.href = response.data.redirect;
                        // }, 100);
                        let promises = [];
                        var i;
                        // window.location.href = response.data.redirect;
                        for (i = 0; i < response.data.name.length; i++) {
                            promises.push(
                                handleDownload(response.data.folder + '/' + response.data.name[i].img, response.data.name[i].img)
                            )
                        }

                        Promise.all(promises).then(res => {
                            setTimeout(() => {
                                refreshData()
                                setIsLoading(false)
                            }, 100);
                        });
                    }
                }).catch(e => {
                    setIsLoading(false)
                    console.log(e.response.data.messages.errors)
                })
        } else {
            setModalMenu({ view: true, id: 0, type: 'alert' })
        }
    };

    const handleChange = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { languages } = checkbox;

        // console.log(`${value} is ${checked}`);

        if (checked) {
            setCheckbox({
                languages: [...languages, value]
            });
        } else {
            setCheckbox({
                languages: languages.filter(
                    (e) => e !== value
                )
            });
        }
    };

    const genLocation = () => {
        if(moment().format("HH:mm") <= moment('14:00', "HH:mm").format("HH:mm")){
			return moment().subtract('1', 'day').format("YYYY-MM-DD")+'='+moment().format("YYYY-MM-DD")
		}else{
			return moment().format("YYYY-MM-DD")+'='+moment().add('1', 'day').format("YYYY-MM-DD")
		}
    }

    const handleRefresh = () => {
        // setModalAdd({ view: false })
        fetchData(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
        setModalDel({ view: false })
        // onRefresh()
    }

    const handleDownload = (url, fileName) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    console.log(checkbox)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white position-relative" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <a href="/administrator/promote" className="btn btn-defalut">
                                                        <img src={backB} className="me-1" style={{ height: '35px' }} alt="" />
                                                        <span className="align-middle sub-topic-small weight-medium ms-2">ย้อนกลับ</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3 text-end">
                                                    <button onClick={(e) => handleSubmit()} className="btn btn-blue sub-topic-small text-white">
                                                        <img src={addMember} className="me-1" style={{ height: '15px' }} alt="" /> ดาวน์โหลดรายการที่เลือก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="px-4">
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <div className="sub-topic weight-medium">
                                                                ดาวน์โหลดแล้ว
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="row px-4 justify-content-md-end">
                                                    <div className="col-auto p-0">
                                                        <button onClick={() => handleSearch('day')} className={`btn btn-border-black me-1 ${searchData.button === 'day' && 'active'}`}>
                                                            วันนี้
                                                        </button>
                                                        {/* <button onClick={() => handleSearch('month')} className={`btn btn-border-black me-1 ${searchData.button === 'month' && 'active'}`}>
                                                            เดือนนี้
                                                        </button>
                                                        <button onClick={() => handleSearch('last')} className={`btn btn-border-black me-1 ${searchData.button === 'last' && 'active'}`}>
                                                            เดือนที่แล้ว
                                                        </button>
                                                        <button onClick={() => handleSearch('year')} className={`btn btn-border-black me-1 ${searchData.button === 'year' && 'active'}`}>
                                                            ปีนี้
                                                        </button> */}
                                                    </div>
                                                    {/* <div className="col-auto ps-0 pe-1">
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                               
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}
                                                            >
                                                                <input type="text" value={searchData.dateStart ? moment(searchData.dateStart).format("DD/MM/YYYY") + ' - ' + moment(searchData.dateEnd).format("DD/MM/YYYY") : ''} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 box-scroll">
                                                    <div className="row">
                                                        {twoData.length ? twoData.map((val, index) => (
                                                            <div className="col-2 col-md-2 col-lg-2 p-1" key={index}>
                                                                <div className="position-relative">
                                                                    <div className="position-absolute absolute-top-start">
                                                                        <div className="ex-checkbox4 d-inline-block align-middle me-4">
                                                                            <input type="checkbox" checked={checkbox.languages.length ? checkbox.languages.filter((a) => a == val.id).length ? true : false : false} name="remember" value={val.id} onChange={handleChange} />
                                                                            <label className="radio_one font-size-18">
                                                                                <span className='ps-2'></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <img src={process.env.REACT_APP_API_LINK_FILE + '/promote/'+genLocation()+'/'+  val.img} className="w-100" style={{ border: '1px solid #F5F5F5' }} />
                                                                    <div className="position-absolute text-center absolute-right-bottom cursor-pointer">
                                                                        <img src={bin} onClick={(e) => setModalDel({ view: true, id: val.id, type: 'promote' })} className="me-1" alt="" style={{filter: "brightness(0) invert(1)"}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalMenu.view ?
                    <Modalalert show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                    // dataProduct={modalMenu.dataProduct}
                    // selectData={selectData}
                    // onRefresh={refreshData}
                    />
                    : null
            }
{
                modalDel.view ?
                    <ModalDel show={modalDel.view}
                        dataId={modalDel.id}
                        onHide={handleRefresh}
                        type={modalDel.type}
                        onRefresh={handleRefresh}

                    />
                    : null
            }
        </>
    );
};

export default Main;
