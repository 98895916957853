import { PixelCrop } from 'react-image-crop'
// import { createDataNone } from "./services/getService";

import { useRecoilState } from 'recoil';
import { nameState } from './state/states'

const TO_RADIANS = Math.PI / 180

export async function canvasPreview(image,canvas,crop,scale = 1, rotate = 0,table,dataId){
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    // const pixelRatio = window.devicePixelRatio
    const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    // console.log(window.devicePixelRatio,Math.floor(crop.width * scaleX),Math.floor(crop.height * scaleY * pixelRatio))

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)

    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )
    ctx.beginPath();

    // var base_image = new Image();
    // base_image.src = boxImg;
    // base_image.onload = function(){
    //     ctx.drawImage(base_image, 10, 10,80,70);
    //     ctx.fillStyle = "#fff";
    //     ctx.font = "16px serif";
    //     ctx.fillText("โต๊ะ", 35, 35);

        
    //     ctx.fillStyle = "#fff";
    //     ctx.font = "30px serif";
    //     ctx.fillText(table, 25, 65);
    // }

    ctx.restore()

    const base64Image = canvas.toDataURL('image/jpeg',0.7);

    const img = new Image();
    img.src = base64Image;
    img.onload = function () {
        let imgWidth = img.naturalWidth;
        let imgHeight = img.naturalHeight;

        // if(imgWidth > 3000){
        //     imgWidth = imgWidth/2;
        //     imgHeight = imgHeight/2
        // }
// console.log(imgWidth,base64Image)
        // setOutput(base64Image);
        var arrayaa = { src: base64Image, width: imgWidth, height: imgHeight }
        localStorage.setItem('imgNature', JSON.stringify(arrayaa));

        // const dataForm = new FormData();

        // dataForm.set('orderId', dataId);
        // dataForm.set('imgNature', JSON.stringify(arrayaa));

        // createDataNone('storage', dataForm).then(
        //     response => {
        //         if (response.status === 'success') {
        //             // setTimeout(() => {
        //             //     // window.location.href = '/promote/'+dataId
        //             // }, 1000);
        //             console.log(response)
        //         }
        //     }).catch(e => {
        //         // setIsLoading(false)
        //         console.log(e.response.data.messages.errors)
        //     })
    };
}
