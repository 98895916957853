import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { getData } from "../../services/getService";

import ModalAdd from "../../layout/modal/singerModal";
import ModalDel from "../../layout/modal/delData";
import Loading from "../../layout/loading";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import plus from "../../assets/img/icon/AddPlus.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";
import edit from "../../assets/img/icon/edit.svg";
import bin from "../../assets/img/icon/bin.svg";


const Main = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);

    const [twoData, setTwoData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [searchData, setSearchData] = useState({ button: 'month', dateStart: '', dateEnd: '' });

    const [modalAdd, setModalAdd] = useState({ view: false, id: 0, type: '' })
    const [modalDel, setModalDel] = useState({ view: false, id: 0, type: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };

    const dateMonthGen = ($year,$month) => {
        return $month == 2 ? ($year % 4 ? 28 : ($year % 100 ? 29 : ($year % 400 ? 28 : 29))) : (($month - 1) % 7 % 2 ? 30 : 31);
    }

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({ button: 'date', dateStart: picker.startDate.format('YYYY-MM-DD'), dateEnd: picker.endDate.format('YYYY-MM-DD') })
        filteredData('date', picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'))
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({ button: 'month', dateStart: '', dateEnd: '' })
    };

    const called = useRef(true);

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-31')]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async (startDate,endDate) => {

        getData('singer?startDate='+startDate+'&endDate='+endDate).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    setTwoData(response.data)
                    // filteredData(response.data, searchData.button)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))
        // filteredData(mainData, e)
        filteredData(e,'','')
    }

    const filteredData = (e,st,en) => {
        // const tststst = edata.filter((item) => {
        //     const itemDate = new Date(item.dateActive);
        //     if (e === 'month') {
        //         const dateTo = moment().format("YYYY-MM");
        //         return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        //     } else if (e === 'last') {
        //         const dateTo = moment().subtract('1', 'month').format("YYYY-MM");
        //         return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        //     } else if (e === 'year') {
        //         const dateTo = moment().format("YYYY");
        //         return (itemDate >= new Date(dateTo + '-01-01')) && (itemDate <= new Date(dateTo + '-12-31'));
        //     } else {
        //         return (itemDate >= new Date(s)) && (itemDate <= new Date(end));
        //     }
        // })
        // setTwoData(tststst)
        // setTreeData(tststst)
        let startDate
        let endDate
        if(e === 'month'){
            startDate = moment().format("YYYY-MM") + '-01';
            // endDate = moment().format("YYYY-MM") + '-31';
            endDate = moment().format("YYYY-MM") + '-'+dateMonthGen(moment().format("YYYY"),moment().format("MM"));
        } else if (e === 'last') {
            startDate = moment().subtract('1', 'month').format("YYYY-MM") + '-01';
            // endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-31';
            endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-'+dateMonthGen(moment().subtract('1', 'month').format("YYYY"),moment().subtract('1', 'month').format("MM"));
        } else if (e === 'year') {
            startDate = moment().format("YYYY") + '-01-01';
            endDate = moment().format("YYYY") + '-12-31';
        }else{
            startDate = st;
            endDate = en;
        }
        fetchData(startDate,endDate);
    };

    const refreshData = () => {
        fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-31')
        setModalAdd({ view: false })
        setModalDel({ view: false })
    };


    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <Menu page={'entertain'} subPage={'singer'} />
                                                    <span className="align-middle sub-topic weight-medium ms-4">ตั้งค่าข้อมูลนักร้อง</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3 text-end">
                                                    <button onClick={(e) => setModalAdd({ view: true, id: 1, type: 'add' })} className="btn btn-addmenu sub-topic-small text-white py-2 px-5">
                                                        <img src={plus} className="me-1" style={{ height: '18px' }} alt="" /> เพิ่มข้อมูล
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6"></div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="row px-4 justify-content-md-end">
                                                    <div className="col-auto p-0">
                                                        <button onClick={() => handleSearch('month')} className={`btn btn-border-black me-1 ${searchData.button === 'month' && 'active'}`}>
                                                            เดือนนี้
                                                        </button>
                                                        <button onClick={() => handleSearch('last')} className={`btn btn-border-black me-1 ${searchData.button === 'last' && 'active'}`}>
                                                            เดือนที่แล้ว
                                                        </button>
                                                        <button onClick={() => handleSearch('year')} className={`btn btn-border-black me-1 ${searchData.button === 'year' && 'active'}`}>
                                                            ปีนี้
                                                        </button>
                                                    </div>
                                                    <div className="col-auto ps-0 pe-1">
                                                        <div className="input-group">

                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                // onEvent={handleEvent} onCallback={handleCallback}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}
                                                            >
                                                                <input type="text" defaultValue="" placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 box-scroll">
                                                    <table className="table table-striped table-list">
                                                        <thead>
                                                            <tr>
                                                                <th>วันที่</th>
                                                                <th>เวลา</th>
                                                                <th>รายการ</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {twoData.length ? twoData.map((val, index) => (
                                                                <tr key={index}>
                                                                    <td className="align-top">{moment(val.dateActive).format('DD/MM/YYYY')}</td>
                                                                    <td>
                                                                        {val.singer.length ? val.singer.map((vall, indexx) => (
                                                                            <div key={indexx}>
                                                                                {moment(vall.dateStart).format('HH:mm')} - {moment(vall.dateEnd).format('HH:mm')} น.
                                                                            </div>
                                                                        )) : <div></div>}
                                                                    </td>
                                                                    <td>
                                                                        {val.singer.length ? val.singer.map((vall, indexx) => (
                                                                            <div key={indexx}>
                                                                                {vall.name}
                                                                            </div>
                                                                        )) : <div></div>}
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <button onClick={(e) => setModalAdd({ view: true, id: val.id, type: 'edit' })} className="btn btn-action py-0 px-0">
                                                                            <img src={edit} className="" style={{ height: '40px' }} alt="" />
                                                                        </button>
                                                                        <button onClick={(e) => setModalDel({ view: true, id: val.id, type: 'singer' })} className="btn btn-action py-0 px-0">
                                                                            <img src={bin} className="" style={{ height: '40px' }} alt="" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )) : <tr><td colSpan={4} className="text-center">-- No data --</td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalAdd.view ?
                    <ModalAdd show={modalAdd.view}
                        dataId={modalAdd.id}
                        onHide={() => setModalAdd({ view: false })}
                        dataOne={mainData.filter((item) => item.id === modalAdd.id)}
                        type={modalAdd.type}
                        onRefresh={refreshData}
                    />
                    : null
            }{
                modalDel.view ?
                    <ModalDel show={modalDel.view}
                        dataId={modalDel.id}
                        onHide={() => setModalDel({ view: false })}
                        type={modalDel.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Main;
