import { base_api, axiosHeader,axiosHeaderNone, axiosJson,axiosJsonNone, axiosFetchSmartCard } from "../configs/fetcher";

const getUsers = async (id) => {
    let url;
    if (id) { url = `${base_api}/auth/users/${id}` } else { url = `${base_api}/auth/users` }
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getUsersPagination = async (page, key, type, cat, id) => {
    let url;

    if (id) { url = `${base_api}/auth/usersPagination/${id}?page=${page}&cat=${cat}&search=${key}&status=${type}` } else { url = `${base_api}/auth/usersPagination?page=${page}&cat=${cat}&search=${key}&status=${type}` }
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

const getData = async (type, id) => {
    let url;
    if (id) { url = `${base_api}/auth/data/${type}/${id}` } else { url = `${base_api}/auth/data/${type}` }
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const exportData = async (type, id) => {
    let url;
    if (id) { url = `${base_api}/auth/export/${type}/${id}` } else { url = `${base_api}/auth/export/${type}` }
    const response = await axiosJson.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getDataNone = async (type, id) => {
    let url;
    if (id) { url = `${base_api}/data/${type}/${id}` } else { url = `${base_api}/data/${type}` }
    const response = await axiosJsonNone.get(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const getDataSmard = async (url) => {
    const response = await axiosFetchSmartCard(url).then(resporn => {
        var idcard = resporn.data
        var x = idcard.substring(13, idcard.length - 1)
        const obj = JSON.parse(x);
        return obj
    })
    if (!response) return { status: 'error' }
    return { status: 'success', data: response }
}

const putNewpass = async (request) => {

    const url = `${base_api}/auth/newpass/`
    const response = await axiosJson.put(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

const putResetpass = async (request) => {

    const url = `${base_api}/auth/resetpass/`
    const response = await axiosJson.put(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

const putData = async (type, id, request) => {

    const url = `${base_api}/auth/update/${type}/${id}`
    const response = await axiosJson.put(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}
const putDataProduct = async (type, id, request) => {

    const url = `${base_api}/auth/update/${type}/${id}`
    const response = await axiosHeader.post(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

const createData = async (type, request) => {

    const url = `${base_api}/auth/create/${type}`
    const response = await axiosHeader.post(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }

}
const createDataNone = async (type, request) => {

    const url = `${base_api}/create/${type}`
    const response = await axiosHeaderNone.post(url, request)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }

}
const delData = async (type, id) => {

    const url = `${base_api}/auth/delete/${type}/${id}`
    const response = await axiosHeader.delete(url)
    if (!response) return { status: 'error' }
    return { status: 'success', data: response.data }
}

export {
    getUsers,
    getUsersPagination,
    getData,
    exportData,
    getDataNone,
    getDataSmard,
    putData,
    putDataProduct,
    putNewpass,
    putResetpass,
    createData,
    createDataNone,
    delData
};
