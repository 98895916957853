import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Loading from "../../layout/loading";
import Modalalert from "../../layout/modal/alertImgModal";
import { fabric } from "fabric";
import { getDataNone } from "../../services/getService";

import frame0 from "../../assets/img/frame0.png";
import frame1 from "../../assets/img/frame1.png";
import frame2 from "../../assets/img/frame2.png";

import boxImg from "../../assets/img/boxTable2.png";
import boxIg from "../../assets/img/ig2.png";

import editPhoto from "../../assets/img/icon/EditPhoto.svg";
import back from "../../assets/img/icon/Back.svg";

import { useRecoilValue } from 'recoil';
import { nameState } from '../../state/states'

const Home = () => {

    const pr = useParams();
    const fileInputRef = useRef();

    const name = useRecoilValue(nameState)

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);

    const [fileDataimg, setFileDataimg] = useState({ imgNature: '', imgNaturetext: '' });

    const [tableData, setTableData] = useState({});
    const [imgNatureData, setImgNatureData] = useState();
    const [modalMenu, setModalMenu] = useState({ view: false, id: 0, type: '' })

    const [nameIg, setNameIg] = useState({ igName: null, tableName: null });

    const called = useRef(true)
    const canvasRef = useRef(null);
    useEffect(() => {
        if (called.current) {
            // Promise.all([fetchData(pr.id)]).then(res => {
            laodData((JSON.parse(localStorage.getItem('igName')).tableName), (JSON.parse(localStorage.getItem('igName')).igName))
            setNameIg({ igName: (JSON.parse(localStorage.getItem('igName')).igName), tableName: (JSON.parse(localStorage.getItem('igName')).tableName) })
            setIsLoading(false);
            // });
        }
        return () => {
            called.current = false
        }
    }, [])


    // const fetchData = async (id) => {

    //     getDataNone('order', id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 if (response.data) {
    //                     setMainData(response.data)
    //                     fetchTable(response.data.tableId)
    //                 } else {
    //                     setIsLoading(true);
    //                 }
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //             setIsLoading(true);
    //         })
    // }
    // const fetchTable = async (id) => {

    //     getDataNone('table', id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setTableData(response.data)
    //                 setTimeout(() => {
    //                     laodData(response.data.name)
    //                     setTimeout(() => {
    //                         setIsLoading(false);
    //                     }, 1000);
    //                 }, 100);
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }

    // const fetchDataIMG = async (id,tableName) => {

    //     getDataNone('storage', id).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 // console.log(response.data)
    //                 if (response.data) {
    //             //  console.log(0)
    //                     // fetchTable(response.data.tableId)
    //                     const imgNature = JSON.parse(response.data.imgNature);

    //                 } else {
    //                     setIsLoading(true);
    //                 }
    //             }
    //         }).catch(e => {
    //             // console.log(e.response)
    //             setIsLoading(true);
    //         })
    // }


    const laodData = (tableName, igName) => {
        // fetchDataIMG(pr.id,tableName)    
        const imgNature = JSON.parse(localStorage.getItem('imgNature'));

        const imgNatureText = name && JSON.parse(name);
        // console.log((imgNatureText))
        const nameIgs = igName
        // setNameIg(nameIgs);

        if (imgNatureText) {
            setImgNatureData(imgNatureText)

            canvasRef.current = initCanvas();
            canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));

            setTimeout(() => {
                var imageUrl = imgNatureText.src;
                functionUnFrame(imageUrl, imgNatureText, nameIgs, tableName)
            }, 100);
        } else {
            setImgNatureData(imgNature)
            // setItems(items);
            canvasRef.current = initCanvas();
            canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));

            setTimeout(() => {
                var imageUrl = imgNature.src;
                functionUnFrame(imageUrl, imgNature, nameIgs, tableName)
            }, 100);
        }

        return () => canvasRef.current.dispose();
    }

    const initCanvas = () => (

        // Create a Fabric.Image object 
        new fabric.Canvas('c3', {
            // height: 278,
            // width: 370,
            height: 675,
            width: 1200,
            // height: 1442.59,
            // width: 1920,
            // backgroundImage: upImg,
        })
    );

    const handleFrame = (f) => {
        let ff
        if (f === 0) {
            ff = null
        } else if (f === 1) {
            ff = frame1
        } else if (f === 2) {
            ff = frame2
        }

        if (ff !== null) {
            fabric.Image.fromURL(ff, function (img) {
                // add background image
                canvasRef.current = initCanvas();
                canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));
                canvasRef.current.setBackgroundImage(img, canvasRef.current.renderAll.bind(canvasRef.current), {
                    scaleX: canvasRef.current.width / img.width,
                    scaleY: canvasRef.current.height / img.height
                });
                functionInFrame(imgNatureData.src, imgNatureData, nameIg.igName, nameIg.tableName)

            });
        } else {
            // window.location.reload(false);
            laodData(nameIg.tableName, nameIg.igName)
            // functionUnFrame()
            // canvasRef.current = initCanvas();
            // canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));

        }
    }

    const functionUnFrame = (imageUrl, imgNature, nameIgs, tableName) => {
        canvasRef.current = initCanvas();
        canvasRef.current.setBackgroundImage(0, canvasRef.current.renderAll.bind(canvasRef.current));
        fabric.Image.fromURL(imageUrl, function (myImg) {
            var img1 = myImg.set({
                // left: 15, top: 15,
                selectable: false,
                scaleX: ((canvasRef.current.width) * 1 / (imgNature.width)),
                scaleY: ((canvasRef.current.height) * 1 / (imgNature.height)),
                // cropX: 100, cropY: 100,
                // cornersize: 1
            });
            canvasRef.current.add(img1);

            // fabric.Image.fromURL(boxImg, function (myImg) {
            //     var img1 = myImg.set({ left: 30, top: 30, selectable: false });
            //     canvasRef.current.add(img1);
            //     canvasRef.current.add(new fabric.Text('โต๊ะ', { left: 160, top: 50, fill: '#fff', fontSize: 70, selectable: false, fontFamily: 'Arial', }));
            //     canvasRef.current.add(new fabric.Text(tableName, { left: 100, top: 120, fill: '#fff', fontSize: 124, fontWeight: 'bold', fontFamily: 'Arial', selectable: false }));
            // });

            // fabric.Image.fromURL(boxIg, function (myImg) {
            //     var img1 = myImg.set({ left: 30, top: 1170, selectable: false });
            //     canvasRef.current.add(img1);
            //     canvasRef.current.add(new fabric.Text(nameIgs, { left: 260, top: 1250, fill: '#fff', fontSize: 80, fontFamily: 'Arial', selectable: false }));
            // });
            fabric.Image.fromURL(boxImg, function (myImg) {
                // var img1 = myImg.set({ left: 20, top: 20, selectable: false });
                // canvasRef.current.add(img1);
                canvasRef.current.add(new fabric.Text('โต๊ะ', { left: 40, top: 55, fill: '#fff', fontSize: 35, selectable: false, fontFamily: 'Prompt', }));
                canvasRef.current.add(new fabric.Text(tableName, { left: 100, top: 30, fill: '#fff', fontSize: 65, fontWeight: 'bold', fontFamily: 'Prompt', selectable: false }));
            });

            fabric.Image.fromURL(boxIg, function (myImg) {
                var img1 = myImg.set({ left: 45, top: 550, selectable: false });
                canvasRef.current.add(img1);
                canvasRef.current.add(new fabric.Text(nameIgs, { left: 135, top: 562, fill: '#fff', fontSize: 55, fontFamily: 'Prompt', fontWeight: 400, selectable: false }));
            });
        });
    }

    const functionInFrame = (imageUrl, imgNature, nameIgs, tableName) => {

        fabric.Image.fromURL(imageUrl, function (myImg) {
            var img1 = myImg.set({
                left: 35, top: 35,
                selectable: false,
                scaleX: ((canvasRef.current.width - 70) / (imgNature.width)),
                scaleY: ((canvasRef.current.height - 70) / (imgNature.height)),
                // cropX: (canvasRef.current.width), cropY: canvasRef.current.height,
                cornersize: 1,
                //         originX: 'center', 
                // originY: 'center'

            });
            // var img1 = myImg.set({
            //     left: 65, top: 65,
            //     selectable: false,
            //     scaleX: ((canvasRef.current.width - 150) / (imgNature.width)),
            //     scaleY: ((canvasRef.current.height - 150) / (imgNature.height)),
            //     // cropX: (canvasRef.current.width), cropY: canvasRef.current.height,
            //     cornersize: 1,
            //     //         originX: 'center', 
            //     // originY: 'center'

            // });
            canvasRef.current.add(img1);

            // fabric.Image.fromURL(boxImg, function (myImg) {
            //     var img1 = myImg.set({ left: 30, top: 30, selectable: false });
            //     canvasRef.current.add(img1);
            //     canvasRef.current.add(new fabric.Text('โต๊ะ', { left: 160, top: 50, fill: '#fff', fontSize: 70, selectable: false, fontFamily: 'Arial' }));
            //     canvasRef.current.add(new fabric.Text(tableName, { left: 100, top: 120, fill: '#fff', fontSize: 124, fontWeight: 'bold', fontFamily: 'Arial', selectable: false }));
            // });

            // fabric.Image.fromURL(boxIg, function (myImg) {
            //     var img1 = myImg.set({ left: 30, top: 1170, selectable: false });
            //     canvasRef.current.add(img1);
            //     canvasRef.current.add(new fabric.Text(nameIgs, { left: 260, top: 1250, fill: '#fff', fontSize: 80, fontFamily: 'Arial', selectable: false }));
            // });
            fabric.Image.fromURL(boxImg, function (myImg) {
                // var img1 = myImg.set({ left: 20, top: 20, selectable: false });
                // canvasRef.current.add(img1);
                canvasRef.current.add(new fabric.Text('โต๊ะ', { left: 40, top: 55, fill: '#fff', fontSize: 35, selectable: false, fontFamily: 'Prompt', }));
                canvasRef.current.add(new fabric.Text(tableName, { left: 100, top: 30, fill: '#fff', fontSize: 65, fontWeight: 'bold', fontFamily: 'Prompt', selectable: false }));
            });

            fabric.Image.fromURL(boxIg, function (myImg) {
                var img1 = myImg.set({ left: 45, top: 550, selectable: false });
                canvasRef.current.add(img1);
                canvasRef.current.add(new fabric.Text(nameIgs, { left: 135, top: 562, fill: '#fff', fontSize: 55, fontFamily: 'Prompt', fontWeight: 400, selectable: false }));
            });
        });
    }

    const handleNext = () => {

        const base64Image = canvasRef.current.toDataURL('image/jpeg', 0.7);

        const img = new Image();
        img.src = base64Image;
        img.onload = function () {
            // const imgWidth = img.naturalWidth;
            // const imgHeight = img.naturalHeight;

            // var arrayaa = { src: base64Image, width: imgWidth, height: imgHeight }
            // localStorage.setItem('imgNatureAddtext', JSON.stringify(arrayaa));

            // setIsLoading(true)

            // setTimeout(() => {
            //     window.location.href = '/promote/edit/'+pr.id
            // }, 200);
        };
        // if (fileData) {
        // console.log(base64Image)
        // } else {
        setModalMenu({ view: true, id: 0, type: 'send', dataImg: base64Image,ig:nameIg.igName })

        // }
    }

console.log(nameIg)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main">
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start pt-5">
                                <div className="col-12 col-md-12 col-lg-12 p-0">
                                    <div className="position-absolute" style={{ top: '10px', left: '10px' }}>
                                        <a href={"/promote"} className="text-white btn-default">
                                            <img src={back} className="" alt="" /> ย้อนกลับ
                                        </a>
                                    </div>
                                    <div className="box-in-home-res bg-white text-center px-3">
                                        <div className="topic weight-bold pt-4">โต๊ะ {nameIg.tableName}</div>
                                        {/* <div className="font-14 weight-light">{moment(mainData.dateActive).format('DD/MM/YYYY')}&nbsp; | &nbsp;Check in : {moment(mainData.dateActive).format('HH:mm')} น.</div> */}
                                        <div className="row justify-content-md-center text-start">
                                            {/* <div className="col-6 col-md-3 col-lg-2 text-center px-0 py-5" >
                                                <img src={imgNatureData && imgNatureData.src} className="w-100" />
                                            </div> */}
                                            <div className="col-12 col-md-12 col-lg-12 text-center px-0 py-5 position-relative" >
                                            <div className="position-absolute bg-white w-100" style={{ height: '30px', bottom: '-12px',left:0 }}></div>
                                                <div className="text-end m-auto position-relative" style={{ width: '387px' }}>

                                                    {/* <button className="btn btn-cancle" onClick={() => handleaddText()}><img className='' src={addText} /></button> */}
                                                    {/* <button className="btn btn-cancle position-relative" onClick={() => fileInputRef.current.click()}>
                                                        <img className='' src={textColor} />
                                                        <span className="position-absolute absolute-center-bottom" style={{ bottom: '3px', borderBottom: '3px solid ' + getStyle.color, height: '5px', width: '20px' }}></span>
                                                        <input
                                                            type="color"
                                                            value={getStyle.color}
                                                            size="10"
                                                            onChange={(e) => handletextColorChange(e)}
                                                            hidden
                                                            ref={fileInputRef}
                                                        />
                                                    </button> */}
                                                    {/* <button className="btn btn-cancle" onClick={() => handleFontStyle()}><img className='' src={fontStyle} /></button> */}
                                                    {/* <button className="btn btn-cancle ps-0 pe-1" onClick={() => handleDel()}><img className='' src={delBin} /></button> */}
                                                </div>
                                                <div className="">
                                                    <canvas id="c3" />
                                                </div>
                                                <div className="text-start mt-2 ms-2">
                                                    <div className="font-18 weight-medium">เลือกกรอบรูปภาพ</div>
                                                    <div>
                                                        <ul className="frame">
                                                            <li><img src={frame0} className="w-100" onClick={(e) => handleFrame(0)} /></li>
                                                            <li><img src={frame1} className="w-100" onClick={(e) => handleFrame(1)} /></li>
                                                            <li><img src={frame2} className="w-100" onClick={(e) => handleFrame(2)} /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <a className="btn btn-blue text-white w-75 mt-4" href={"/promote/addText"}>
                                                    <img src={editPhoto} className="" style={{ width: '20px' }} /> ใส่ข้อความบนรูปภาพ
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-3 box-order-list">
                                        <div className="row align-items-center text-start">
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <a className="btn btn-cancle w-100" href={"/promote"}>ยกเลิก</a>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="position-relative">
                                                    <button onClick={() => handleNext()} className="btn btn-addmember d-block w-100 text-white">
                                                        ถัดไป
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalMenu.view ?
                    <Modalalert show={modalMenu.view}
                        dataId={modalMenu.id}
                        onHide={() => setModalMenu({ view: false })}
                        type={modalMenu.type}
                        dataImg={modalMenu.dataImg}
                        ig={modalMenu.ig}
                    // selectData={selectData}
                    // onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Home;
