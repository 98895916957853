import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { createDataNone } from "../../services/getService";
import delIcon from "../../assets/img/icon/Del.svg";
import Loading from "../../layout/loading";

const Main = ({ dataId, dataImg, ig, type, onRefresh, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);

    const [errorIg, setErrorIg] = useState();

    const handleSubmit = async (event) => {

        setIsLoading(true)

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData();

        dataForm.set('orderId', dataId);
        dataForm.set('ig', ig);
        dataForm.set('dataImg', JSON.stringify(dataImg));

        await createDataNone('promote', dataForm).then(
            response => {
                if (response.status === 'success') {
                    setTimeout(() => {
                        window.location.href = '/promote'
                    }, 1000);
                    setIsLoading(false)
                }
            }).catch(e => {
                setIsLoading(false)
                setErrorIg(e.response.data.messages.errors)
            })
    }

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    {type === 'alert' ?
                        <div className="row align-items-center text-start">
                            <div className="col-12 col-md-12 col-lg-12 px-4">
                                <div className="sub-topic weight-bold text-center mt-4 mb-4">
                                    กรุณาเลือกรูปภาพ
                                </div>
                                <div className="px-3 text-center">
                                    คุณยังไม่ได้เลือกรูปภาพ กรุณาเลือกรูปภาพของคุณ
                                    เพื่อทำรายการต่อ
                                </div>
                                <div className="mt-5 mb-4">
                                    <button className="btn btn-addmember text-white w-100" onClick={data.onHide}>ยืนยัน</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row align-items-center text-start">
                            {errorIg ?
                                <div className="col-12 col-md-12 col-lg-12 px-4">
                                    <div className="sub-topic weight-bold text-center mt-4 mb-4">
                                        ไม่สามารถใช้ ชื่อ Instagram ซ้ำได้
                                    </div>
                                    <div className="mt-5 mb-4">
                                        <a href="/promote" className="btn btn-addmember text-white w-100">ยืนยัน</a>
                                    </div>
                                </div>
                                :
                                <div className="col-12 col-md-12 col-lg-12 px-4">
                                    <div className="sub-topic weight-bold text-center mt-4 mb-4">
                                        ยืนยันส่งรูปภาพ
                                    </div>
                                    <div className="px-3 text-center">
                                        การกดยืนยันจะไม่สามารถ
                                        กลับมาแก้ไขรูปได้อีก
                                    </div>
                                    <div className="mt-5 mb-4">
                                        <button className="btn btn-addmember text-white w-100" onClick={handleSubmit}>ยืนยัน</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
