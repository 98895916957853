import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { Form } from "react-bootstrap";
import { Tab, Nav } from "react-bootstrap";
import TipsReport from "../../administrator/entertain/tabTips";
import GiftReport from "../../administrator/entertain/tabGift";
import TipsReportSum from "../../administrator/entertain/tabTipsSum";
import GiftReportSum from "../../administrator/entertain/tabGiftSum";
import TotalReportSum from "../../administrator/entertain/totalReport";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ModalAddMember from "../../layout/modal/memberModal";
import ModalDelMember from "../../layout/modal/delData";
import Loading from "../../layout/loading";

import { getData, exportData } from "../../services/getService";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import menu from "../../assets/img/icon/Menu.svg";
import pdf from "../../assets/img/icon/Pdf.svg";
import xls from "../../assets/img/icon/Xls.svg";
import addMember from "../../assets/img/icon/AddMember.svg";
import search from "../../assets/img/icon/Search.svg";
import edit from "../../assets/img/icon/edit.svg";
import bin from "../../assets/img/icon/bin.svg";
import detail from "../../assets/img/icon/detail.svg";
import addPoint from "../../assets/img/icon/Addpoint.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";


const Main = () => {

    const dateMonthGen = ($year, $month) => {
        return $month == 2 ? ($year % 4 ? 28 : ($year % 100 ? 29 : ($year % 400 ? 28 : 29))) : (($month - 1) % 7 % 2 ? 30 : 31);
    }

    // const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('reportTips');

    const [searchData, setSearchData] = useState({ button: 'month', dateStart: '', dateEnd: '' });
    const [modalAddMember, setModalAddMember] = useState({ view: false, id: 0, type: '' })
    const [modalDelMember, setModalDelMember] = useState({ view: false, id: 0, type: '' })

    const [dateData, setDateData] = useState({ name: '', startDate: moment().format("YYYY-MM") + '-01', endDate: moment().format("YYYY-MM") + '-' + dateMonthGen(moment().format("YYYY"), moment().format("MM")) });

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        // minDate: todayDate,
        // startDate: startDate,
        // endDate: endDate,
        // singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };


    // const called = useRef(true);

    // useEffect(() => {
    //     if (called.current) {
    //         Promise.all([fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-31')]).then(res => {
    //             setIsLoading(false);
    //         });
    //     }
    //     return () => {
    //         called.current = false;
    //     };
    // }, []);

    // const fetchData = async (startDate,endDate) => {

    //     getData('report?startDate='+startDate+'&endDate='+endDate).then(
    //         response => {
    //             if (response.status === 'success') {
    //                 setMainData(response.data)
    //                 setTwoData(response.data)
    //             }
    //         }).catch(e => {
    //             console.log(e.response.data.error)
    //         })
    // }

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({ button: 'date', dateStart: picker.startDate.format('YYYY-MM-DD'), dateEnd: picker.endDate.format('YYYY-MM-DD') })
        filteredData('date', picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'), 'get', '')
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({ button: 'month', dateStart: '', dateEnd: '' })
    };

    const refreshData = () => {
        // fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-31')
        setModalAddMember({ view: false })
        setModalDelMember({ view: false })
    };

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))

        filteredData(e, '', '', 'get', '')
    }

    const handleSearchM = (e) => {
 
        let startDate
        let endDate

        startDate = moment(dateData.endDate).format("YYYY") +'-'+moment(e).format("MM") + '-01';
        endDate = moment(dateData.endDate).format("YYYY") +'-'+moment(e).format("MM") + '-' + dateMonthGen(moment().format("YYYY"), moment().format("MM"));

        setSearchData({ button: 'date', dateStart: startDate, dateEnd: endDate })
        filteredData('date', startDate, endDate, 'get', '')
    }
    const handleSearchY = (e) => {
 
        let startDate
        let endDate

        startDate = moment(e).format("YYYY") +'-'+moment(dateData.endDate).format("MM") + '-01';
        endDate = moment(e).format("YYYY") +'-'+moment(dateData.endDate).format("MM") + '-' + dateMonthGen(moment().format("YYYY"), moment().format("MM"));

        setSearchData({ button: 'date', dateStart: startDate, dateEnd: endDate })
        filteredData('date', startDate, endDate, 'get', '')
    }

    const filteredData = (e, st, en, type, cat) => {

        let startDate
        let endDate
        if (e === 'month') {
            startDate = moment().format("YYYY-MM") + '-01';
            endDate = moment().format("YYYY-MM") + '-' + dateMonthGen(moment().format("YYYY"), moment().format("MM"));
        } else if (e === 'last') {
            startDate = moment().subtract('1', 'month').format("YYYY-MM") + '-01';
            endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-' + dateMonthGen(moment().subtract('1', 'month').format("YYYY"), moment().subtract('1', 'month').format("MM"));
        } else if (e === 'year') {
            startDate = moment().format("YYYY") + '-01-01';
            endDate = moment().format("YYYY") + '-12-31';
        } else {
            startDate = st;
            endDate = en;
        }

        if (type === 'get') {
            // fetchData(startDate,endDate);
            setDateData({ name: '', startDate: startDate, endDate: endDate })
            if (activeTab === 'reportTips' || activeTab === 'reportGift') {
                myFormRef.current.reset()
            }
        } else if (type === 'export') {
            fetchExport(startDate, endDate, activeTab, cat)
        }
    };

    const myFormRef = useRef();
    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);
        if (dataForm.get('singerName')) {
            setDateData((prev) => ({ ...prev, name: dataForm.get('singerName') }))
        } else {
            setDateData((prev) => ({ ...prev, name: '' }))
        }
    };

    const handleTab = (e) => {
        setActiveTab(e)

        setSearchData({ button: 'month', dateStart: '', dateEnd: '' })

        filteredData('month', '', '', 'get', '')
    }

    const handleExport = async (event) => {
        filteredData(searchData.button, searchData.dateStart, searchData.dateEnd, 'export', event)
    }

    const fetchExport = async (startDate, endDate, active, cat) => {
        let pi
        if (cat === 'xls') {
            pi = active
        } else {
            pi = active + 'PDF'
        }
        exportData(pi + '?startDate=' + startDate + '&endDate=' + endDate).then(
            response => {
                if (response.status === 'success') {
                    // console.log(response.data)
                    window.open(response.data.redirect, '_blank')
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    console.log(dateData)
    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {/* {isLoading ? <Loading /> : null} */}
                    <Tab.Container id="tabs-with-dropdown" defaultActiveKey="0">
                        <section className="pt-3" id="page-wrap">
                            <div className="container-fluid ">
                                <div className="row align-items-center text-start">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <div className="box-in bg-white overflow-hidden" style={{ height: 'calc(100vh - 4rem)' }}>
                                            <div className="row justify-content-md-center">
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="p-3">
                                                        <Menu page={'entertain'} subPage={'report'} />
                                                        <span className="align-middle sub-topic weight-medium ms-4">รายงาน</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="p-3 text-end">
                                                        {activeTab !== 'total' ?
                                                            <button onClick={(e) => handleExport('pdf')} className="btn px-0">
                                                                <img src={pdf} className="" style={{ height: '45px' }} alt="" />
                                                            </button>
                                                            : null}
                                                        <button onClick={(e) => handleExport('xls')} className="btn">
                                                            <img src={xls} className="" style={{ height: '45px' }} alt="" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-md-center" style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    {activeTab === 'reportTips' || activeTab === 'reportGift' ?
                                                        <div className="px-4">
                                                            <Form noValidate onSubmit={handleSubmit} ref={myFormRef}>
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-auto">
                                                                        <a href="/" className="btn btn-menu py-0 px-md-3 px-lg-3" style={{ opacity: 0 }}>
                                                                            <img src={menu} className="" style={{ height: '30px' }} alt="" />
                                                                        </a>
                                                                        <span className="sub-topic-small weight-medium ms-4">ค้นหาชื่อนักร้อง</span>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="input-group">
                                                                            <input maxLength={10} className="form-control input-custom border-end-0 border" type="search" name="singerName" placeholder="ชื่อนักร้อง" />
                                                                            <span className="input-group-append">
                                                                                <button type="submit" className="btn btn-outline-secondary bg-white border-start-0 border ms-n5 px-2" style={{ borderRadius: '0 4px 4px 0' }}>
                                                                                    <img src={search} className="" style={{ height: '30px' }} alt="" />
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6">
                                                    <div className="row px-4 justify-content-md-end">
                                                        {activeTab !== 'total' ?
                                                            <div className="col-auto p-0">
                                                                <button onClick={() => handleSearch('month')} className={`btn btn-border-black me-1 ${searchData.button === 'month' && 'active'}`}>
                                                                    เดือนนี้
                                                                </button>
                                                                <button onClick={() => handleSearch('last')} className={`btn btn-border-black me-1 ${searchData.button === 'last' && 'active'}`}>
                                                                    เดือนที่แล้ว
                                                                </button>
                                                                <button onClick={() => handleSearch('year')} className={`btn btn-border-black me-1 ${searchData.button === 'year' && 'active'}`}>
                                                                    ปีนี้
                                                                </button>
                                                            </div>
                                                            : null}
                                                        {activeTab !== 'total' ?
                                                            <div className="col-auto ps-0 pe-1">
                                                                <div className="input-group">

                                                                    <DateRangePicker
                                                                        initialSettings={dateoptionrang}
                                                                        // onEvent={handleEvent} onCallback={handleCallback}
                                                                        onApply={handleApply}
                                                                        onCancel={handleCancel}
                                                                    >
                                                                        <input type="text" value={searchData.dateStart ? moment(searchData.dateStart).format("DD/MM/YYYY") + ' - ' + moment(searchData.dateEnd).format("DD/MM/YYYY") : ''} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                                    </DateRangePicker>
                                                                    <span className="input-group-append">
                                                                        <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                            <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            : null}

                                                        {activeTab === 'total' ?
                                                            <div className="col-auto ps-0 pe-1">
                                                                <div className="input-group">

                                                                    <select class="form-select input-custom py-2 me-2" onChange={(e) => handleSearchM(e.target.value)} value={moment(dateData.startDate).format("MM")} aria-label="Default select example">
                                                                        <option value="01">January</option>
                                                                        <option value="02">February</option>
                                                                        <option value="03">March</option>
                                                                        <option value="04">April</option>
                                                                        <option value="05">May</option>
                                                                        <option value="06">June</option>
                                                                        <option value="07">July</option>
                                                                        <option value="08">August</option>
                                                                        <option value="09">September</option>
                                                                        <option value="10">October</option>
                                                                        <option value="11">November</option>
                                                                        <option value="12">December</option>
                                                                    </select>

                                                                    <select class="form-select input-custom py-2" onChange={(e) => handleSearchY(e.target.value)} value={moment(dateData.startDate).format("YYYY")} aria-label="Default select example">
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                        <option value="2026">2026</option>
                                                                        <option value="2027">2027</option>
                                                                        <option value="2028">2028</option>
                                                                        <option value="2029">2029</option>
                                                                        <option value="2030">2030</option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <div className="pt-2 text-end">
                                                        <Nav as="ul" variant="tabs" className="nav nav-tabs overflow-hidden tab-custom">
                                                            <Nav.Item as="li" >
                                                                <Nav.Link eventKey={0} onClick={e => handleTab('reportTips')}>Tips</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as="li" >
                                                                <Nav.Link eventKey={1} onClick={e => handleTab('reportGift')}>ของขวัญ</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as="li" >
                                                                <Nav.Link eventKey={2} onClick={e => handleTab('total')}>สรุปยอดรายการเครื่องดื่ม</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as="li" >
                                                                <Nav.Link eventKey={3} onClick={e => handleTab('reportTipsSum')}>สรุปรายได้ทิป</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as="li" >
                                                                <Nav.Link eventKey={4} onClick={e => handleTab('reportGiftSum')}>สรุปรายได้เครื่องดื่ม</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="0">
                                                            {activeTab === 'reportTips' ?
                                                                <TipsReport idZone="tips" dateData={dateData} onRefresh={refreshData} />
                                                                : null}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="1">
                                                            {activeTab === 'reportGift' ?
                                                                <GiftReport idZone="gift" dateData={dateData} onRefresh={refreshData} />
                                                                : null}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="2">
                                                            {activeTab === 'total' ?
                                                                <TotalReportSum idZone="total" dateData={dateData} onRefresh={refreshData} />
                                                                : null}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="3">
                                                            {activeTab === 'reportTipsSum' ?
                                                                <TipsReportSum idZone="tips" dateData={dateData} onRefresh={refreshData} />
                                                                : null}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="4">
                                                            {activeTab === 'reportGiftSum' ?
                                                                <GiftReportSum idZone="gift" dateData={dateData} onRefresh={refreshData} />
                                                                : null}
                                                        </Tab.Pane>
                                                    </Tab.Content>

                                                </div>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Tab.Container>
                </div>
            </div >

            {
                modalAddMember.view ?
                    <ModalAddMember show={modalAddMember.view}
                        dataId={modalAddMember.id}
                        onHide={() => setModalAddMember({ view: false })}
                        type={modalAddMember.type}
                        onRefresh={refreshData}
                    />
                    : null
            }{
                modalDelMember.view ?
                    <ModalDelMember show={modalDelMember.view}
                        dataId={modalDelMember.id}
                        onHide={() => setModalDelMember({ view: false })}
                        type={modalDelMember.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Main;
