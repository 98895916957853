import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { Form } from "react-bootstrap";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ModalAddMember from "../../layout/modal/memberModal";
import ModalDelMember from "../../layout/modal/delData";
import Loading from "../../layout/loading";

import { getData,exportData } from "../../services/getService";
// import { Link } from "react-router-dom";
// import moment from "moment";
import Menu from "../../layout/menu"
import Footer from "../../layout/footer"

import menu from "../../assets/img/icon/Menu.svg";
import pdf from "../../assets/img/icon/Pdf.svg";
import xls from "../../assets/img/icon/Xls.svg";
import addMember from "../../assets/img/icon/AddMember.svg";
import search from "../../assets/img/icon/Search.svg";
import edit from "../../assets/img/icon/edit.svg";
import bin from "../../assets/img/icon/bin.svg";
import detail from "../../assets/img/icon/detail.svg";
import addPoint from "../../assets/img/icon/Addpoint.svg";
import carlendar from "../../assets/img/icon/Carlendar.svg";
import viewDetail from "../../assets/img/icon/information-icon.svg";


const Main = () => {

    const dateMonthGen = ($year,$month) => {
        return $month == 2 ? ($year % 4 ? 28 : ($year % 100 ? 29 : ($year % 400 ? 28 : 29))) : (($month - 1) % 7 % 2 ? 30 : 31);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [mainData, setMainData] = useState([]);
    const [twoData, setTwoData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [searchData, setSearchData] = useState({ button: 'month', dateStart: '', dateEnd: '' });
    const [modalAddMember, setModalAddMember] = useState({ view: false, id: 0, type: '' })
    const [modalDelMember, setModalDelMember] = useState({ view: false, id: 0, type: '' })

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        // minDate: todayDate,
        // startDate: startDate,
        // endDate: endDate,
        // singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true
    };

    const called = useRef(true);
    const myFormRef = useRef();

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-'+dateMonthGen(moment().format("YYYY"),moment().format("MM")))]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false;
        };
    }, []);

    const fetchData = async (startDate,endDate) => {  

        getData('member?startDate='+startDate+'&endDate='+endDate).then(
            response => {
                if (response.status === 'success') {
                    setMainData(response.data)
                    setTwoData(response.data)
                    // filteredData(response.data, searchData.button)
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    // const handleEvent = (event, picker) => {
    //     // console.log(picker.startDate);
    // }
    // const handleCallback = (start, end, label) => {
    //     // console.log(start, end, label);
    //     // console.log(moment(start, "DD-MM-YYYY"));
    //     // console.log(start?.format('MM/DD/YYYY'))
    // }

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD/MM/YYYY') +
            ' - ' +
            picker.endDate.format('DD/MM/YYYY')
        );
        setSearchData({ button: 'date', dateStart: picker.startDate.format('YYYY-MM-DD'), dateEnd: picker.endDate.format('YYYY-MM-DD') })
        filteredData('date', picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'),'get','')
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
        setSearchData({ button: 'month', dateStart: '', dateEnd: '' })
    };

    const refreshData = () => {
        fetchData(moment().format("YYYY-MM") + '-01',moment().format("YYYY-MM") + '-'+dateMonthGen(moment().format("YYYY"),moment().format("MM")))
        setModalAddMember({ view: false })
        setModalDelMember({ view: false })
    };

    const handleSearch = (e) => {
        setSearchData((prev) => ({ ...prev, button: e }))
        setSearchData((prev) => ({ ...prev, dateStart: '' }))
        filteredData(e,'','','get','')
        myFormRef.current.reset()
    }

    const filteredData = (e,st,en,type,cat) => {
        // const tststst = edata.filter((item) => {
        //     const itemDate = new Date(item.dateActive);
        //     if (e === 'month') {
        //         const dateTo = moment().format("YYYY-MM");
        //         return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        //     } else if (e === 'last') {
        //         const dateTo = moment().subtract('1', 'month').format("YYYY-MM");
        //         return (itemDate >= new Date(dateTo + '-01')) && (itemDate <= new Date(dateTo + '-31'));
        //     } else if (e === 'year') {
        //         const dateTo = moment().format("YYYY");
        //         return (itemDate >= new Date(dateTo + '-01-01')) && (itemDate <= new Date(dateTo + '-12-31'));
        //     } else {
        //         return (moment(itemDate).format("YYYY-MM-DD") >= (s)) && (moment(itemDate).format("YYYY-MM-DD") <= (end));
        //     }
        // })
        // setTwoData(tststst)
        // setTreeData(tststst)
        let startDate
        let endDate
        if(e === 'month'){
            startDate = moment().format("YYYY-MM") + '-01';
            endDate = moment().format("YYYY-MM") + '-'+dateMonthGen(moment().format("YYYY"),moment().format("MM"));
        } else if (e === 'last') {
            startDate = moment().subtract('1', 'month').format("YYYY-MM") + '-01';
            endDate = moment().subtract('1', 'month').format("YYYY-MM") + '-'+dateMonthGen(moment().subtract('1', 'month').format("YYYY"),moment().subtract('1', 'month').format("MM"));
        } else if (e === 'year') {
            startDate = moment().format("YYYY") + '-01-01';
            endDate = moment().format("YYYY") + '-12-31';
        }else{
            startDate = st;
            endDate = en;
        }

        if(type === 'get'){
            fetchData(startDate,endDate);            
        }else if(type === 'export'){   
            // console.log(startDate,endDate)        
            fetchExport(startDate,endDate,cat)
            
        }

        // setSearchData((prev) => ({ ...prev, button: e }))
        // setSearchData((prev) => ({ ...prev, dateStart: startDate }))
        // setSearchData((prev) => ({ ...prev, dateEnd: endDate }))
        // console.log(startDate,endDate)
    };

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);
   
        if (dataForm.get('tel')) {
            const updatedList = mainData.filter((item) => {
                return item.tel.toLowerCase().indexOf(dataForm.get('tel').toLowerCase()) !== -1;
            });

            if(updatedList.length){
                setTwoData(updatedList)
            }else{
                const updatedList2 = mainData.filter((item) => {
                    return item.name.toLowerCase().indexOf(dataForm.get('tel').toLowerCase()) !== -1;
                });
                setTwoData(updatedList2)
            }
           
        } else {
            setTwoData(mainData)
        }
    };

    const handleExport = async (event) => {
        filteredData(searchData.button,searchData.dateStart,searchData.dateEnd,'export',event)
    }

    const fetchExport = async (startDate,endDate,cat) => {  
        let pi

        if(cat === 'xls'){
            pi = 'member'
        }else{
            pi = 'memberPDF'
        }
        exportData(pi+'?startDate='+startDate+'&endDate='+endDate).then(
            response => {
                if (response.status === 'success') {
                    // window.location.href = response.data.redirect;
                    window.open(response.data.redirect,'_blank')
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    // console.log(searchData)

    return (
        <>
            <div id="outer-container" style={{}}>
                {/* <Header page="home" /> */}
                <div className="wrapper bg-main vh-100">
                    {isLoading ? <Loading /> : null}
                    <section className="pt-3" id="page-wrap">
                        <div className="container-fluid ">
                            <div className="row align-items-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="box-in bg-white" style={{ height: 'calc(100vh - 4rem)' }}>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3">
                                                    <Menu page={'users'} />
                                                    <span className="align-middle sub-topic weight-medium ms-4">ระบบชื่อผู้ให้บริการ</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="p-3 text-end">
                                                    <button onClick={(e) => handleExport('pdf')} className="btn px-0">
                                                        <img src={pdf} className="" style={{ height: '45px' }} alt="" />
                                                    </button>
                                                    <button onClick={(e) => handleExport('xls')} className="btn">
                                                        <img src={xls} className="" style={{ height: '45px' }} alt="" />
                                                    </button>
                                                    <button onClick={(e) => setModalAddMember({ view: true, id: 1, type: 'add' })} className="btn btn-addmember sub-topic-small text-white">
                                                        <img src={addMember} className="me-2" style={{ height: '27px' }} alt="" /> เพิ่มชื่อลูกค้า
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="px-4">
                                                    <Form noValidate onSubmit={handleSubmit} ref={myFormRef}>
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-auto">
                                                                <a href="/" className="btn btn-menu py-0 px-md-3 px-lg-3" style={{ opacity: 0 }}>
                                                                    <img src={menu} className="" style={{ height: '30px' }} alt="" />
                                                                </a>
                                                                <span className="sub-topic-small weight-medium ms-4">ค้นหาลูกค้า</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="input-group">
                                                                    <input className="form-control input-custom border-end-0 border" type="search" name="tel" placeholder="ชื่อหรือเบอร์โทรศัพท์" />
                                                                    <span className="input-group-append">
                                                                        <button type="submit" className="btn btn-outline-secondary bg-white border-start-0 border ms-n5 px-2" style={{ borderRadius: '0 4px 4px 0' }}>
                                                                            <img src={search} className="" style={{ height: '30px' }} alt="" />
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="row px-4 justify-content-md-end">
                                                    <div className="col-auto p-0">
                                                        <button onClick={() => handleSearch('month')} className={`btn btn-border-black me-1 ${searchData.button === 'month' && 'active'}`}>
                                                            เดือนนี้
                                                        </button>
                                                        <button onClick={() => handleSearch('last')} className={`btn btn-border-black me-1 ${searchData.button === 'last' && 'active'}`}>
                                                            เดือนที่แล้ว
                                                        </button>
                                                        <button onClick={() => handleSearch('year')} className={`btn btn-border-black me-1 ${searchData.button === 'year' && 'active'}`}>
                                                            ปีนี้
                                                        </button>
                                                    </div>
                                                    <div className="col-auto ps-0 pe-1">
                                                        <div className="input-group">

                                                            <DateRangePicker
                                                                initialSettings={dateoptionrang}
                                                                // onEvent={handleEvent} onCallback={handleCallback}
                                                                onApply={handleApply}
                                                                onCancel={handleCancel}
                                                            >
                                                                <input type="text" value={searchData.dateStart ? moment(searchData.dateStart).format("DD/MM/YYYY") + ' - ' + moment(searchData.dateEnd).format("DD/MM/YYYY") : ''} placeholder="ค้นหาตามช่วงเวลา" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                                            </DateRangePicker>
                                                            <span className="input-group-append">
                                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="px-3 box-scroll">
                                                    <table className="table table-striped table-list">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '100px' }}>ลำดับที่</th>
                                                                <th>ชื่อ-นามสกุล</th>
                                                                <th style={{ width: '150px' }}>เบอร์โทร</th>
                                                                {/* <th className="text-end">ยอดสะสม</th> */}
                                                                <th style={{ width: '120px' }}>จำนวนครั้ง</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {twoData.length ? twoData.map((val, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{val.name}</td>
                                                                    <td>{val.tel}</td>
                                                                    {/* <td className="text-end">{val.total ? val.total.toLocaleString() : 0}</td> */}
                                                                    <td>{val.countt}</td>
                                                                    <td className="text-end">
                                                                        <button onClick={(e) => setModalAddMember({ view: true, id: val.id, type: 'detail' })} className="btn btn-action py-0 px-0">
                                                                            <img src={viewDetail} className="" style={{ height: '40px' }} alt="" />
                                                                        </button>
                                                                        <button onClick={(e) => setModalAddMember({ view: true, id: val.id, type: 'edit' })} className="btn btn-action py-0 px-0">
                                                                            <img src={edit} className="" style={{ height: '40px' }} alt="" />
                                                                        </button>
                                                                        <button onClick={(e) => setModalDelMember({ view: true, id: val.id, type: 'member' })} className="btn btn-action py-0 px-0">
                                                                            <img src={bin} className="" style={{ height: '40px' }} alt="" />
                                                                        </button>
                                                                        {/* <a href={`/administrator/members/${val.id}`} className="btn btn-action py-0 px-0">
                                                                            <img src={detail} className="" style={{ height: '40px' }} alt="" />
                                                                        </a> */}
                                                                        {/* <button onClick={(e) => setModalAddMember({ view: true, id: val.id, type: 'amount' })} className="btn btn-addpoint py-0 px-0">
                                                                            <img src={addPoint} className="" style={{ height: '40px' }} alt="" />
                                                                        </button> */}
                                                                    </td>
                                                                </tr>
                                                            )) : <tr>
                                                                <td colSpan={5} className="text-center">-- No data --</td>
                                                            </tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >

            {
                modalAddMember.view ?
                    <ModalAddMember show={modalAddMember.view}
                        dataId={modalAddMember.id}
                        onHide={() => setModalAddMember({ view: false })}
                        type={modalAddMember.type}
                        onRefresh={refreshData}
                    />
                    : null
            }{
                modalDelMember.view ?
                    <ModalDelMember show={modalDelMember.view}
                        dataId={modalDelMember.id}
                        onHide={() => setModalDelMember({ view: false })}
                        type={modalDelMember.type}
                        onRefresh={refreshData}
                    />
                    : null
            }
        </>
    );
};

export default Main;
