import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import TimeRange from 'react-time-range';

import Loading from "../../layout/loading";
import carlendar from "../../assets/img/icon/Carlendar.svg";
import confirm from "../../assets/img/icon/Confirm.svg";
import cancel from "../../assets/img/icon/Cancel.svg";
import time from "../../assets/img/icon/Time.svg";

import { createData, putData } from "../../services/getService";

const Main = ({ dataId, type, onRefresh, dataOne, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [dateToday, setDateToday] = useState(dataOne.length ? dataOne[0].dateActive : new Date());
    const [inputFields, setInputFields] = useState(dataOne.length ? dataOne[0].singer : []);
    const [inputinput, setInputinput] = useState({
        id: 0,
        dateStart: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().format('HH:mm')).format("YYYY-MM-DD HH:mm"),
        dateEnd: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().add(60, 'minutes').format('HH:mm')).format("YYYY-MM-DD HH:mm"),
        name: '',
        hStart: '',
        iStart: '',
        hEnd: '',
        iEnd: ''
    });
    // const [inputinput2, setInputinput2] = useState({ id: 0, dateStart: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().format('HH:mm')).format("YYYY-MM-DD HH:mm"), dateEnd: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().add(60, 'minutes').format('HH:mm')).format("YYYY-MM-DD HH:mm"), name: '' });
    const [inputError, setInputError] = useState(false);

    const addFields = () => {
        if (inputinput.name && inputinput.hStart && inputinput.iStart && inputinput.hEnd && inputinput.iEnd) {
            let newfield = {
                id: 0,
                dateStart: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().format('HH:mm')).format("YYYY-MM-DD HH:mm"),
                dateEnd: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment().add(60, 'minutes').format('HH:mm')).format("YYYY-MM-DD HH:mm"),
                name: '',
                hStart: '',
                iStart: '',
                hEnd: '',
                iEnd: ''
            }

            let getfield = {
                id: 0,
                dateStart: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment(moment(dateToday).format('YYYY-MM-DD')+' '+inputinput.hStart+':'+inputinput.iStart).format('HH:mm')).format("YYYY-MM-DD HH:mm"),
                dateEnd: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment(moment(dateToday).format('YYYY-MM-DD')+' '+inputinput.hEnd+':'+inputinput.iEnd).format('HH:mm')).format("YYYY-MM-DD HH:mm"),
                name: inputinput.name,
            }

            setInputFields([...inputFields, getfield])
            setInputinput(newfield)
            setInputError(false)
        } else {
            setInputError(true)
        }
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const dateoptionrang = {
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: 'Clear',
        },
        singleDatePicker: true,
        autoUpdateInput: false,
        autoApply: false,
        timePicker24Hour: true,
    };

    const handleApply = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        setDateToday(picker.startDate);
    };
    const handleCancel = (event, picker) => {
        picker.element.val('');
    };

    // const returnFunctionStart = (e) => {
    //     setInputinput((prev) => ({ ...prev, dateStart: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment(e.startTime).format('HH:mm')).format("YYYY-MM-DD HH:mm") }))
    //     setInputinput2((prev) => ({ ...prev, dateStart: moment(e.startTime) }))
    // }
    // const returnFunctionEnd = (e) => {
    //     setInputinput((prev) => ({ ...prev, dateEnd: moment(moment(dateToday).format('YYYY-MM-DD') + "@" + moment(e.endTime).format('HH:mm')).format("YYYY-MM-DD HH:mm") }))
    //     setInputinput2((prev) => ({ ...prev, dateEnd: moment(e.endTime) }))
    // }

    const [validated, setValidated] = useState(false);
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        // const newErrors = findFormErrors()

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        } else {
            setIsLoading(true);

            if (inputFields.length === 0) {
                alert('No data')
                setIsLoading(false)
            } else {
                if (type === 'add') {
                    dataForm.set('dateActive', moment(dataForm.get('dateActive'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
                    dataForm.set('datalist', JSON.stringify(inputFields));
                    await createData('singer', dataForm).then(
                        response => {
                            if (response.status === 'success') {
                                setTimeout(() => {
                                    onRefresh()
                                }, 100);
                            }
                        }).catch(e => {
                            setIsLoading(false)
                            console.log(e.response.data.messages.errors)
                        })
                } else {
                    dataForm.set('id', dataId);
                    dataForm.set('dateActive', moment(dataForm.get('dateActive'), 'DD/MM/YYYY').format('YYYY-MM-DD'));
                    dataForm.set('datalist', JSON.stringify(inputFields));
                    await putData('singer', dataId, dataForm).then(
                        response => {
                            if (response.status === 'success') {
                                setTimeout(() => {
                                    onRefresh()
                                }, 100);
                            }
                        }).catch(e => {
                            setIsLoading(false)
                            console.log(e.response.data.messages.errors)
                        })
                }
            }
        }
        setValidated(true);
    };


    console.log(inputinput)

    console.log(inputFields)
    // console.log(moment('2024-04-11 14:00').format('HH:mm'))
    return (
        <>
            <Modal {...data} className="modal-custom" size="lg" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading ? <Loading border={true} /> : null}
                <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header>
                <Modal.Body className="pt-0 position-relative">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row align-items-center text-start">
                            <div className="col-12 col-md-12 col-lg-12 px-4">
                                <div className="sub-topic weight-bold text-start mb-4">
                                    {type === 'add' ? 'เพิ่มนักร้อง' : type === 'edit' ? 'แก้ไขนักร้อง' : ''}
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center text-start">
                            <div className="col-12 col-md-6 col-lg-6 px-4">
                                <div className="">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label className="weight-medium mb-1">วันที่*</Form.Label>
                                        <div className="input-group">
                                            <DateRangePicker
                                                initialSettings={dateoptionrang}
                                                onApply={handleApply}
                                                onCancel={handleCancel}
                                            >
                                                <input type="text" defaultValue={moment(dateToday).format("DD/MM/YYYY")} name="dateActive" required placeholder="วว/ดด/ปปปป" className="form-control input-custom daterangBlack border-end-0 border" style={{ padding: '9px 8px' }} />
                                            </DateRangePicker>
                                            <span className="input-group-append">
                                                <span className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ borderRadius: '0 4px 4px 0', padding: '9px 9px' }}>
                                                    <img src={carlendar} className="" style={{ height: '20px' }} alt="" />
                                                </span>
                                            </span>
                                            <div className="invalid-feedback">
                                                Please select date
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center text-start">
                            <div className="col-12 col-md-12 col-lg-12 px-4">
                                <div className="mb-3" style={{ height: 'calc(-4rem + 50vh)', overflow: 'auto' }}>
                                    <table className="table table-striped table-list">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '250px' }}>เวลา</th>
                                                <th>รายการ</th>
                                                <th style={{ width: '30px' }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inputFields.map((input, index) => (
                                                <tr key={index}>
                                                    <td>{moment(input.dateStart).format('HH:mm')} - {moment(input.dateEnd).format('HH:mm')}</td>
                                                    {/* <td>{input.dateStart} - {input.dateEnd}</td> */}
                                                    <td>{input.name}</td>
                                                    <td>
                                                        <span className='cursor-pointer' onClick={(e) => removeFields(index)}>
                                                            <img src={cancel} alt="" className="" style={{ width: "25px" }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="bg-gray-b align-top">
                                                    <div className="pe-5 position-relative">
                                                        <div className="d-inline-block" style={{ width: '30px' }}>
                                                            <input type="number" name='hStart' min={0} max={23} value={inputinput.hStart} onInput={e => setInputinput((prev) => ({ ...prev, hStart: e.target.value }))} className='form-control input-custom-border px-0' placeholder="" />
                                                        </div>
                                                        :
                                                        <div className="d-inline-block ms-2" style={{ width: '30px' }}>
                                                            <input type="number" name='iStart' min={0} max={59} value={inputinput.iStart} onInput={e => setInputinput((prev) => ({ ...prev, iStart: e.target.value }))} className='form-control input-custom-border px-0' placeholder="" />
                                                        </div>
                                                        <span className="mx-3">-</span>
                                                        <div className="d-inline-block" style={{ width: '30px' }}>
                                                            <input type="number" name='hEnd' min={0} max={23} value={inputinput.hEnd} onInput={e => setInputinput((prev) => ({ ...prev, hEnd: e.target.value }))} className='form-control input-custom-border px-0' placeholder="" />
                                                        </div>
                                                        :
                                                        <div className="d-inline-block ms-2" style={{ width: '30px' }}>
                                                            <input type="number" name='iEnd' min={0} max={59} value={inputinput.iEnd} onInput={e => setInputinput((prev) => ({ ...prev, iEnd: e.target.value }))} className='form-control input-custom-border px-0' placeholder="" />
                                                        </div>
                                                        <span className="position-absolute" style={{ right: '3px', bottom: '5px' }}>
                                                            <img src={time} alt="" className="" style={{ width: "25px" }} />
                                                        </span>
                                                        {/* <TimeRange
                                                            startMoment={inputinput2.dateStart}
                                                            endMoment={inputinput2.dateEnd}
                                                            onStartTimeChange={returnFunctionStart}
                                                            onEndTimeChange={returnFunctionEnd}
                                                            minuteIncrement={1}
                                                            use24Hours={true}
                                                            startLabel=""
                                                            endLabel="-"
                                                        /> */}
                                                        {/* <span className="position-absolute" style={{ right: '3px', bottom: '5px' }}>
                                                            <img src={time} alt="" className="" style={{ width: "25px" }} />
                                                        </span> */}
                                                    </div>
                                                    {inputError && <div id="react-time-range" className="position-relative"><div className="error" style={{ bottom: '-33px' }}>Please fill all input</div></div>}
                                                </td>
                                                <td className="bg-gray-b align-top">
                                                    <input type="text" name='name' value={inputinput.name} onInput={e => setInputinput((prev) => ({ ...prev, name: e.target.value }))} className='form-control input-custom-border' placeholder="พิมพ์ชื่อวง" />
                                                    
                                                </td>
                                                <td className="bg-gray-b align-top">
                                                    <span className='cursor-pointer' onClick={addFields}>
                                                        <img src={confirm} alt="" className="" style={{ width: "25px" }} />
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 ps-4">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <div className="font-14 weight-medium mb-1 mt-4">*กรุณากรอกข้อมูลสำคัญให้ครบถ้วน</div>
                                </Form.Group>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 pe-4">
                                <div className="">
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <div className="">
                                            <button className="btn btn-addmember text-white w-100">บันทึก</button>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
